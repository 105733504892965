import { FunctionComponent } from "react";
import { HistoryItem } from "../../../HistoryApi";
import { historyMapKey } from "../historyMapKey";
import { historyMapValue } from "../historyMapValue";
import HistoryItemDescLine from "./HistoryItemDescLine";

const HistoryItemDesc: FunctionComponent<{ record: HistoryItem }> = ({
  record,
}) => {
  return (
    <>
      {(Object.keys((record?.changes?.changes ?? {}) as any) ?? []).map(
        (key) => {
          const change: any[] = (record.changes?.changes as any)?.[key] ?? [];
          if (key == "id") return <></>;
          if (key == "is_superuser") return <></>;
          if (key == "created_at") return <></>;
          if (key == "updated_at") return <></>;
          if (key == "order") return <></>;
          if (key == "avatar_ulr") return <></>;
          if (key == "category_id") return <></>;
          if (key == "avatar_url" && record.operation == 2) return <></>;

          return (
            <HistoryItemDescLine keyValue={key} change={change} record={record} />
          );
        }
      )}
    </>
  );
};

export default HistoryItemDesc;
