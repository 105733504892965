import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { api } from "../../../api_client";
import ViewHeader from "../../../shared/ViewHeader";
import { accessKeys, checkAccess } from "../../../shared/parseJwt";
import { useOpenNotifications } from "../../../shared/useOpenNotifications";

const SettingsComponents: React.FC = () => {
  const [form] = Form.useForm();
  const { apiNotification } = useOpenNotifications();
  const [allowLdap, setAllowLdap] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const { data: settingsList } = useQuery({
    queryKey: "settings",
    queryFn: () => api.settings.settingsList().then((e) => e.data),
  });

  useEffect(() => {
    if (settingsList) {
      let res = false;
      ldapNames.forEach((name) => {
        if (settingsList.find((e) => e.name === name)?.value) {
          res = true;
        }
      });
      setAllowLdap(res);
    }
  }, [settingsList]);

  function getSettingValueByIndex(index: number) {
    const name = settingsList?.[index]?.name;

    switch (name) {
      case "ACCESS_TOKEN_LIFETIME":
        return Number(form.getFieldValue("access_token"));
      case "REFRESH_TOKEN_LIFETIME":
        return Number(form.getFieldValue("refresh_token"));
      case "PASSWORD_RULES":
        return form.getFieldValue("reg-exp");
      case "PASSWORD_ERROR_CHECK":
        return form.getFieldValue("description");
      default:
        return form.getFieldValue(name);
    }
  }

  function getSettingValueByName(name: string) {
    switch (name) {
      case "ACCESS_TOKEN_LIFETIME":
        return Number(form.getFieldValue("access_token"));
      case "REFRESH_TOKEN_LIFETIME":
        return Number(form.getFieldValue("refresh_token"));
      case "PASSWORD_RULES":
        return form.getFieldValue("reg-exp");
      case "PASSWORD_ERROR_CHECK":
        return form.getFieldValue("description");
      default:
        return form.getFieldValue(name);
    }
  }

  const baseNames = [
    "ACCESS_TOKEN_LIFETIME",
    "PASSWORD_RULES",
    "PASSWORD_ERROR_CHECK",
    "REFRESH_TOKEN_LIFETIME",
  ];

  const ldapNames = [
    "AD_HOST",
    "AD_ROOT",
    "AD_USERS_DN",
    "LDAP_LOGIN",
    "AD_GROUPS_DN",
    "LDAP_PASSWORD",
  ];

  const handleUpdate = useMutation({
    mutationFn: async () => {
      await api.settings.updateCreate({
        settings: [
          ...baseNames.map((name) => ({
            id: settingsList?.find((e) => e.name === name)?.id ?? "",
            data: {
              title: settingsList?.find((e) => e.name === name)?.title ?? "",
              value: getSettingValueByName(name),
            },
          })),
          ...ldapNames.map((name) => ({
            id: settingsList?.find((e) => e.name === name)?.id ?? "",
            data: {
              title: settingsList?.find((e) => e.name === name)?.title ?? "",
              value: getSettingValueByName(name),
            },
          })),
        ],
      });
    },
    onSuccess: (result) => {
      setTimeout(() => {
        apiNotification.info({
          message: `Сохранение`,
          description: <>Данные сохранены</>,
        });
      }, 1000);
    },
    onError: (err: any) => {
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось обновить</>,
      });
    },
  });

  const showPropsConfirm = async () => {
    try {
      await form.validateFields();

      Modal.confirm({
        title: "Сохранение настроек",
        icon: <ExclamationCircleFilled />,
        cancelText: "Отмена",
        okText: "Сохранить",
        className: "modalSaveSettings",

        cancelButtonProps: { type: "text" },
        okButtonProps: {
          type: "primary",
          style: { backgroundColor: "#F09F54" },
        },
        content: (
          <>
            <Row gutter={5}>
              <Col>
                <Typography.Text disabled>Access token:</Typography.Text>
              </Col>
              <Col>
                <Typography.Text disabled>
                  {form.getFieldValue("access_token")}
                </Typography.Text>
              </Col>
            </Row>

            <Row gutter={5}>
              <Col>
                <Typography.Text disabled>Refresh token:</Typography.Text>
              </Col>
              <Col>
                <Typography.Text disabled>
                  {form.getFieldValue("refresh_token")}
                </Typography.Text>
              </Col>
            </Row>

            <Row gutter={5}>
              <Col>
                <Typography.Text disabled>Проверка:</Typography.Text>
              </Col>
              <Col>
                <Typography.Text disabled>
                  {form.getFieldValue("reg-exp")}
                </Typography.Text>
              </Col>
            </Row>

            <Row gutter={5}>
              <Col>
                <Typography.Text disabled>
                  Описание требований к паролю:
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text disabled>
                  {form.getFieldValue("description")}
                </Typography.Text>
              </Col>

              {ldapNames.map((name) => (
                <Row gutter={5} key={name}>
                  <Col>
                    <Typography.Text disabled>{settingsList?.find((e) => e.name === name)?.title}:</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text disabled>
                      {form.getFieldValue(name)}
                    </Typography.Text>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        ),
        async onOk() {
          await form.validateFields();
          handleUpdate.mutate();
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } catch (err) {
      apiNotification.error({
        message: `Ошибка`,
        description:
          (err as any)?.error?.message ??
          "Пожалуйста, проверьте значения формы",
      });
    }
  };

  return (
    <>
      <ViewHeader
        group={{ title: "Настройки" }}
        linkEdit={""}
        accessKey={accessKeys.settings}
      >
        {checkAccess(accessKeys.settings, ["F", "E"]) && (
          <Button
            type={"primary"}
            style={{ marginLeft: "auto" }}
            onClick={showPropsConfirm}
          >
            Сохранить
          </Button>
        )}
      </ViewHeader>

      {settingsList?.length && (
        <Row gutter={[24, 12]}>
          <Col span={6}>
            <Typography.Title level={5}>Срок действия, сек</Typography.Title>
            <Form
              layout={"vertical"}
              form={form}
              disabled={!checkAccess(accessKeys.settings, ["F", "E"])}
              initialValues={{
                access_token: Number(
                  settingsList?.find(
                    (el) => el.name === "ACCESS_TOKEN_LIFETIME"
                  )?.value
                ),
                refresh_token: Number(
                  settingsList?.find(
                    (el) => el.name === "REFRESH_TOKEN_LIFETIME"
                  )?.value
                ),
                "reg-exp": settingsList?.find(
                  (el) => el.name === "PASSWORD_RULES"
                )?.value,
                description: settingsList?.find(
                  (el) => el.name === "PASSWORD_ERROR_CHECK"
                )?.value,
              }}
              style={{ maxWidth: "100%" }}
            >
              <Form.Item
                name="access_token"
                label="Access token"
                rules={[
                  {
                    required: true,
                    message: "Поле обязательно для заполнения",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) return Promise.resolve();

                      if (Number(value) && Number(value) >= 60) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Минимальное значение 60 секунд")
                      );
                    },
                  }),
                ]}
              >
                <Input placeholder="Целое число, секунды" />
              </Form.Item>
              <Form.Item
                name="refresh_token"
                label="Refresh token"
                rules={[
                  {
                    required: true,
                    message: "Поле обязательно для заполнения",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) return Promise.resolve();

                      if (
                        Number(value) &&
                        Number(getFieldValue("access_token")) &&
                        Number(getFieldValue("access_token")) + 10 <=
                          Number(value)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Refresh токен должен быть больше access токена хотя бы на 10 секунд"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input placeholder="Целое число, секунды" />
              </Form.Item>
            </Form>
          </Col>

          <Col span={18}>
            <Typography.Title level={5}>
              Требования к сложности пароля
            </Typography.Title>
            <Form
              layout={"vertical"}
              form={form}
              disabled={!checkAccess(accessKeys.settings, ["F", "E"])}
              initialValues={{
                access_token: Number(
                  settingsList?.find(
                    (el) => el.name === "ACCESS_TOKEN_LIFETIME"
                  )?.value
                ),
                refresh_token: Number(
                  settingsList?.find(
                    (el) => el.name === "REFRESH_TOKEN_LIFETIME"
                  )?.value
                ),
                "reg-exp": settingsList?.find(
                  (el) => el.name === "PASSWORD_RULES"
                )?.value,
                description: settingsList?.find(
                  (el) => el.name === "PASSWORD_ERROR_CHECK"
                )?.value,
              }}
              style={{ maxWidth: "100%" }}
            >
              <Form.Item
                key={"key_reg-exp"}
                name="reg-exp"
                label="Проверка"
                rules={[
                  {
                    required: true,
                    message: "Поле обязательно для заполнения",
                  },
                ]}
              >
                <Input placeholder="Регулярное выражение" />
              </Form.Item>
              <Form.Item
                key={"key_description"}
                name="description"
                label="Описание требований к паролю"
                rules={[
                  {
                    required: true,
                    message: "Поле обязательно для заполнения",
                  },
                ]}
              >
                <Input placeholder="Текст сообщения пользователю" />
              </Form.Item>
            </Form>
          </Col>

          <Col span={24}>
            <Checkbox onChange={() => setAllowLdap(!allowLdap)} checked={allowLdap}>
              Синхронизация по LDAP
            </Checkbox>
            <br />
            <br />

            <Form
              layout={"vertical"}
              form={form}
              disabled={
                !checkAccess(accessKeys.settings, ["F", "E"]) || !allowLdap
              }
              initialValues={{
                ...ldapNames.reduce(
                  (acc, name) => ({ ...acc, [name]: settingsList.find(e => e.name === name)?.value }),
                  {}
                ),
              }}
              style={{ maxWidth: "100%" }}
            >
              <Row gutter={[24, 12]}>
                {ldapNames.map((name, index) => (
                  <Col span={index % 2 === 0 ? 16 : 8}>
                    <Form.Item
                      key={`key_${name}`}
                      name={name}
                      label={settingsList.find((e) => e.name === name)?.title}
                      rules={[
                        {
                          required: true,
                          message: "Поле обязательно для заполнения",
                        },
                      ]}
                    >
                      {name === 'LDAP_PASSWORD' && <Input.Password />}
                      {name !== 'LDAP_PASSWORD' && <Input />}
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SettingsComponents;
