import React, { FunctionComponent, useEffect } from "react";
import useZoneActions from "../../../actions/useZoneActions";
import useCategoryActions from "../../../actions/useCategoryActions";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { BtnDelete } from "../../../shared/showDeleteConfirm";
import { AccessZone } from "../../../Api";
import { Button, RowProps, Table } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import getEmptyAccessZone from "../../../emptyModels/getEmptyAccessZone";
import { accessKeys } from "../../../shared/parseJwt";

const CategoryZonesListEdit: FunctionComponent<{allowActions: boolean}> = (props) => {
  let { id } = useParams();
  const { zoneCategory, zoneCategoryLoading, sortZoneCategory } =
    useCategoryActions(id);
  const { deleteZone, sortZones, zoneLoading } = useZoneActions({
    id: "",
    categoryId: id,
  });
  const navigate = useNavigate();
  const [ids, setIds] = React.useState<string[]>([]);

  useEffect(() => {
    setIds(zoneCategory.zones?.map((zone) => zone.id ?? "") ?? []);
  }, [zoneCategory]);

  function handleAddZone() {
    navigate(`/categories/${id}/access_zone/add?from=/categories/${id}/edit`);
  }

  function getUrlList(categoryId: string, id: string) {
    return `/categories/:categoryId/access_zone/:id`
      .replace(":categoryId", `${categoryId}`)
      .replace(":id", `${id}`);
  }

  const columns: ColumnsType<AccessZone> = [
    {
      key: "sort",
      width: "50px",
    },
    {
      title: "Наименование",
      dataIndex: "title",
      className: "column_title",
      render: (text, record) => (
        <NavLink to={getUrlList(id ?? "", record.id ?? "")}>{text}</NavLink>
      ),
      key: "title",
    },
    {
      title: "Код",
      dataIndex: "name",
      key: "name",
      // render: (text) => <Link to={'/'}>{text}</Link>,
      width: "300px",
    },
    {
      width: "120px",
      title: "",
      key: "actions",
      dataIndex: "actions",
      render: (value, record, index) => (
        <>
          {props.allowActions && <BtnDelete
            keyAccess={accessKeys.access}
            values={["E"]}
            onOk={() => {
              deleteZone.mutate([record.id ?? ""]);
            }}
            linkView={getUrlList(id ?? "", record.id ?? "") + "/edit"}
          />}
        </>
      ),
    },
  ];

  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: (props as any)["data-row-key"],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setIds((previous) => {
        const activeIndex = previous.findIndex((i) => i === active.id);
        const overIndex = previous.findIndex((i) => i === over?.id);
        const res = arrayMove(previous, activeIndex, overIndex);
        sortZones.mutate(res);
        return res;
      });
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className={"CategoryZonesListTitle"}>Зоны доступа</div>
        <Button disabled={!id} onClick={handleAddZone}>
          Добавить
        </Button>
      </div>

      <br />
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={zoneCategory.zones?.map((i) => i.id ?? "") ?? []}
          strategy={verticalListSortingStrategy}
        >
          <Table
            loading={zoneCategoryLoading || zoneLoading}
            components={{ body: { row: Row } }}
            rowKey="key"
            columns={columns}
            dataSource={ids.map(
              (id) =>
                zoneCategory.zones?.find((e) => e.id === id) ??
                getEmptyAccessZone()
            )}
            onRow={(record, rowIndex) => ({
              onClick: (event) => getUrlList(id ?? "", record.id ?? ""),
            })}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </>
  );
};

export default CategoryZonesListEdit;
