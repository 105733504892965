import moment from "moment";

export function dateFormatter(date: string) {
    if (!date) return '-'

    if (date.includes('T')) {
        const moment1 = moment();
        const [y, m, d] = (date.split('T')[0]).split('-').map(e => Number(e))
        const [h, min, s] = (date.replace('.000000Z', '').split('T')[1]).split(':').map(e => Number(e))
        moment1.set({date: d, month: m - 1, y: y, h: h, minute: min, second: s})

        return moment1.format('DD.MM.YYYY')
    }

    return moment(date).format('DD.MM.YYYY')
}

export function dateTimeFormatter(date: string) {
    if (!date) return '-'

    if (date.includes('T')) {
        const moment1 = moment();
        const [y, m, d] = (date.split('T')[0]).split('-').map(e => Number(e))
        const [h, min, s] = (date.replace('.000000Z', '').split('T')[1]).split(':').map(e => Number(e))
        moment1.set({date: d, month: m - 1, y: y, h: h, minute: min, second: s})

        return moment1.format('DD.MM.YYYY HH:mm')
    }

    return moment(date).format('DD.MM.YYYY HH:mm')
}

export default dateFormatter;

export const DateFormatter = {
    beforeMount(el: Node, binding: any, vnode: any) {
        el.textContent = dateFormatter(el.textContent ?? '');
    }
}

export function getDateISO(date: Date | string) {
    if (date.toString().includes('.')) {
        const [y, m, d] = (date.toString().split('T')[0]).split('-').map(e => Number(e))
        const moment1 = moment();
        moment1.set({date: d, month: m - 1, y: y, h: 0, minute: 0, second: 0})
        return moment1.toDate().toISOString()
    }

    if (typeof date == "string") return date;

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(date.getTime() - tzoffset)).toISOString();
}

export function getDateISOTZ(date: Date | string) {
    console.log({date})
    if (date.toString().includes('.')) {
        const [y, m, d] = (date.toString().split('T')[0]).split('-').map(e => Number(e))
        const moment1 = moment();
        moment1.set({date: d, month: m - 1, y: y, h: 0, minute: 0, second: 0})
        return moment1.toDate().toISOString()
    }

    if (typeof date == "string") return date;

    const [y, m, d] = [date.getFullYear(), date.getMonth(), date.getDate()]
    const moment1 = moment();
    moment1.set({date: d, month: m, y: y, h: 0, minute: 0, second: 0})


    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(moment1.toDate().getTime() - tzoffset)).toISOString();
}

export function getDateISOTZ_Local(date: Date) {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date((date).getTime() - tzoffset)).toISOString().split('.')[0];
}

export function numberFormatter(price: number | string) {
    const price2 = Number.parseFloat((price ?? '').toString())

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'decimal'
    });

    return USDollar.format(price2).replaceAll(',', ' ').replaceAll('.', ',')
}

export function getDateFromISO(date: string) {
    const [y, m, d] = (date.toString().split('T')[0]).split('-').map(e => Number(e))
    const moment1 = moment();
    moment1.set({date: d, month: m - 1, y: y, h: 0, minute: 0, second: 0})
    return moment1.toDate()
}