import React, {FunctionComponent} from "react";
import {Button, Descriptions, DescriptionsProps} from "antd";
import {useQuery} from "react-query";
import {ObjectPermission, UserCategoryWithZones} from "../../../Api";
import AppTable from "../../../shared/AppTable";
import {ColumnsType} from "antd/lib/table";
import {useNavigate} from "react-router-dom";
import {api} from "../../../api_client";
import {getLevelLocale} from "../../../shared/getLevelLocale";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";

const RoleDataForm: FunctionComponent<{ id: string }> = (props) => {
    const {data, error, isLoading} = useQuery({
        queryKey: [`role_${props.id}`],
        queryFn: () => api.roles.rolesDetail(props.id).then(e => ({
            ...e.data,
            permissions: e?.data?.permissions?.map(permission => ({
                ...permission,
                children: permission.zones
            })),
        })),
    })

    const navigate = useNavigate();

    const columns: ColumnsType<UserCategoryWithZones> = [
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Код',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Уровень',
            dataIndex: 'level',
            key: 'level',
            render: (level, record) => (record as any).level_title ?? getLevelLocale(level),
        },
    ]

    return <div className={'wrap-content1'}>
        <AppTable columns={columns} items={(data?.permissions ?? []).map(e => ({...e, key: e.id}))} loading={isLoading}/>

        <br/>

        {/*<Button type={'primary'} disabled={isLoading} onClick={() => { navigate(`/roles/${props.id}/edit`) }}>Изменить</Button>*/}
    </div>
}

export default RoleDataForm;
