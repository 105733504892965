import React, { FunctionComponent, useEffect, useState } from "react";
import Layout from "../../shared/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ZoneForm from "./components/ZoneForm";
import useZoneActions from "../../actions/useZoneActions";
import useCategoryActions from "../../actions/useCategoryActions";
import { api } from "../../api_client";
import { CategoryWithZones } from "../../Api";

const ZoneAccessView: FunctionComponent<{ isAdd?: boolean }> = (props) => {
  let { id, categoryId } = useParams();
  const { zone, zoneLoading } = useZoneActions({ id });

  const [category, setCategory] = useState<CategoryWithZones>({});

  useEffect(() => {
    if (zone.category_id && zone.category_id != category.id) {
      api.zoneCategories
        .zoneCategoriesDetail(zone.category_id ?? "")
        .then((e) => setCategory(e.data));
    }

    if (props.isAdd) {
      api.zoneCategories
        .zoneCategoriesDetail(categoryId ?? "")
        .then((e) => setCategory(e.data));
    }
  }, [zone.category_id]);

  return (
    <Layout
      className={"zone_edit"}
      title={props.isAdd ? "Новая зона" : zone.title ?? "Зона доступа"}
      back={{
        title: "Доступ",
        link: `/categories`,
        titleBack: "Доступ",
        link2: `/categories/${categoryId}`,
        titleBack2: category?.title ?? "Категория доступов",
      }}
      loading={zoneLoading || !category.id}
    >
      <>
        <ZoneForm isNew={props.isAdd ?? false} />
      </>
    </Layout>
  );
};

export default ZoneAccessView;
