import { dateTimeFormatter } from "../../shared/dateFormat";
import { NavLink } from "react-router-dom";
import { getLevelLocale } from "../../shared/getLevelLocale";

export function historyMapValue(
  type:
    | "User"
    | "Role"
    | "Group"
    | "AccessZone"
    | "AccessZoneCategory"
    | "Setting",
  key: string,
  value: any,
  isOld = false
) {
  // if (key == 'groups') {
  //     return JSON.stringify(value)
  // }

  if (key == "is_superuser") {
    return value ? "Да" : "Нет";
  }

  if (key == "in_active_directory") {
    return value === true ? "Да" : "Нет";
  }

  if (!value) return "";

  if (type == "AccessZoneCategory" || type == "Role") {
    switch (key) {
      case "status":
        return value == "active" ? "Активна" : "В архиве";
    }
  }

  if (type == "User") {
    switch (key) {
      case "status":
        return value == "active" ? "Активен" : "В архиве";
    }
  }

  switch (key) {
    case "name":
      return value;
    case "email":
      return value;
    case "login":
      return value;
    case "status":
      return value == "active" ? "Активный" : "В архиве";
    case "created_at":
      return dateTimeFormatter(value);
    case "updated_at":
      return dateTimeFormatter(value);
    case "telegram_id":
      return value;
    case "is_superuser":
      return value ? "Да" : "Нет";
    case "full_name":
      return value;
    case "title":
      return value;
    case "description":
      return value;
    case "avatar_url":
      return (
        <div
          className={"AvatarPreview"}
          style={{ backgroundImage: `url(${value})` }}
        />
      );
    case "roles":
      return (value as { id: string; title: string }[]).map((item, index) => (
        <>
          <NavLink to={`/roles/${item.id}`}>{item.title}</NavLink>
          {index != value.length - 1 ? ", " : ""}
        </>
      ));
    case "groups":
      return (value as { id: string; title: string }[]).map((item) => (
        <>
          <NavLink to={`/groups/${item.id}`}>{item.title}</NavLink> &nbsp;
        </>
      ));
    case "levels2":
      return JSON.stringify(value);
    case "permissions":
      let categoryes: {
        [name: string]: {
          category_title: string;
          category_id: string;
          zones: { id: string; title: string; level_title: string }[];
        };
      } = {};
      if (!value[0].zones) {
        const items = value as {
          id: string;
          title: string;
          level_title: string;
          category_title: string;
          category_id: string;
        }[];
        items.forEach((item) => {
          if (!categoryes[item.category_id]) {
            categoryes[item.category_id] = {
              category_title: item.category_title,
              category_id: item.category_id,
              zones: [item],
            };
          } else {
            categoryes[item.category_id].zones.push(item);
          }
        });
      } else {
        const items = value as {
          id: string;
          title: string;
          zones: { id: string; title: string; level_title: string }[];
        }[];

        items.forEach((item) => {
          if (!categoryes[item.id]) {
            categoryes[item.id] = {
              category_title: item.title,
              category_id: item.id,
              zones: item.zones,
            };
          }
        });
      }

      return Object.values(categoryes).map((item) => (
        <div>
          <div style={{ fontWeight: "600" }}>
            Категория "{item.category_title}"
          </div>
          {item.zones.map((zone) => (
            <span style={{ display: "block" }}>
              - Зона "{zone.title}", уровень "{zone.level_title}"
            </span>
          ))}
        </div>
      ));
    case "levels":
      return (value as { name: string; title: string }[]).map((item, index) => (
        <>
          {item.title} ({item.name}){index != value.length - 1 ? ", " : ""}
        </>
      ));
    case "users":
      return (value as { id: string; full_name: string }[]).map(
        (item, index) => (
          <>
            <NavLink to={`/groups/${item.id}`}>{item.full_name}</NavLink>
            {index != value.length - 1 ? ", " : ""}
          </>
        )
      );
    case "children":
      return (value as { id: string; title: string }[]).map((item, index) => (
        <>
          <NavLink to={`/groups/${item.id}`}>{item.title}</NavLink>
          {index != value.length - 1 ? ", " : ""}
        </>
      ));
    case "zones":
      return (
        value as {
          name: string;
          title: string;
          id: string;
          levels: { name: string; title: string }[];
        }[]
      ).map((item) => (
        <>
          <div style={{ fontWeight: "600" }}>{item?.title}</div>
          {item?.levels?.map((e) => <div>- Уровень: {e.title}</div>)}
        </>
      ));
    case "category_id":
      return <NavLink to={`/categories/${value}`}>{value}</NavLink>;
    case "password":
      return value;
    case "value":
      return value;
    case "action":
      return value;
    case "password_rules":
      return value;
    case "password_error_check":
      return value;
    case "access_token_lifetime":
      return value;
    case "ad_host":
      return value;
    case "ad_root":
      return value;
    case "ldap_login":
      return value;
    case "ad_users_dn":
      return value;
    case "ad_groups_dn":
      return value;
    case "ldap_password":
      return value;
  }

  return `${key} ${JSON.stringify(value)}`;
}
