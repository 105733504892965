import React, { FunctionComponent } from "react";

const AppIcon: FunctionComponent<{
  path: string;
  active: boolean;
  children?: any[];
  onClick: (e: any) => void;
  disabled?: boolean;
  count?: number;
}> = ({ active, path, onClick, disabled, count }) => {
  if (disabled) {
    return (
      <img
        style={{ borderRadius: "8px", opacity: "0.5" }}
        src={`${path}.svg`}
        alt=""
      />
    );
  }
  if (active) {
    return (
      <img
        onClick={(e) => onClick(e)}
        style={{
          background: "var(--neutral-4)",
          borderRadius: "8px",
          cursor: "pointer",
        }}
        src={`${path}.svg`}
        alt=""
      />
    );
  }
  return (
    <span style={{position: 'relative', top: '-2px', display: 'block', height: '28px'}}>
      {!!count && (
        <div
          style={{
            position: "absolute",
            background: "red",
            borderRadius: "10px",
            padding: "0 3px",
            fontSize: "10px",
            color: "white",
            marginLeft: "20px",
            marginTop: "-12px",
          }}
        >
          {count}
        </div>
      )}
      <img
        onClick={(e) => onClick(e)}
        style={{ borderRadius: "8px", cursor: "pointer" }}
        src={`${path}.svg`}
        alt=""
      />
    </span>
  );
};

export default AppIcon;
