import React, { FunctionComponent, useEffect, useState } from "react";
import {
  AccessZone,
  CategoryWithZones,
  ObjectPermission,
  Role,
  RoleUpdateRequest,
} from "../../../Api";
import { Form, Input, Radio, Select, Tabs } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { useZones } from "../RoleEdit";
import { NavLink, useNavigate } from "react-router-dom";
import { api } from "../../../api_client";
import TextArea from "antd/es/input/TextArea";
import FormHeader from "../../../shared/FormHeader";
import AppTable from "../../../shared/AppTable";
import { ColumnsType } from "antd/lib/table";
import { stopEvent } from "../../../shared/stopEvent";
import { useOpenNotifications } from "../../../shared/useOpenNotifications";
import { useAppTab } from "../../../shared/useAppTab";
import RoleUsersList from "./RoleUsersList";
import RoleGroupsList from "./RoleGroupsList";

interface FieldType extends RoleUpdateRequest {}

const RoleForm: FunctionComponent<{
  item: Role;
  permissions: ObjectPermission[];
  isNew: boolean;
}> = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { apiNotification } = useOpenNotifications();
  const queryClient = useQueryClient();

  const [zoneValues, setZoneValues] = useState<
    { zone_name: string; value: string; zone_id: string; priority: number }[]
  >([]);
  const { zones: categoryZones, zonesLoading, zonesError } = useZones();
  const { tab, setTab } = useAppTab();

  useEffect(() => {
    let res: {
      zone_name: string;
      value: string;
      zone_id: string;
      priority: number;
    }[] = [];
    props.item.permissions?.forEach((categoryZone) => {
      res.push(
        ...(categoryZone.zones?.map((e) => ({
          zone_name: e.name ?? "",
          zone_id: e.id ?? "",
          value: e.level ?? "",
          priority: Number(e.level?.split(":")[0] ?? 0),
        })) ?? [])
      );
    });

    console.log({ res });

    setZoneValues(res);
  }, [props.item]);

  function resetCache() {
    queryClient.invalidateQueries({ queryKey: ["roles"] });
    queryClient.invalidateQueries({ queryKey: [`roles_${props.item.id}`] });
  }

  const handleUpdate = useMutation({
    mutationFn: () => {
      const payload = {
        ...form.getFieldsValue(),
        permissions: zoneValues
          .map((e) => ({
            zone_id: e.zone_id,
            level: {
              name: e.value.split(":")[1] ?? "",
              priority: Number(e.value.split(":")[0] ?? 0),
            },
          }))
          .filter((e) => e.level.priority !== undefined && e.level.name != ""),
      };

      if (props.isNew) {
        delete payload.status;
        return api.roles.roleAdd(payload);
      }

      return api.roles.roleUpd(props.item.id ?? "", payload);
    },
    onSuccess: (result) => {
      if (props.isNew) {
        navigate(`/roles/${result.data.id}`);
      } else {
        navigate(`/roles/${props.item.id}`);
      }
      setTimeout(() => {
        apiNotification.info({
          message: `Сохранение`,
          description: <>Данные сохранены</>,
        });
      }, 1000);
      resetCache();
    },
    onError: (err: any) => {
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось обновить</>,
      });
      resetCache();
    },
  });

  const handleDelete = useMutation({
    mutationFn: () => {
      return api.roles.rolesDelete({ ids: [props.item.id ?? ""] });
    },
    onSuccess: (result) => {
      navigate(`/roles`);
      apiNotification.info({
        message: `Удаление`,
        description: <>Роль удалена</>,
      });
      resetCache();
    },
    onError: (err: any) => {
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось удалить</>,
      });
      resetCache();
    },
  });

  const handleOk = async () => {
    try {
      await form.validateFields();
      handleUpdate.mutate();
    } catch (e) {
      // EMPTY
    }
  };

  function onChangeZone(zone_name: string, level: string, zone_id: string) {
    console.log({ zone_name, level, zone_id });
    setZoneValues([
      ...zoneValues.filter((e) => e.zone_id != zone_id),
      {
        zone_name,
        value: level,
        zone_id: zone_id,
        priority: Number(level.split(":")[0]),
      },
    ]);
  }

  function getUrlView(record: CategoryWithZones) {
    return record.zones !== undefined
      ? `/categories/${record.id}`
      : `/categories/${
          categoryZones?.find(
            (e) => e?.zones?.find((e1) => e1.id === record.id)
          )?.id ?? "0"
        }/access_zone/${record.id}`;
  }

  const columns: ColumnsType<CategoryWithZones & AccessZone> = [
    {
      title: "Наименование",
      dataIndex: "title",
      key: "title",
      render: (value, record, index) => {
        if (record.zones?.length) {
          return (
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <NavLink to={getUrlView(record)}>{value}</NavLink>
            </span>
          );
        }

        return (
          <>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <NavLink to={getUrlView(record)}>{value}</NavLink>
          </>
        );
      },
    },
    {
      title: "Уровень",
      dataIndex: "level",
      key: "level",
      width: 350,
      render: (value, record, index) => {
        if (record?.zones) {
          return "";
        }

        const permission = zoneValues.find((e) => e.zone_id === record.id);
        let val = permission?.value ?? "";
        const levels = record?.levels?.map((e) => ({
          value: `${e.priority}:${e.name}`,
          label: e.title,
          name: e.name,
        })) ?? []

        return (
          <div onClick={(e) => stopEvent(e)}>
            <Select
              value={val}
              onChange={(level) =>
                onChangeZone(record.name ?? "", level, record.id ?? "")
              }
              style={{ width: "100%" }}
              options={
                [{ value: "", label: "Без доступа" }, ...levels,]
                  .filter(e => e.value == '' ? !levels.find(e => e.name == 'N') : true)
              }
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={"role_form"}>
      <FormHeader
        onSubmit={handleOk}
        onCancel={() =>
          navigate(props.isNew ? "/roles" : `/roles/${props.item.id}`)
        }
        title={props.isNew ? "Добавление роли" : `Изменение роли`}
      />
      <Form
        className={""}
        style={{ marginBottom: "20px", marginTop: "20px" }}
        layout="vertical"
        initialValues={props.item}
        onFinish={handleOk}
        onFinishFailed={() => {}}
        autoComplete="off"
        form={form}
        disabled={handleUpdate.isLoading || zonesLoading}
      >
        <div
          className={"form_row_inline"}
          style={{  gridTemplateColumns: !props.isNew ? "50% 1fr auto" : "50% 1fr" }}
        >
          <Form.Item<FieldType>
            label="Наименование"
            name="title"
            rules={[
              { required: true, message: "Поле обязательно для заполнения" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Код"
            name="name"
            rules={[
              { required: true, message: "Поле обязательно для заполнения" },
            ]}
          >
            <Input />
          </Form.Item>
          {!props.isNew && (
            <Form.Item<FieldType>
              label="Статус роли"
              name="status"
              rules={[
                { required: true, message: "Поле обязательно для заполнения" },
              ]}
            >
              <Radio.Group>
                <Radio.Button value="active">Активна</Radio.Button>
                <Radio.Button value="archive">В архиве</Radio.Button>
              </Radio.Group>
            </Form.Item>
          )}
        </div>
        <div
          className={"form_row_inline"}
          style={{ gridTemplateColumns: "50% 1fr" }}
        >
          <Form.Item<FieldType> label="Описание" name="description" rules={[]}>
            <Input />
          </Form.Item>
          <Form.Item<FieldType> label="Группа из LDAP-каталога" name="ad_group" rules={[]}>
            <Input placeholder="Введите наименование группы из LDAP-каталога" />
          </Form.Item>
        </div>
      </Form>

      <AppTable
        className={"RoleFormTable"}
        columns={columns}
        items={categoryZones.map((e) => {
          if (e.zones?.length) {
            return { ...e, children: e.zones, key: e.id };
          }
          return { ...e, key: e.id };
        })}
        expandRowByClick={true}
        expandedRowKeys={categoryZones.map((e) => e.id ?? "") ?? []}
      />

      <br />
      <br />

      {/*<div style={{display: 'flex', justifyContent: 'flex-end'}}>*/}
      {/*    {!props.isNew && <Button type="default" onClick={() => handleDelete.mutate()}>Удалить роль</Button>}*/}
      {/*</div>*/}
    </div>
  );
};

export default RoleForm;
