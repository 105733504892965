import React, {FunctionComponent} from "react";
import {Button, Form, Input, Modal} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import useCategoryActions from "../../../actions/useCategoryActions";
import {CategoryWithZones} from "../../../Api";
import CategoryZonesList from "./CategoryZonesList";
import CategoryZonesListEdit from "./CategoryZonesListEdit";
import FormHeader from "../../../shared/FormHeader";

type FieldType = {
    title?: string;
    desc?: string;
};

const CategoryForm: FunctionComponent<{ isAdd: boolean }> = function (props) {
    let {id} = useParams();
    const {
        zoneCategory,
        zoneCategoryLoading,
        updateZoneCategory,
        deleteZoneCategory
    } = useCategoryActions(id)
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const handleOk = async () => {
        try {
            await form.validateFields()
            updateZoneCategory.mutate({id: id ?? '', payload: form.getFieldsValue(), isNew: props.isAdd ?? false,})
        } catch (e) {
            // EMPTY
        }

    };

    const handleDelete = async () => {
        Modal.confirm({
            title: 'Удалить данные?',
            icon: <ExclamationCircleFilled/>,
            okText: 'Удалить',
            okType: 'danger',
            cancelText: 'Отмена',
            onOk() {
                deleteZoneCategory.mutate([id ?? ''])
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleCancel = () => {
        if (props.isAdd)
        navigate(`/categories`)
        else 
        navigate(`/categories/${id}`)
    };

    return <>
        <FormHeader
            title={props.isAdd ? 'Добавление категории доступа' : 'Изменение категории доступа'}
            onCancel={handleCancel}
            onSubmit={handleOk}
        />
        {(props.isAdd || zoneCategory.title) && <Form
            layout={'vertical'}
            initialValues={zoneCategory}
            onFinish={handleOk}
            onFinishFailed={() => {
            }}
            autoComplete="off"
            disabled={zoneCategoryLoading}
            onChange={console.log}
            form={form}
        >
            <div className={'form_row_inline'} style={{gridTemplateColumns: '1fr 3fr'}}>
                <Form.Item<CategoryWithZones>
                    label="Наименование"
                    name="title"
                    rules={[{required: true, message: 'Поле обязательно для заполнения'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item<CategoryWithZones>
                    label="Описание"
                    name="description"
                >
                    <Input/>
                </Form.Item>
            </div>
        </Form>}

        <hr />
        <br />

        <CategoryZonesListEdit allowActions={true} />

        <br/>

        <div
            style={{display: 'flex'}}>
            {/*{!props.isAdd && <>*/}
            {/*    <Button*/}
            {/*        type="default"*/}
            {/*        loading={zoneCategoryLoading}*/}
            {/*        onClick={handleDelete}*/}
            {/*        style={{marginLeft: 'auto'}}*/}
            {/*    >*/}
            {/*        Удалить категорию*/}
            {/*    </Button>&nbsp;*/}
            {/*</>}*/}
        </div>


    </>
}

export default CategoryForm;
