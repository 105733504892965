import { api, resetApi } from "../api_client";
import Cookies from "js-cookie";

export function checkTimeRefresh() {
  return (
    Cookies.get("refresh_token") &&
    Number(Cookies.get("time_token")) - new Date().getTime() / 1000 <
      30
  );
}

export async function refreshTokenFun() {
  console.log("Refresh token");

  if (Cookies.get("token_refresh_lock") == "1") {
    return new Promise((resolve, reject) => setTimeout(resolve, 5000));
  }

  if (checkTimeRefresh()) {
    Cookies.set("token_refresh_lock", "1", {domain: process.env.REACT_APP_BASE_DOMAIN});

    try {
      const res = await fetch(process.env.REACT_APP_API_URL + "/api/refresh-token", {
        "headers": {
          "authorization": `Bearer ${Cookies.get('token')}`,
          "content-type": "application/json",
        },
        "referrerPolicy": "no-referrer",
        "body": JSON.stringify({
          refresh_token: Cookies.get('refresh_token')
        }),
        "method": "POST"
      }).then(e => e.json());

      console.log('refresh_token', JSON.stringify(res));


      Cookies.set("token", res.access_token ?? "", {domain: process.env.REACT_APP_BASE_DOMAIN});
      Cookies.set("refresh_token", res.refresh_token ?? "", {domain: process.env.REACT_APP_BASE_DOMAIN});
      Cookies.set("time_token", `${res.expires_in}`, {domain: process.env.REACT_APP_BASE_DOMAIN});

      resetApi(res.access_token);
    } catch (e) {}

    Cookies.remove('token_refresh_lock');
  }
}

export default function () {
  // refreshTokenFun();
  // setInterval(() => refreshTokenFun(), 10000);
}
