import React, { FunctionComponent, useEffect, useState } from "react";
import Layout from "../../shared/Layout";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Tabs, Tag } from "antd";
import GroupDataForm from "./components/GroupDataForm";
import GroupAccessList from "./components/GroupAccessList";
import GroupGroupsList from "./components/GroupGroupsList";
import useGroupsAction from "../../actions/useGroupsAction";
import TagActive from "../../shared/TagActive";
import ViewHeader from "../../shared/ViewHeader";
import { accessKeys } from "../../shared/parseJwt";

const GroupView: FunctionComponent<{}> = (props) => {
  let { id } = useParams();
  const [tab, setTab] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (tab !== "") {
      setSearchParams({ ...searchParams, tab: tab });
    }
  }, [tab]);

  useEffect(() => {
    if (searchParams.get("tab") !== tab) {
      setTab(searchParams.get("tab") ?? "accesses");
    }
  }, [searchParams]);

  const { group, groupLoading, groupError } = useGroupsAction({ id });

  return (
    <Layout
      title={group?.title ?? "Группа"}
      back={{ title: "Группы", link: "/groups", titleBack: "Группы" }}
      loading={groupLoading}
      error={groupError as any}
    >
      <ViewHeader
        group={group}
        linkEdit={`/groups/${group.id}/edit`}
        accessKey={accessKeys.groups}
      />

      <div style={{ display: "flex", flexDirection: "column", gridGap: "7px" }}>
        <div>
          Роли:{" "}
          {group?.roles?.map((role) => (
            <Tag key={`role_${role.id}`}>{role.title}</Tag>
          ))}
        </div>
        <div>
          Дочерние группы:{" "}
          {group?.children?.map((subgroup) => (
            <Tag key={`subgroup_${subgroup.id}`}>{subgroup.title}</Tag>
          ))}
        </div>
        <div>
          Пользователи:{" "}
          {group?.users?.map((user) => (
            <Tag key={`user_${user.id}`}>{user.full_name}</Tag>
          ))}
        </div>
      </div>

      <Tabs
        key={tab == "" ? "" : "tab"}
        defaultActiveKey={tab}
        items={[
          { key: "accesses", label: "Доступы" },
          { key: "groups", label: "Родительские группы" },
        ]}
        onChange={setTab}
      />

      {tab === "accesses" && <GroupAccessList groupId={id ?? ""} />}

      {tab === "groups" && <GroupGroupsList groupId={id ?? ""} />}
    </Layout>
  );
};

export default GroupView;
