import { Modal, Popconfirm } from "antd";
import {
  CheckOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React from "react";
import { stopEvent } from "./stopEvent";
import AppIcon from "./Icons/AppIcon";
import { Link, useNavigate } from "react-router-dom";
import { checkAccess } from "./parseJwt";

export function BtnDelete(props: {
  onOk: () => void;
  linkView: string;
  editClick?: () => void;
  okeyClick?: () => void;
  addLink?: string;
  keyAccess: string;
  values: string[];
}) {
  const navigation = useNavigate();

  if (!checkAccess(props.keyAccess, props.values)) return <></>;

  return (
    <span
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        gridGap: "15px",
        maxWidth: "120px",
      }}
      onClick={(event) => stopEvent(event)}
    >
      {props.addLink && (
        <Link
          to={props.addLink}
          onClick={(event) => {
            navigation(props.addLink ?? "");
            stopEvent(event);
          }}
        >
          <span  className="btn_delete_action">
            <PlusCircleOutlined
              style={{ color: "var(--sunset-orange-6, #FA8C16)" }}
            />
          </span>
        </Link>
      )}

      {props.linkView && (
        <span onClick={(e) => stopEvent(e)}>
          <Link to={props.linkView}>
            <span className="btn_delete_action">
              <EditOutlined
                style={{ color: "var(--sunset-orange-6, #FA8C16)" }}
              />
            </span>
          </Link>
        </span>
      )}
      {props.editClick && (
        <span
          onClick={(e) => {
            props.editClick!();
            stopEvent(e);
          }}
        >
          <span className="btn_delete_action">
            <EditOutlined
              style={{ color: "var(--sunset-orange-6, #FA8C16)" }}
            />
          </span>
        </span>
      )}
      {props.okeyClick && (
        <span
          onClick={(e) => {
            props.okeyClick!();
            stopEvent(e);
          }}
        >
          <span className="btn_delete_action">
            <CheckOutlined
              style={{ color: "var(--sunset-orange-6, #FA8C16)" }}
            />
          </span>
        </span>
      )}

      <Popconfirm
        placement="topLeft"
        title={"Уверены в удалении?"}
        onConfirm={(event) => {
          props.onOk();
          stopEvent(event as any);
        }}
        okText="Да"
        cancelText="Нет"
      >
        <AppIcon
          active={false}
          path={"/layout/delete_table"}
          onClick={(event: any) => {
            stopEvent(event);
            return false;
          }}
        />
      </Popconfirm>
    </span>
  );

  // return (
  //     <AppIcon
  //         active={false}
  //         path={'/layout/delete_table'}
  //         onClick={(event: any) => {
  //             showDeleteConfirm(props.onOk);
  //             stopEvent(event)
  //             return false;
  //         }}
  //     />
  // );
}

const showDeleteConfirm = (onOk: (...args: any[]) => any) => {
  Modal.confirm({
    title: "Удалить данные?",
    icon: <ExclamationCircleFilled />,
    okText: "Удалить",
    okType: "danger",
    cancelText: "Отмена",
    onOk: onOk,
    onCancel() {
      console.log("Cancel");
    },
  });
};

export default showDeleteConfirm;
