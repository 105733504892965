import React, { FunctionComponent, useState, useEffect } from "react";
import { Pagination, Tooltip } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { historyApi } from "../../api_client";
import { dateTimeFormatter, getDateISO } from "../../shared/dateFormat";
import { useNameEntity } from "./historyMapKey";
import { HistoryParams } from "../../emptyModels/historyParams";
import HistoryItemHeader from "./components/HistoryItemHeader";
import HistoryItemDesc from "./components/HistoryItemDesc";

const HistoryList: FunctionComponent<{ users: string[] }> = (props) => {
  const nameEntity = useNameEntity();

  let nameEntityQuery = nameEntity.entity == 'AccessZoneCategory' ? 'AccessZoneCategory' : nameEntity.entity as any

  let paramsBase: HistoryParams = nameEntity.entity
    ? nameEntity.id
      ? { object_type: nameEntityQuery, object_ids: [nameEntity.id] }
      : { object_type: nameEntityQuery }
    : {};

  const [params, setParams] = useState<HistoryParams>(paramsBase);
  const { data, error, isLoading } = useQuery({
    queryKey: [`history_${JSON.stringify(params)}`],
    queryFn: () => historyApi.changes.changesList(params)?.then((e) => e.data),
  });

  useEffect(() => {
    setParams({ ...params, user_ids: props.users });
  }, [props.users]);

  const isGlobal = window.location.pathname == '/' || window.location.pathname == '/categories';

  return (
    <div className={"history_list"}>
      {isLoading && <>Loading...</>}
      {!isLoading && !!error && <>Не удалось получить данные</>}
      {!isLoading && !data?.data?.length && <>Ничего не найдено</>}
      {!isLoading && (
        <table>
          {data?.data?.map((record, index) => (
            <tr>
              <td className={"history_datetime"}>
                <>
                  {dateTimeFormatter(
                    getDateISO(new Date((record.timestamp ?? 0) * 1000))
                  )}
                </>
              </td>
              <td>
                <>
                  {/*{dateTimeFormatter(getDateISO(new Date((record.timestamp ?? 0) * 1000)))}*/}
                </>
              </td>
              <td>
                <>
                  <HistoryItemHeader
                    isGlobal={isGlobal}
                    user={record?.changes?.user}
                    operation={record?.operation}
                    record={record}
                  />
                  <HistoryItemDesc record={record} />
                </>
              </td>
            </tr>
          ))}
        </table>
      )}

      <br />
      <Pagination
        defaultCurrent={data?.meta?.current_page ?? 1}
        total={data?.meta?.total ?? 0}
        onChange={(page) => setParams({ ...params, page })}
        showSizeChanger={false}
        hideOnSinglePage={true}
      />
    </div>
  );
};

export default HistoryList;
