import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { api, resetApi } from "../../api_client";
import { getAccess } from "../../shared/parseJwt";
import AppIcon from "../../shared/Icons/AppIcon";
import { useQuery } from "react-query";
import Cookies from "js-cookie";


type FieldType = {
    username?: string;
    password?: string;
};

function AuthScreen() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({ message: '', code: '' })
    const [form] = Form.useForm();

    function handleRestorePassword(event: any) {
        navigate('/restore_password');
        event.preventDefault()
    }

    const onFinish = async (values: any) => {
        setLoading(true)
        console.log('Success:', values);

        try {
            const res = await api.login.login({ login: values.username, password: values.password })
            console.log({ res })

            Cookies.set('token', res.data.access_token ?? '', {domain: process.env.REACT_APP_BASE_DOMAIN});
            Cookies.set('refresh_token', res.data.refresh_token ?? '', {domain: process.env.REACT_APP_BASE_DOMAIN});
            Cookies.set('time_token', `${res.data.expires_in}`, {domain: process.env.REACT_APP_BASE_DOMAIN});
            Cookies.remove('token_refresh_lock', {domain: process.env.REACT_APP_BASE_DOMAIN})

            resetApi(res.data.access_token);

            (window as any).access = getAccess(Cookies.get('token') ?? '');

            setTimeout(() => navigate('/'), 200);
        } catch (e) {
            if ((e as any)?.error?.error_auth_be?.http_body) {
                const err = JSON.parse((e as any)?.error?.error_auth_be?.http_body);
                setError(err ?? {})

                form.validateFields(['username', 'password'])

                form.submit()
                
            }
        }

        setLoading(false)
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div className="auth_screen">
            <img src={'images/logo.svg'} className={'logo'} alt={'Logo'} />
            <Form
                className={'auth_form'}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
                onChange={() => {
                    setError({code: '', message: ''})
                }}
            >
                <Form.Item<FieldType>
                    name="username"
                    rules={[
                        { required: true, message: 'Введите логин' },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (error?.code) {
                                    return Promise.reject(new Error(''));
                                }
                                return Promise.resolve();
                            },
                        }),    
                    ]}
                >
                    <Input
                        disabled={loading}
                        placeholder={'Логин'}
                        prefix={<UserOutlined />}
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    name="password"
                    rules={[
                        { required: true, message: 'Введите пароль' },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (error?.code) {
                                    return Promise.reject(new Error("Неверный логин или пароль"));
                                }
                                return Promise.resolve();
                            },
                        }),    
                    ]}
                >
                    <Input.Password
                        disabled={loading}
                        placeholder={'Пароль'}
                        prefix={<LockOutlined />}
                    />
                </Form.Item>

                <Form.Item>
                    <Button disabled={loading} className={'btn_submit'} type="primary" htmlType="submit">Войти</Button>
                </Form.Item>
                <Form.Item>
                    <Button disabled={loading} className={'btn_restore_pass'} type="text"
                        onClick={handleRestorePassword}>Восстановить
                        пароль</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default AuthScreen;
