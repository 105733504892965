import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { CreateCategoryRequest } from "../Api";
import { api } from "../api_client";
import { getEmptyCategoryWithZones } from "../emptyModels/getEmptyAccessZone";
import { useOpenNotifications } from "../shared/useOpenNotifications";

export function useCategoryActionList() {
  const { data, error, isLoading } = useQuery({
    queryKey: [`zone_categories`],
    queryFn: () =>
      api.zoneCategories
        .zoneCategoriesList()
        .then((e) => e.data?.map((e) => ({ ...e, key: e.id }))),
  });

  return {
    zoneCategories: data ?? [],
    zoneCategoriesLoading: isLoading,
    zoneCategoriesError: error,
  };
}

export default function (id?: string) {
  const {
    data: item,
    error: itemError,
    isLoading: itemLoading,
  } = id && id != "undefined"
    ? useQuery({
        queryKey: [`zone_categories_${id}`],
        queryFn: () =>
          api.zoneCategories.zoneCategoriesDetail(id).then((e) => ({
            ...e.data,
            zones: e.data.zones?.map((e) => ({ ...e, key: e.id })),
          })),
      })
    : {
        data: undefined,
        error: undefined,
        isLoading: false,
      };
  const { apiNotification } = useOpenNotifications();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  function resetCache(id?: string) {
    queryClient.invalidateQueries({ queryKey: ["zones"] });
    queryClient.invalidateQueries({ queryKey: ["zone_categories"] });
    if (id) {
      queryClient.invalidateQueries({ queryKey: [`zone_categories_${id}`] });
    }
  }

  const mutationUpdateZoneCategory = useMutation({
    mutationFn: (params: {
      id: string;
      payload: CreateCategoryRequest;
      isNew: boolean;
    }) => {
      if (params.isNew) {
        // delete params.payload.status;
        return api.zoneCategories.zoneCategoriesCreate(params.payload);
      }

      return api.zoneCategories.zoneCategoriesUpdate(params.id, params.payload);
    },
    onSuccess: (result, params) => {
      if (params.isNew) {
        navigate(`/categories/${result.data.id}`);
      } else {
        navigate(`/categories/${params.id}`);
      }
      setTimeout(() => {
        apiNotification.info({
          message: `Сохранение`,
          description: <>Данные сохранены</>,
        });
      }, 1000);
      resetCache(params.id);
    },
    onError: (err: any, params) => {
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось обновить</>,
      });
      resetCache(params.id);
    },
  });

  const mutationDeleteZoneCategory = useMutation({
    mutationFn: async (ids: string[]) => {
      await api.zoneCategories.zoneCategoriesDelete({ ids: ids });
      await api.zones.zonesDelete({ ids: ids });
    },
    onSuccess: (result, ids) => {
      resetCache();
      apiNotification.info({
        message: `Удаление`,
        description: <>Категория удалена</>,
      });
      ids.forEach((id) => resetCache(id));
      navigate(`/categories`);
    },
    onError: (err: any, ids) => {
      resetCache();
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось удалить</>,
      });
      ids.forEach((id) => resetCache(id));
    },
  });

  const mutationSortZoneCategory = useMutation({
    mutationFn: (ids: string[]) => {
      return api.zoneCategories.sortCreate({ ids: ids });
    },
    onSuccess: (result, ids) => {
      apiNotification.info({
        message: `Сортировка`,
        description: <>Порядок обновлен</>,
      });
      resetCache(id);
    },
    onError: (err: any, ids) => {
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось обновить</>,
      });
      resetCache(id);
    },
  });

  return {
    zoneCategory: item ?? getEmptyCategoryWithZones(),
    zoneCategoryLoading:
      itemLoading ||
      mutationUpdateZoneCategory.isLoading ||
      mutationDeleteZoneCategory.isLoading ||
      mutationSortZoneCategory.isLoading,
    zoneCategoryError: itemError,

    updateZoneCategory: mutationUpdateZoneCategory,
    deleteZoneCategory: mutationDeleteZoneCategory,
    sortZoneCategory: mutationSortZoneCategory,
  };
}
