import {FunctionComponent} from "react";
import {Button, Tag} from "antd";

const FormHeader: FunctionComponent<{onSubmit: () => void, onCancel: () => void, title: string, tag?: string}> = (props) => (
    <div style={{display: 'flex', alignItems: 'center', gridGap: '10px'}}>
        <h3>{props.title}</h3>
        {props.tag && <Tag color={'var(--pnk-color-6)'}>{props.tag}</Tag>}
        <Button style={{marginLeft: 'auto'}} type={'link'}  onClick={props.onCancel}>Отмена</Button>
        <Button type={'primary'} onClick={props.onSubmit}>Сохранить</Button>
    </div>
)

export default FormHeader;