/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {contentFormatters} from "./api_client";

export interface MetaData {
  /**
   * Номер текущей страницы
   * @example 1
   */
  current_page?: number;
  /**
   * Количество элементов на странице
   * @example 10
   */
  per_page?: number;
  /**
   * Общее количество элементов
   * @example 20
   */
  total?: number;
  /**
   * Номер последней страницы
   * @example 2
   */
  last_page?: number;
}

export interface DeleteManyRequest {
  ids?: string[];
}

export interface SimpleResponse {
  /**
   * Результат выполнения запроса
   * @example true
   */
  success?: boolean;
}

export interface Error {
  /**
   * Сообщение об ошибке
   * @example "Ошибка"
   */
  message?: string;
  /** @example "ERROR_EXCEPTION" */
  code?: string;
}

export interface LoginRequest {
  /**
   * Логин пользователя
   * @example "i.ivanov"
   */
  login: string;
  /**
   * Пароль пользователя
   * @format password
   * @example "password"
   */
  password: string;
}

export interface AuthToken {
  /**
   * Токен доступа
   * @example "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUzYjBjNDQyOThmYzFjMTQ5YWZiZjRjODk5NmZiOTI0MjdhZTQxZTQ2NDliOTM0Y2E0OTU5OTFiNzg1MmI4NTUifQ.eyJhdWQiOiJodHRwczovL2F1dGguZGV2ZWxvcC5wbmsuaXRtZWdhc3Rhci5jb20iLCJlbWFpbCI6Iml2YW5vbnYuaUBtYWlsLmNvbSIsImV4cCI6MTY5NzY5NDgyNywiZ3JvdXBzIjpbXSwiaWF0IjoxNjk3NjA4NDI3LCJqdGkiOiI1ODZlOTY1Ni1kYWZhLTQ2NjMtYjE0OS03NDg5YTk0M2NmMWYiLCJwZXJtaXNzaW9ucyI6W10sInJvbGVzIjpbXSwic3ViIjoiNTNlMjU3YWMtNDQ4YS00MzEwLTkzNzUtZTUyNzI0ZGY3NzRkIiwidG9rZW5fdHlwZSI6ImFjY2VzcyJ9.wX_BQyurOnoXHQH59o7FDWRIlAdOJNgSdIYpuNoW12bGi2Cr_FH3w7emPovlCzimL-XKbls1nwgwrPvgoDqxsh8b7XPwUx9jlak6XHxhMANp_zPyvz_-26eTdjC6wI9y3YgoqPzd21mGMZDg0Bmf1aAsSxpyEqJ6Lv5KNU2osbCOzUQ0GCHCmuxLmTWlMAa-ud-H9oSirGuM3MRAipdzzydocN8JruuzMNhKdmzehHEojOMXczLKqOUXy8L9sg-E2Ea5AS4xQVNRzaKO2SWpDi6b1B3Cdv4028-0nQMleFAVMtEUBaiOzwnCq7UKrJoDxNx-0eIOzpotbCcelGdt1A"
   */
  access_token?: string;
  /**
   * Токен для обновления токена доступа
   * @example "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUzYjBjNDQyOThmYzFjMTQ5YWZiZjRjODk5NmZiOTI0MjdhZTQxZTQ2NDliOTM0Y2E0OTU5OTFiNzg1MmI4NTUifQ.eyJhdWQiOiJodHRwczovL2F1dGguZGV2ZWxvcC5wbmsuaXRtZWdhc3Rhci5jb20iLCJleHAiOjE2OTk2ODIwMjcsImZhbWlseSI6IjQ5NzYyOWNhLTNlYzMtNGM4NS04MTA1LTJkNDVlNTJiMDdlNyIsImlhdCI6MTY5NzYwODQyNywianRpIjoiNGI1MjA2MzktOWVmNC00YmFlLTg1NjgtNzA3ODkzZjk3MGFjIiwic3ViIjoiNTNlMjU3YWMtNDQ4YS00MzEwLTkzNzUtZTUyNzI0ZGY3NzRkIiwidG9rZW5fdHlwZSI6InJlZnJlc2gifQ.YvrwyfGiBii1VJGrA_qVhUrZvwabCzYWJkuzLCIL43zYEbSoDMZ3POLm5BspFBzvyrBCfUfIbQMrH4SvgxeRkiuvtU5kIRNFmWYNDCiBHPHU_jkNoxuwCKfM8GL-3kbkml8TkHBFBys0_pe_C6Lc7bnfHl0JucswTfJQdccaqN6A0bIyUtSkrt4hA7gvEf8TP9zhOPEDh1gxZl86O_enGGqKDaqBBNy9LhQiD1WWQty-_hruMOYxAcltOz_fa5wW9ng2yZ9XV74iT6Sr3BDXbIM2PI2dP8Pl0Byw3mFfyFhumvR1BjUrVuN7Okod0WizrFaYMGCk_lCR9GAUrLTxng"
   */
  refresh_token?: string;
  /**
   * Тип токена доступа
   * @example "bearer"
   */
  token_type?: string;
  /**
   * До какого времени валиден токен доступа
   * @example 1673394870
   */
  expires_in?: number;
}

export interface RefreshTokenRequest {
  /**
   * Токен для получения нового токена доступа
   * @example "8xLOxBtZp8"
   */
  refresh_token: string;
}

export interface PasswordResetRequest {
  /**
   * Логин пользователя
   * @example "i.ivanov"
   */
  login: string;
}

export interface PasswordChangeRequest {
  /**
   * Одноразовый токен из письма после запроса на сброс пароля
   * @example "8xLOxBtZp8"
   */
  token: string;
  /**
   * Новый пароль пользователя
   * @format password
   * @example "password"
   */
  password: string;
  /**
   * Новый пароль пользователя (повтор)
   * @format password
   * @example "password"
   */
  password_confirm: string;
}

export interface RequestPermission {
  /**
   * Идентификатор зоны доступа
   * @format uuid
   */
  zone_id?: string;
  level?: {
    /**
     * Код уровня доступа
     * @example "read"
     */
    name?: string;
    /**
     * Приоритет уровня доступа, от меньшего к большему
     * @min 0
     */
    priority?: number;
  };
}

export interface AddRoleRequest {
  /**
   * Наименование роли
   * @maxLength 255
   * @example "Сотрудник"
   */
  title?: string;
  /**
   * Код роли
   * @maxLength 255
   * @example "employee"
   */
  name: string;
  /**
   * Описание роли
   * @maxLength 5000
   * @example "Простой сотрудник"
   */
  description?: string;
  /** Наименование группы в AD, если задано, то признак in_active_directory будет true */
  ad_group?: string;
  permissions?: RequestPermission[];
}

export interface ObjectPermission {
  /** @format uuid */
  id?: string;
  /**
   * Идентификтор роли
   * @format uuid
   */
  role_id?: string;
  /**
   * Идентификатор зоны доступа
   * @format uuid
   */
  zone_id?: string;
  /**
   * Уровень доступа к зоне, первая часть - это приоритет уровня доступа, а вторая - код уровня доступа
   * @example "2:level_write"
   */
  level?: string;
  /**
   * Наименование зоны доступа
   * @example "Пользователи"
   */
  zone_title?: string;
  /**
   * Код зоны доступа
   * @example "users"
   */
  zone_name?: string;
}

export interface Role {
  /**
   * Идентификатор роли
   * @format uuid
   */
  id?: string;
  /**
   * Наименование роли
   * @example "Администраторы"
   */
  title?: string;
  /**
   * Код роли
   * @example "admins"
   */
  name?: string;
  /**
   * Описание роли
   * @example "Роль администраторов системы"
   */
  description?: string;
  /**
   * Статус акивен/архивен
   * @example "active"
   */
  status?: string;
  /** Признак роли суперпользователя */
  is_superuser?: boolean;
  /** Признак группы в AD */
  in_active_directory?: boolean;
  /** Название группы в AD */
  ad_group?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
  /** Настройки доступов к зонам */
  permissions?: UserCategoryWithZones[];
}

export interface SimpleRole {
  /**
   * Идентификатор роли
   * @format uuid
   */
  id?: string;
  /**
   * Наименование роли
   * @example "Сотрудник"
   */
  title?: string;
  /**
   * Код роли
   * @example "employee"
   */
  name?: string;
  /**
   * Описание роли
   * @example "Простой сотрудник"
   */
  description?: string;
  /**
   * Статус активности/архивности роли
   * @example "active"
   */
  status?: string;
  /** Признак роли суперпользователя */
  is_superuser?: boolean;
  /** Признак группы в AD */
  in_active_directory?: boolean;
  /** Название группы в AD */
  ad_group?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export type RolesList = Role[];

export type RolesSimpleList = SimpleRole[];

export interface RoleUpdateRequest {
  /**
   * Наименование роли
   * @maxLength 255
   * @example "Сотрудник"
   */
  title?: string;
  /**
   * Код роли
   * @maxLength 255
   * @example "employee"
   */
  name?: string;
  /**
   * Описание роли
   * @maxLength 5000
   * @example "Простой сотрудник"
   */
  description?: string;
  /** Статус роли */
  status?: "active" | "archive";
  /** Наименование группы в AD, если задано, то признак in_active_directory будет true */
  ad_group?: string;
  /** Уровни доступа роли */
  permissions?: RequestPermission[];
}

export interface Group {
  /**
   * Идентификатор группы
   * @format uuid
   */
  id?: string;
  /**
   * Наименование группы
   * @example "Сотрудники"
   */
  title?: string;
  /**
   * Код группы
   * @example "employees"
   */
  name?: string;
  /**
   * Статус активности/архивности группы
   * @example "active"
   */
  status?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export interface GroupWithRoleList {
  /**
   * Идентификатор группы
   * @format uuid
   */
  id?: string;
  /**
   * Наименование группы
   * @example "Сотрудники"
   */
  title?: string;
  /**
   * Код группы
   * @example "employees"
   */
  name?: string;
  /**
   * Статус активности/архивности группы
   * @example "active"
   */
  status?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
  /** Список ролей группы */
  roles?: string[];
}

export interface GroupParent {
  /**
   * Идентификатор группы
   * @format uuid
   */
  id?: string;
  /**
   * Наименование группы
   * @example "Сотрудники"
   */
  title?: string;
  /**
   * Код группы
   * @example "employees"
   */
  name?: string;
  /**
   * Статус активности/архивности группы
   * @example "active"
   */
  status?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
  /**
   * Уровень родства с группой, если 1 - прямой предок, и т.д., чем больше тем дальше родство
   * @example 1
   */
  level?: number;
  /** Список ролей группы */
  roles?: string[];
}

export interface GroupAddUsersResponse {
  /**
   * Идентификатор группы
   * @format uuid
   */
  group_id?: string;
  /** Список пользователей */
  users?: User[];
}

export interface GroupExtended {
  /**
   * Идентификатор группы
   * @format uuid
   */
  id?: string;
  /**
   * Наименование группы
   * @example "Сотрудники"
   */
  title?: string;
  /**
   * Код группы
   * @example "employees"
   */
  name?: string;
  /**
   * Статус группы
   * @example "active"
   */
  status?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
  /** Список прямых дочерних групп */
  children?: Group[];
  /** Список ролей входящих в группу (не рекурсивно) */
  roles?: SimpleRole[];
  /** Список пользователей входящих в группу (не рекурсивно) */
  users?: User[];
}

export interface AddGroupRequest {
  /**
   * Наименование группы
   * @example "Сотрудники"
   */
  title: string;
  /**
   * Код группы
   * @example "employees"
   */
  name: string;
  /** Список дочерних групп */
  children?: string[];
  /** Список ролей в группе */
  roles?: string[];
  /** Список пользовтелей в группе */
  users?: string[];
}

export interface GroupUpdateRequest {
  /**
   * Наименование группы
   * @example "Сотрудники"
   */
  title?: string;
  /**
   * Код группы
   * @example "employees"
   */
  name?: string;
  /**
   * Статус группы
   * @example "active"
   */
  status?: string;
  /** Список дочерних групп */
  children?: string[];
  /** Список ролей в группе */
  roles?: string[];
  /** Список пользователей в группе */
  users?: string[];
}

export interface GroupAddUsersRequest {
  /** Список идентификаторов пользователей */
  users?: string[];
}

export interface User {
  /**
   * Идентификатор пользователя
   * @format uuid
   */
  id?: string;
  /**
   * ФИО пользователя
   * @example "Иванов Иван Иванович"
   */
  full_name?: string;
  /**
   * Логин пользователя
   * @example "i.ivanov"
   */
  login?: string;
  /**
   * @format email
   * @example "email@example.org"
   */
  email?: string;
  /** Идентификатор чата с ботом в Telegram */
  telegram_id?: string;
  /** Имя пользователя в Telegram */
  telegram_username?: string;
  /**
   * Статус акивности/архивности пользователя
   * @example "active"
   */
  status?: string;
  /** Признак суперпользователя */
  is_superuser?: boolean;
  /** Признак пользователя из AD */
  in_active_directory?: boolean;
  /**
   * @format float
   * @default "Лимит фактического превышения план бюджета в процентах"
   */
  exceeding_limit_percent?: number;
  /**
   * Ссылка на фото пользователя
   * @format url
   */
  avatar_url?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export interface UserWithRoles {
  /**
   * Идентификатор пользователя
   * @format uuid
   */
  id?: string;
  /**
   * ФИО пользователя
   * @example "Иванов Иван Иванович"
   */
  full_name?: string;
  /**
   * Логин пользователя
   * @example "i.ivanov"
   */
  login?: string;
  /**
   * @format email
   * @example "email@example.org"
   */
  email?: string;
  /** Идентификатор чата с ботом в Telegram */
  telegram_id?: string;
  /**
   * Статус акивности/архивности пользователя
   * @example "active"
   */
  status?: string;
  /** Признак суперпользователя */
  is_superuser?: boolean;
  /** Признак пользователя из AD */
  in_active_directory?: boolean;
  /**
   * @format float
   * @default "Лимит фактического превышения план бюджета в процентах"
   */
  exceeding_limit_percent?: number;
  /**
   * Ссылка на фото пользователя
   * @format url
   */
  avatar_url?: string;
  /** Обычные роли системы */
  roles?: SimpleRole[];
  /** Базовые роли Active Directory */
  base_roles?: SimpleRole[];
  groups?: Group[];
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export interface AddUserRequest {
  /**
   * ФИО
   * @example "Иванов Иван Иванович"
   */
  full_name: string;
  /**
   * Логин пользователя
   * @example "i.ivanov"
   */
  login: string;
  /**
   * Адрес эл. почты
   * @format email
   * @example "email@example.org"
   */
  email?: string;
  /** Идентификтор чата с ботом в Telegram */
  telegram_id?: string;
  /**
   * Пароль пользователя
   * @format password
   * @example "password"
   */
  password: string;
  /** Статус пользователя */
  status?: "active" | "archive";
  /**
   * Лимит фактического превышения план бюджета в процентах
   * @format float
   * @min 0
   */
  exceeding_limit_percent?: number;
  /**
   * Изображение для аватара пользователя, максимальный размер 5Mb (png, img, webp)
   * @format binary
   */
  file?: File;
  /** Список ролей пользователя */
  "roles[]"?: string[];
  /** Список групп пользователя */
  "groups[]"?: string[];
}

export interface UpdateMeRequest {
  /**
   * ФИО
   * @example "Иванов Иван Иванович"
   */
  full_name?: string;
  /**
   * Логин пользователя
   * @example "i.ivanov"
   */
  login?: string;
  /**
   * Адрес эл. почты
   * @format email
   * @example "email@example.org"
   */
  email?: string;
  /** Идентификтор чата с ботом в Telegram */
  telegram_id?: string;
  /** Имя пользователя в Telegram */
  telegram_username?: string;
  /**
   * Пароль пользователя
   * @format password
   * @example "password"
   */
  password?: string;
  /**
   * Изображение для аватара пользователя, максимальный размер 5Mb (png, img, webp)
   * @format binary
   */
  file?: File;
  /**
   * Принзнак отсылать (1) или не отсылать (0) приветственное сообщение в Telegram
   * @min 0
   * @max 1
   */
  init_telegram?: number;
}

export interface UpdateUserRequest {
  /**
   * ФИО
   * @example "Иванов Иван Иванович"
   */
  full_name?: string;
  /**
   * Логин пользователя
   * @example "i.ivanov"
   */
  login?: string;
  /**
   * Адрес эл. почты
   * @format email
   * @example "email@example.org"
   */
  email?: string;
  /** Идентификтор чата с ботом в Telegram */
  telegram_id?: string;
  /**
   * Пароль пользователя
   * @format password
   * @example "password"
   */
  password?: string;
  /** Статус пользователя */
  status?: "active" | "archive";
  /**
   * Лимит фактического превышения план бюджета в процентах
   * @format float
   * @min 0
   */
  exceeding_limit_percent?: number;
  /**
   * Изображение для аватара пользователя, максимальный размер 5Mb (png, img, webp)
   * @format binary
   */
  file?: File;
  /** Список ролей пользователя */
  "roles[]"?: string[];
  /** Список групп пользователя */
  "groups[]"?: string[];
}

export interface UserRolesRequest {
  /** Список идентификаторов ролей */
  roles: string[];
}

export interface UserRoles {
  /**
   * Идентификатор пользователя
   * @format uuid
   */
  id?: string;
  /** Список ролей пользователя */
  roles?: SimpleRole[];
}

export interface UserGroupsRequest {
  /** Список идентификаторов групп */
  groups: string[];
}

export interface UserGroups {
  /**
   * Идентификатор пользователя
   * @format uuid
   */
  id?: string;
  /** Список групп */
  groups?: Group[];
}

export interface UserList {
  data?: User[];
  meta?: MetaData;
}

export interface GroupList {
  /** Список групп */
  data?: Group[];
  meta?: MetaData;
}

export interface GroupListWithRoles {
  data?: GroupWithRoleList[];
  meta?: MetaData;
}

export type GroupParentList = GroupParent[];

export interface Setting {
  /**
   * Идентификтор настройки
   * @format uuid
   */
  id?: string;
  /**
   * Наименование настройки
   * @example "Текст ошибки проверки пароля"
   */
  title?: string;
  /**
   * Код настройки
   * @example "PASSWORD_ERROR_CHECK"
   */
  name?: string;
  /**
   * Значение настройки
   * @example "Ваш пароль недостаточно сложный"
   */
  value?: string;
  /** словарь индивидуальных свойств настройки (тип значения, ед. изм., мин. значение и т.д.) */
  properties?: object;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export type SettingsList = Setting[];

export interface SettingUpdateRequest {
  /**
   * Наименование настройки
   * @maxLength 255
   * @example "Текст ошибки проверки пароля"
   */
  title: string;
  /**
   * Значение настройки
   * @maxLength 255
   * @example "Ваш пароль недостаточно сложный"
   */
  value: string;
}

export interface SettingMassUpdateRequest {
  settings: {
    /**
     * Идентификтор настройки
     * @format uuid
     */
    id: string;
    data: SettingUpdateRequest;
  }[];
}

export interface ZoneCategory {
  /**
   * Идентификатор категории
   * @format uuid
   */
  id?: string;
  /**
   * Наименование категории
   * @example "RBAC"
   */
  title?: string;
  /** Описание категории */
  description?: string;
  /** Порядковый номер категории */
  order?: number;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export interface AccessZone {
  /**
   * Идентификатор зоны доступа
   * @format uuid
   */
  id?: string;
  /**
   * Наименование зоны доступа
   * @example "Пользователи"
   */
  title?: string;
  /**
   * Код зоны доступа
   * @example "users"
   */
  name?: string;
  /** Описание зоны доступа */
  description?: string;
  /** @format uuid */
  category_id?: string;
  /** Уровни доступа зоны */
  levels?: {
    /**
     * Наименование уровня доступа
     * @example "Чтение"
     */
    title?: string;
    /**
     * Код уровня доступа
     * @example "read"
     */
    name?: string;
    /**
     * Приоритет уровня доступа в зоне
     * @example 1
     */
    priority?: number;
  }[];
  /** Порядковый номер зоны в категории */
  order?: number;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export type AccessZoneList = AccessZone[];

export interface UserAccessZone {
  /**
   * Идентификатор зоны доступа
   * @format uuid
   */
  id?: string;
  /**
   * Наименование зоны доступа
   * @example "Пользователи"
   */
  title?: string;
  /**
   * Код зоны доступа
   * @example "users"
   */
  name?: string;
  /** @format uuid */
  category_id?: string;
  /**
   * Уровень доступа к зоне (первая часть приоритет, вторая код доступа)
   * @example "3:full"
   */
  level?: string;
  /**
   * Код уровня доступа
   * @example "full"
   */
  level_name?: string;
  /**
   * Наименование уровня доступа
   * @example "Полный"
   */
  level_title?: string;
  /** Порядковый номер зоны доступа */
  order?: number;
}

export interface CategoryWithZones {
  /**
   * Идентификатор категории
   * @format uuid
   */
  id?: string;
  /**
   * Наименование категории
   * @example "RBAC"
   */
  title?: string;
  /** Описание категории зон доступа */
  description?: string;
  /**
   * Порядковый номер категории в списке
   * @example 1
   */
  order?: number;
  /** Зоны доступа в категории */
  zones?: AccessZone[];
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export interface UserCategoryWithZones {
  /**
   * Идентификатор категории зоны доступа
   * @format uuid
   */
  id?: string;
  /**
   * Наименование категории зоны доступа
   * @example "Зоны доступа СПП"
   */
  title?: string;
  /** Описание категории зоны доступа */
  description?: string;
  /** Порядковый номер категории */
  order?: number;
  /** Зоны доступа в категории */
  zones?: UserAccessZone[];
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
}

export type CategoryList = ZoneCategory[];

export interface CreateCategoryRequest {
  /**
   * Наименование категории
   * @maxLength 255
   * @example "RBAC"
   */
  title: string;
  /**
   * Описание категории зон доступа
   * @maxLength 2000
   */
  description?: string;
  /**
   * Порядковый номер вывода категории в списке
   * @min 1
   */
  order?: number;
}

export interface UpdateCategoryRequest {
  /**
   * Наименование категории
   * @maxLength 255
   * @example "RBAC"
   */
  title?: string;
  /**
   * Описание категории зон доступа
   * @maxLength 2000
   */
  description?: string;
  /**
   * Порядковый номер вывода категории в списке
   * @min 1
   */
  order?: number;
}

export type CategoryWithZonesList = CategoryWithZones[];

export type UserCategoryWithZonesList = UserCategoryWithZones[];

export interface CreateZoneRequest {
  /**
   * Наименование зоны доступа
   * @example "Пользователи"
   */
  title: string;
  /**
   * Код зоны доступа
   * @example "users"
   */
  name: string;
  /** Описание зоны доступа */
  description?: string;
  /**
   * Идентификатор категории зоны доступа
   * @format uuid
   */
  category_id: string;
  /** Уровни доступа */
  levels: {
    /**
     * Наименование уровня доступа
     * @maxLength 255
     * @example "Редактирование"
     */
    title?: string;
    /**
     * Код уровня доступа
     * @maxLength 255
     * @example "write"
     */
    name?: string;
    /**
     * Приоритет зоны доступа
     * @min 0
     * @example 2
     */
    priority?: number;
  }[];
}

export interface UpdateZoneRequest {
  /**
   * Наименование зоны доступа
   * @example "Пользователи"
   */
  title?: string;
  /**
   * Код зоны доступа
   * @example "users"
   */
  name?: string;
  /** Описание зоны доступа */
  description?: string;
  /**
   * Идентификатор категории зоны доступа
   * @format uuid
   */
  category_id?: string;
  levels?: {
    /**
     * Наименование уровня доступа
     * @maxLength 255
     * @example "Редактирование"
     */
    title?: string;
    /**
     * Код уровня доступа
     * @maxLength 255
     * @example "write"
     */
    name?: string;
    /**
     * Приоритет зоны доступа
     * @min 0
     * @example 2
     */
    priority?: number;
  }[];
}

export interface HelperValue {
  /** Текст для отображения пользователю */
  text?: string;
  /** Значение для отправки на BE */
  value?: string;
  /** Означает что сущность находится не в статусе active(только для пользователей, групп и ролей) */
  disabled?: boolean;
}

export type HelperValueList = HelperValue[];

export interface HelperPageValueList {
  data?: HelperValue[];
  meta?: MetaData;
}

export interface HelperSettingsValue {
  /** Код настройки */
  name?: string;
  /** Наименование настройки */
  title?: string;
  /** Значение настройки */
  value?: string;
}

export type HelperSettingsValueList = HelperSettingsValue[];

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://auth.develop.pnk.itmegastar.com/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title PNK Auth Service
 * @version 1.0.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 * @termsOfService http://swagger.io/terms/
 * @baseUrl https://auth.develop.pnk.itmegastar.com/api
 * @externalDocs https://www.notion.so/megastar/v2-3388738db3264bd7b57b4cfe343e1690
 * @contact <d.kornilov@itmegabox.com>
 *
 * Сервис авторизации и управления правами пользователей проекта PNK
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  login = {
    /**
     * @description Получение access и refresh JWT-токенов для доступа к сервисам ПНК
     *
     * @tags Авторизация
     * @name Login
     * @summary Авторизация пользователя
     * @request POST:/login
     */
    login: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<AuthToken, Error>({
        path: `/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  refreshToken = {
    /**
     * @description Получение новых access и refresh JWT-токенов по refresh-токену
     *
     * @tags Авторизация
     * @name RefreshToken
     * @summary Обновление токена доступа
     * @request POST:/refresh-token
     * @secure
     */
    refreshToken: (data: RefreshTokenRequest, params: RequestParams = {}) =>
      this.request<AuthToken, void>({
        path: `/refresh-token`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  forgotPassword = {
    /**
     * @description Запрос на смену пароля по логину пользователя
     *
     * @tags Безопасность
     * @name PassReset
     * @summary Запрос на смену пароля
     * @request POST:/forgot-password
     */
    passReset: (data: PasswordResetRequest, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/forgot-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  resetPassword = {
    /**
     * @description Обновление пароля на новый с помощью одноразового токена
     *
     * @tags Безопасность
     * @name PassUpd
     * @summary Обновление пароля
     * @request POST:/reset-password
     */
    passUpd: (data: PasswordChangeRequest, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/reset-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  resetPasswordCheck = {
    /**
     * @description Проверка токена сброса пароля на валидность
     *
     * @tags Безопасность
     * @name ResetPasswordCheckList
     * @summary Проверка токена сброса пароля
     * @request GET:/reset-password-check
     */
    resetPasswordCheckList: (
      query: {
        /** Одноразовый токен на сброс пароля */
        token: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SimpleResponse, Error>({
        path: `/reset-password-check`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  roles = {
    /**
     * @description Плоский список ролей с настройками доступов к зонам
     *
     * @tags Роли
     * @name RolesList
     * @summary Список ролей
     * @request GET:/roles
     * @secure
     */
    rolesList: (
      query?: {
        /** Поиск по наименованию или коду роли */
        search?: string;
        /** Поиск по статусу роли */
        status?: "active" | "archive";
        /**
         * Поиск ролей из Active Directory
         * @min 0
         * @max 1
         */
        in_ad?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<RolesList, Error>({
        path: `/roles`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создание новой роли в системе СПП
     *
     * @tags Роли
     * @name RoleAdd
     * @summary Создание роли
     * @request POST:/roles
     * @secure
     */
    roleAdd: (data: AddRoleRequest, params: RequestParams = {}) =>
      this.request<Role, Error>({
        path: `/roles`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить роли по списку идентификаторов
     *
     * @tags Роли
     * @name RolesDelete
     * @summary Удаление нескольких ролей
     * @request DELETE:/roles
     * @secure
     */
    rolesDelete: (data: DeleteManyRequest, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/roles`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Получение детальной информации о роли
     *
     * @tags Роли
     * @name RolesDetail
     * @summary Просмотр роли
     * @request GET:/roles/{id}
     * @secure
     */
    rolesDetail: (id: string, params: RequestParams = {}) =>
      this.request<Role, Error>({
        path: `/roles/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные роли
     *
     * @tags Роли
     * @name RoleUpd
     * @summary Изменение роли
     * @request PUT:/roles/{id}
     * @secure
     */
    roleUpd: (id: string, data: RoleUpdateRequest, params: RequestParams = {}) =>
      this.request<Role, Error>({
        path: `/roles/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление одной роли по идентификатору
     *
     * @tags Роли
     * @name RoleDel
     * @summary Удаление роли
     * @request DELETE:/roles/{id}
     * @secure
     */
    roleDel: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/roles/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Список пользователей с этой ролью
     *
     * @tags Роли
     * @name UsersDetail
     * @summary Список пользователей
     * @request GET:/roles/{id}/users
     * @secure
     */
    usersDetail: (
      id: string,
      query?: {
        /** Поиск по ФИО или логину пользователя */
        search?: string;
        /**
         * Номер страницы
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @min 1
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserList, Error>({
        path: `/roles/${id}/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Список групп с этой ролью
     *
     * @tags Роли
     * @name GroupsDetail
     * @summary Список групп
     * @request GET:/roles/{id}/groups
     */
    groupsDetail: (
      id: string,
      query?: {
        /** Поиск по названию или коду группы */
        search?: string;
        /**
         * Номер страницы
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @min 1
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupList, Error>({
        path: `/roles/${id}/groups`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  groups = {
    /**
     * @description Получение списка групп с фильтрацией
     *
     * @tags Группы
     * @name GroupsList
     * @summary Список групп
     * @request GET:/groups
     * @secure
     */
    groupsList: (
      query?: {
        /** Поиск по наименованию или коду группы */
        search?: string;
        /** Поиск по статусу группы */
        status?: "active" | "archive";
        /**
         * Поиск групп с ролями (список uuid через запятую)
         * @format uuid
         */
        roles?: string;
        /**
         * Поиск по дочерней группе через ее идентификатор (список uuid через запятую)
         * @format uuid
         */
        groups?: string;
        /**
         * Номер страницы
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @min 1
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupList, Error>({
        path: `/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить новую группу в систему
     *
     * @tags Группы
     * @name GroupAdd
     * @summary Создание группы
     * @request POST:/groups
     * @secure
     */
    groupAdd: (data: AddGroupRequest, params: RequestParams = {}) =>
      this.request<GroupExtended, Error>({
        path: `/groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление нескольких групп по списку идентификаторов
     *
     * @tags Группы
     * @name GroupsDelete
     * @summary Удаление нескольких групп
     * @request DELETE:/groups
     * @secure
     */
    groupsDelete: (data: DeleteManyRequest, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/groups`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Получение детальной информации о группе
     *
     * @tags Группы
     * @name GroupsDetail
     * @summary Просмотр группы
     * @request GET:/groups/{id}
     * @secure
     */
    groupsDetail: (id: string, params: RequestParams = {}) =>
      this.request<GroupExtended, Error>({
        path: `/groups/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновление группы системы
     *
     * @tags Группы
     * @name GroupUpd
     * @summary Изменить группу
     * @request PUT:/groups/{id}
     * @secure
     */
    groupUpd: (id: string, data: GroupUpdateRequest, params: RequestParams = {}) =>
      this.request<GroupExtended, Error>({
        path: `/groups/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить группу из системы
     *
     * @tags Группы
     * @name GroupDel
     * @summary Удалить группу
     * @request DELETE:/groups/{id}
     * @secure
     */
    groupDel: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/groups/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список родительских групп
     *
     * @tags Группы
     * @name ParentsDetail
     * @summary Родительские группы
     * @request GET:/groups/{id}/parents
     * @secure
     */
    parentsDetail: (
      id: string,
      query?: {
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @default 10
         */
        per_page?: number;
        /**
         * Все предки группы(only_direct = 0) или только прямые предки(only_direct = 1)
         * @min 0
         * @max 1
         * @default 0
         */
        only_direct?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupParentList, Error>({
        path: `/groups/${id}/parents`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список дочерних групп
     *
     * @tags Группы
     * @name ChildrenDetail
     * @summary Дочерние группы
     * @request GET:/groups/{id}/children
     * @secure
     */
    childrenDetail: (
      id: string,
      query?: {
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @default 10
         */
        per_page?: number;
        /**
         * Все потомки группы (only_direct = 0) или только прямые потомки(only_direct = 1)
         * @min 0
         * @max 1
         * @default 1
         */
        only_direct?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupListWithRoles, Error>({
        path: `/groups/${id}/children`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список прямых пользователей группы
     *
     * @tags Группы
     * @name UsersDetail
     * @summary Пользователи группы
     * @request GET:/groups/{id}/users
     * @secure
     */
    usersDetail: (
      id: string,
      query?: {
        /** Поиск по ФИО и логину */
        search?: string;
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserList, Error>({
        path: `/groups/${id}/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Установить список пользователей группы, отсутствующие в списке будут удалены из группы
     *
     * @tags Группы
     * @name UsersCreate
     * @summary Синхронизация пользователей группы
     * @request POST:/groups/{id}/users
     * @secure
     */
    usersCreate: (id: string, data: GroupAddUsersRequest, params: RequestParams = {}) =>
      this.request<GroupAddUsersResponse, Error>({
        path: `/groups/${id}/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список доступов группы сгруппированный по категориям зон доступа
     *
     * @tags Группы
     * @name ZonesDetail
     * @summary Список доступов группы
     * @request GET:/groups/{id}/zones
     * @secure
     */
    zonesDetail: (id: string, params: RequestParams = {}) =>
      this.request<UserCategoryWithZonesList, Error>({
        path: `/groups/${id}/zones`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  me = {
    /**
     * @description Получить детальную ифнормацию по текущему пользователю
     *
     * @tags Пользователи
     * @name GetMe
     * @summary Текущий пользователь
     * @request GET:/me
     * @secure
     */
    getMe: (params: RequestParams = {}) =>
      this.request<User, Error>({
        path: `/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновление личной информации текущего пользователя
     *
     * @tags Пользователи
     * @name PutMe
     * @summary Обновление текущего пользователя
     * @request PUT:/me
     * @secure
     */
    putMe: (data: UpdateMeRequest, params: RequestParams = {}) =>
      this.request<User, Error>({
        path: `/me`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * @description Получить список пользователей с фильтрами и пагинацией
     *
     * @tags Пользователи
     * @name UsersList
     * @summary Список пользователей
     * @request GET:/users
     * @secure
     */
    usersList: (
      query?: {
        /** Поиск по логину или по ФИО */
        search?: string;
        /** Поиск по статусу пользователя */
        status?: "active" | "archive";
        /**
         * Поиск пользователей из Active Directory
         * @min 0
         * @max 1
         */
        in_ad?: number;
        /**
         * Список идентификаторов пользователя через запятую
         * @format uuid
         */
        roles?: string;
        /**
         * Поиск пользователей по вхождению в группу
         * @format uuid
         */
        groups?: string;
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserList, Error>({
        path: `/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Добавить нового пользователя в систему
     *
     * @tags Пользователи
     * @name AddUser
     * @summary Создать пользователя
     * @request POST:/users
     * @secure
     */
    addUser: (data: AddUserRequest, params: RequestParams = {}) =>
      this.request<UserWithRoles, Error>({
        path: `/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление пользователей по списку идентификаторов
     *
     * @tags Пользователи
     * @name UsersDelete
     * @summary Удалить пользователей
     * @request DELETE:/users
     * @secure
     */
    usersDelete: (data: DeleteManyRequest, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/users`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить детальную информацию о пользователе по его идентификатору
     *
     * @tags Пользователи
     * @name UsersDetail
     * @summary Информация о пользователе
     * @request GET:/users/{id}
     * @secure
     */
    usersDetail: (id: string, params: RequestParams = {}) =>
      this.request<UserWithRoles, Error>({
        path: `/users/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновление данных пользователя
     *
     * @tags Пользователи
     * @name UserUpd
     * @summary Обновление пользователя
     * @request PUT:/users/{id}
     * @secure
     */
    userUpd: (id: string, data: UpdateUserRequest, params: RequestParams = {}) =>
      this.request<UserWithRoles, Error>({
        path: `/users/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить пользователя
     *
     * @tags Пользователи
     * @name UserDel
     * @summary Удалить одного пользователя по идентификатору
     * @request DELETE:/users/{id}
     * @secure
     */
    userDel: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/users/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список ролей пользователя
     *
     * @tags Пользователи
     * @name RolesDetail
     * @summary Список ролей пользователя
     * @request GET:/users/{id}/roles
     * @secure
     */
    rolesDetail: (id: string, params: RequestParams = {}) =>
      this.request<RolesSimpleList, Error>({
        path: `/users/${id}/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Задание списка ролей для пользователя
     *
     * @tags Пользователи
     * @name UserRoles
     * @summary Установка ролей пользователю
     * @request POST:/users/{id}/roles
     * @secure
     */
    userRoles: (id: string, data: UserRolesRequest, params: RequestParams = {}) =>
      this.request<UserRoles, Error>({
        path: `/users/${id}/roles`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Получить список только прямых или всех групп пользователя
     *
     * @tags Пользователи
     * @name GroupsDetail
     * @summary Список групп пользователя
     * @request GET:/users/{id}/groups
     * @secure
     */
    groupsDetail: (
      id: string,
      query?: {
        /** Поиск по наименованию или коду группы */
        search?: string;
        /**
         * Выбрать только прямые или все группы вместе с косвенными
         * @min 0
         * @max 1
         */
        only_direct?: number;
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupList, Error>({
        path: `/users/${id}/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Назначение пользователю списка групп
     *
     * @tags Пользователи
     * @name UserGroups
     * @summary Установка групп пользователю
     * @request POST:/users/{id}/groups
     * @secure
     */
    userGroups: (id: string, data: UserGroupsRequest, params: RequestParams = {}) =>
      this.request<UserGroups, Error>({
        path: `/users/${id}/groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Список зон доступа пользователя сгруппированный по категориям
     *
     * @tags Пользователи
     * @name ZonesDetail
     * @summary Список зон доступа пользователя
     * @request GET:/users/{id}/zones
     * @secure
     */
    zonesDetail: (id: string, params: RequestParams = {}) =>
      this.request<UserCategoryWithZonesList, Error>({
        path: `/users/${id}/zones`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  settings = {
    /**
     * @description Получить список настроек сервиса
     *
     * @tags Настройки
     * @name SettingsList
     * @summary Список настроек сервиса
     * @request GET:/settings
     * @secure
     */
    settingsList: (params: RequestParams = {}) =>
      this.request<SettingsList, Error>({
        path: `/settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные нескольких настроек сервиса
     *
     * @tags Настройки
     * @name UpdateCreate
     * @summary Обновить список настроек
     * @request POST:/settings/update
     * @secure
     */
    updateCreate: (data: SettingMassUpdateRequest, params: RequestParams = {}) =>
      this.request<SettingsList, Error>({
        path: `/settings/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Детальный просмотр данных настройки
     *
     * @tags Настройки
     * @name SettingsDetail
     * @summary Просмотр настройки
     * @request GET:/settings/{id}
     * @secure
     */
    settingsDetail: (id: string, params: RequestParams = {}) =>
      this.request<Setting, Error>({
        path: `/settings/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить одну настроку по ее идентификатору
     *
     * @tags Настройки
     * @name SettingsUpdate
     * @summary Обновить настройку
     * @request PUT:/settings/{id}
     * @secure
     */
    settingsUpdate: (id: string, data: SettingUpdateRequest, params: RequestParams = {}) =>
      this.request<Setting, Error>({
        path: `/settings/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Сброс всех паролей пользователей, кроме пользователя текущей сессии и супер пользователя. Пароли заменяются на случайно сгенерированные
     *
     * @tags Настройки
     * @name ResetPasswordsCreate
     * @summary Сброс паролей пользователей
     * @request POST:/settings/reset-passwords
     * @secure
     */
    resetPasswordsCreate: (params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/settings/reset-passwords`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  zoneCategories = {
    /**
     * @description Получить список категорий зон доступа
     *
     * @tags Категории зон доступа
     * @name ZoneCategoriesList
     * @summary Список категорий
     * @request GET:/zone-categories
     * @secure
     */
    zoneCategoriesList: (
      query?: {
        /** Поиск по наименованию */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CategoryList, Error>({
        path: `/zone-categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создать категорию зон доступа
     *
     * @tags Категории зон доступа
     * @name ZoneCategoriesCreate
     * @summary Создать категорию
     * @request POST:/zone-categories
     * @secure
     */
    zoneCategoriesCreate: (data: CreateCategoryRequest, params: RequestParams = {}) =>
      this.request<CategoryWithZones, Error>({
        path: `/zone-categories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить несколько категорий зон доступа по списку идентификаторов, зоны доступа в категории так же удаляются
     *
     * @tags Категории зон доступа
     * @name ZoneCategoriesDelete
     * @summary Удаление нескольких категорий
     * @request DELETE:/zone-categories
     * @secure
     */
    zoneCategoriesDelete: (data: DeleteManyRequest, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/zone-categories`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Порядковым номером является место идентификатора в списке
     *
     * @tags Категории зон доступа
     * @name SortCreate
     * @summary Задать порядковые номера категорий
     * @request POST:/zone-categories/sort
     * @secure
     */
    sortCreate: (data: DeleteManyRequest, params: RequestParams = {}) =>
      this.request<CategoryList, Error>({
        path: `/zone-categories/sort`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Детальный просмотр информации о категории зон доступа по ее идентификатору
     *
     * @tags Категории зон доступа
     * @name ZoneCategoriesDetail
     * @summary Просмотр категории зон доступа
     * @request GET:/zone-categories/{id}
     * @secure
     */
    zoneCategoriesDetail: (id: string, params: RequestParams = {}) =>
      this.request<CategoryWithZones, Error>({
        path: `/zone-categories/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить категорию зон доступа по идентификатору
     *
     * @tags Категории зон доступа
     * @name ZoneCategoriesUpdate
     * @summary Обновить категорию
     * @request PUT:/zone-categories/{id}
     * @secure
     */
    zoneCategoriesUpdate: (id: string, data: UpdateCategoryRequest, params: RequestParams = {}) =>
      this.request<CategoryWithZones, Error>({
        path: `/zone-categories/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удалить категорию вместе с ее зонами, что приведет к обновлению ролей и отзыву выданных JWT
     *
     * @tags Категории зон доступа
     * @name ZoneCategoriesDelete2
     * @summary Удалить категорию
     * @request DELETE:/zone-categories/{id}
     * @originalName zoneCategoriesDelete
     * @duplicate
     * @secure
     */
    zoneCategoriesDelete2: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/zone-categories/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  zones = {
    /**
     * @description Получить полный список зон доступа разбитый по категориям
     *
     * @tags Зоны доступа
     * @name ZonesList
     * @summary Список зон доступа
     * @request GET:/zones
     * @secure
     */
    zonesList: (
      query?: {
        /** Поиск по наименованию категории или наименованию зоны или коду зоны */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CategoryWithZonesList, Error>({
        path: `/zones`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создание новой зоны доступа
     *
     * @tags Зоны доступа
     * @name ZonesCreate
     * @summary Создать зону доступа
     * @request POST:/zones
     * @secure
     */
    zonesCreate: (data: CreateZoneRequest, params: RequestParams = {}) =>
      this.request<AccessZone, Error>({
        path: `/zones`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление списка зон, которое приведет к обновлению ролей и сбросу JWT
     *
     * @tags Зоны доступа
     * @name ZonesDelete
     * @summary Удаление зон доступа
     * @request DELETE:/zones
     * @secure
     */
    zonesDelete: (data: DeleteManyRequest, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/zones`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Порядковый номер определяется положением в списке
     *
     * @tags Зоны доступа
     * @name SortCreate
     * @summary Обновление порядковых номеров зон в категории
     * @request POST:/zones/sort
     * @secure
     */
    sortCreate: (data: DeleteManyRequest, params: RequestParams = {}) =>
      this.request<AccessZoneList, Error>({
        path: `/zones/sort`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Просмотр детальной информации о зоне доступа по ее идентификатору
     *
     * @tags Зоны доступа
     * @name ZonesDetail
     * @summary Просмотр зоны доступа
     * @request GET:/zones/{id}
     * @secure
     */
    zonesDetail: (id: string, params: RequestParams = {}) =>
      this.request<AccessZone, Error>({
        path: `/zones/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Обновить данные зоны доступа
     *
     * @tags Зоны доступа
     * @name ZonesUpdate
     * @summary Обновление зоны доступа
     * @request PUT:/zones/{id}
     * @secure
     */
    zonesUpdate: (id: string, data: UpdateZoneRequest, params: RequestParams = {}) =>
      this.request<AccessZone, Error>({
        path: `/zones/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Удаление зоны так же обновляет роли и приводит к отзыву JWT
     *
     * @tags Зоны доступа
     * @name ZonesDelete2
     * @summary Удалить зону доступа
     * @request DELETE:/zones/{id}
     * @originalName zonesDelete
     * @duplicate
     * @secure
     */
    zonesDelete2: (id: string, params: RequestParams = {}) =>
      this.request<SimpleResponse, Error>({
        path: `/zones/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  helpers = {
    /**
     * @description Вспомогательный метод для пользователей
     *
     * @tags Helpers
     * @name UsersList
     * @summary Хелпер пользователей
     * @request GET:/helpers/users
     * @secure
     */
    usersList: (
      query?: {
        /** Поиск по ФИО или логину */
        search?: string;
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<HelperPageValueList, Error>({
        path: `/helpers/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Вспомогательный метод для списка ролей
     *
     * @tags Helpers
     * @name RolesList
     * @summary Хелпер для ролей
     * @request GET:/helpers/roles
     * @secure
     */
    rolesList: (
      query?: {
        /** Поиск по наименованию или коду зоны или уровню доступа в зоне */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<HelperValueList, Error>({
        path: `/helpers/roles`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Вспомогательный метод для получения списка групп
     *
     * @tags Helpers
     * @name GroupsList
     * @summary Хелпер для групп
     * @request GET:/helpers/groups
     * @secure
     */
    groupsList: (
      query?: {
        /** Поиск по наименованию или коду группы */
        search?: string;
        /**
         * Номер страницы
         * @default 1
         */
        page?: number;
        /**
         * Количество элементов на странице
         * @default 10
         */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<HelperPageValueList, Error>({
        path: `/helpers/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Вспомогательный метод для списка зон доступа
     *
     * @tags Helpers
     * @name ZonesList
     * @summary Хелпер для зон доступа
     * @request GET:/helpers/zones
     * @secure
     */
    zonesList: (
      query?: {
        /** Поиск по наименованию или коду зоны */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<HelperValueList, Error>({
        path: `/helpers/zones`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Вспомогательный метод для списка категорий зон доступа
     *
     * @tags Helpers
     * @name ZoneCategoriesList
     * @summary Хелпер для категорий зон доступа
     * @request GET:/helpers/zone-categories
     * @secure
     */
    zoneCategoriesList: (
      query?: {
        /** Поиск по наименованию категории зоны доступа */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<HelperValueList, Error>({
        path: `/helpers/zone-categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Вспомогательный метод для списка настроек системы. Отображаются только публичные настройки.
     *
     * @tags Helpers
     * @name SettingsList
     * @summary Хелпер для настроек
     * @request GET:/helpers/settings
     * @secure
     */
    settingsList: (params: RequestParams = {}) =>
      this.request<HelperSettingsValueList, any>({
        path: `/helpers/settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
