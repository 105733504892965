import React, { useState, useEffect } from "react";
import { Button, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "../../api_client";
import { useOpenNotifications } from "../../shared/useOpenNotifications";
import { HelperSettingsValue, HelperSettingsValueList } from "../../Api";

type FieldType = {
  username?: string;
  password?: string;
  password2?: string;
};

function RestoreScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();

  const hasToken = searchParams.get("token") != null;

  const [isSendedLogin, setSendedLogin] = React.useState("");
  const [codeError, setCodeError] = React.useState("");

  function handleAuthScreen(event: any) {
    navigate("/");
    event.preventDefault();
  }

  const onFinish = async (values: any) => {
    if (!hasToken) {
      try {
        const res = await api.forgotPassword
          .passReset({ login: form.getFieldValue("username") ?? "" })
          .then((res) => {
            console.log({ res });
            setCodeError("SUCCESS");
            setSendedLogin("Данные успешно отправлены");
          })
          .catch((err) => {
            console.log({ err });
            if (err?.error?.message) {
              setSendedLogin(err.error.message);
              setCodeError(err.error.code);
            } else {
              setSendedLogin(JSON.stringify(err));
            }
          });
      } catch (err: any) {
        console.log({ err });
      }
    } else {
      try {
        setSendedLogin("");
        setCodeError("");

        const res = await api.resetPassword
          .passUpd({
            token: searchParams.get("token") ?? "",
            password: values.password ?? "",
            password_confirm: values.password2 ?? "",
          })
          .then((res) => {
            console.log({ res });
            setCodeError("SUCCESS");
            setSendedLogin("Пароль успешно изменен");
          })
          .catch((err) => {
            if (err?.error?.message) {
              setSendedLogin(err.error.message);
              setCodeError(err.error.code);
            } else {
              setSendedLogin(JSON.stringify(err));
            }
          });
      } catch (e) {
        console.log({ e });
        // EMPTY
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [passDesc, setPassDesc] = useState<HelperSettingsValue>({});

  useEffect(() => {
    if (hasToken) {
      api.helpers.settingsList().then((e) => {
        setPassDesc(e.data?.find((e) => e.name == "PASSWORD_ERROR_CHECK") ?? {});
      });
    }
  }, []);

  return (
    <div className="restore_screen">
      <img src={"images/logo.svg"} className={"logo"} alt={"Logo"} />

      {!hasToken && (
        <>
          <div className="header">Восстановление пароля</div>
          {!isSendedLogin && (
            <p className="text">
              Укажите свой логин, инструкция будет отправлена <br /> на ваш
              E-mail/Telegram
            </p>
          )}
        </>
      )}
      {hasToken && (
        <>
          <div className="header">Создание нового пароля</div>
          {!isSendedLogin && (
            <p className="text">
              {!passDesc.name && <>
                Используйте только буквы латинского алфавита, цифры и символы: `!@#$%^&*()_=+[]{};:"\|,.
              </>}
              {passDesc && <>{passDesc.value}</>}
            </p>
          )}
        </>
      )}

      {isSendedLogin && (
        <div className={"auth_form"}>
          <p
            className="text"
            style={{ color: codeError != "SUCCESS" ? "red" : "green" }}
          >
            {isSendedLogin}
          </p>
          {codeError != "LOGIN_NOT_FOUND_EXCEPTION" &&
            codeError != "VALIDATION_EXCEPTION" && (
              <Button
                className={"btn_restore_pass"}
                type="primary"
                onClick={handleAuthScreen}
              >
                Назад к авторизации
              </Button>
            )}
        </div>
      )}

      {(!isSendedLogin ||
        codeError == "LOGIN_NOT_FOUND_EXCEPTION" ||
        codeError == "VALIDATION_EXCEPTION") && (
        <Form
          form={form}
          className={"auth_form"}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {!hasToken && (
            <Form.Item<FieldType>
              name="username"
              rules={[{ required: true, message: "Введите логин" }]}
            >
              <Input placeholder={"Логин"} prefix={<UserOutlined />} />
            </Form.Item>
          )}

          {hasToken && (
            <>
              <Form.Item<FieldType>
                name="password"
                rules={[{ required: true, message: "Введите пароль" }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder={"Придумайте пароль"}
                />
              </Form.Item>

              <Form.Item<FieldType>
                name="password2"
                rules={[
                  { required: true, message: "Введите пароль" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Пароли не совпадают!"));
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder={"Повторите, чтобы не ошибиться"}
                />
              </Form.Item>
            </>
          )}

          <Form.Item>
            {!hasToken && (
              <Button className={"btn_submit"} type="primary" htmlType="submit">
                Отправить
              </Button>
            )}
            {hasToken && (
              <Button className={"btn_submit"} type="primary" htmlType="submit">
                Подтвердить
              </Button>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              className={"btn_restore_pass"}
              type="text"
              onClick={handleAuthScreen}
            >
              Назад к авторизации
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

export default RestoreScreen;
