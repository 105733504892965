/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface MetaData {
  /** @example 1 */
  current_page?: number;
  /** @example 10 */
  per_page?: number;
  /** @example 20 */
  total?: number;
  /** @example 2 */
  last_page?: number;
}

export interface SimpleResponse {
  /** @example true */
  success?: boolean;
}

export interface Error {
  message?: string;
  code?: string;
}

export interface HistoryItem {
  /**
   * Unix timestamp (время изменения)
   * @format float
   */
  timestamp?: number;
  /**
   * ID пользователя внесшего изменения
   * @format uuid
   */
  user_id?: string;
  /**
   * Код системы откуда пришли изменения
   * @example "rbac"
   */
  system_id?: string;
  /**
   * Код объекта
   * @example "User"
   */
  object_type?: string;
  /**
   * Идентификатор объекта
   * @format uuid
   */
  object_id?: string;
  operation?: number;
  changes?: {
    /** Пользователь внесший изменения */
    user?: {
      /**
       * Логин пользователя
       * @example "i.ivanov"
       */
      login?: string;
      /**
       * ФИО пользователя
       * @example "Иванов Иван Иванович"
       */
      username?: string;
      avatar_url?: string;
    };
    /** Изменяемый объект */
    object?: {
      /**
       * Код типа объекта
       * @example "User"
       */
      type?: string;
      /**
       * Представление объекта в строковом виде
       * @example "Иванов Иван Иванович"
       */
      repr?: string;
    };
    /** Изменения объекта, где ключи это названия полей, а значение - это массив старого и нового значения */
    changes?: object;
  };
}

export interface HistoryList {
  data?: HistoryItem[];
  meta?: MetaData;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://develop.pnk.itmegastar.com/history/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title PNK History Service
 * @version 1.0.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 * @termsOfService http://swagger.io/terms/
 * @baseUrl https://develop.pnk.itmegastar.com/history/api
 * @externalDocs https://www.notion.so/megastar/v2-3388738db3264bd7b57b4cfe343e1690
 * @contact <d.kornilov@itmegabox.com>
 *
 * Сервис истории изменений проекта PNK
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  changes = {
    /**
     * @description Список истории изменений объектов с фильтрацией
     *
     * @tags History
     * @name ChangesList
     * @summary Список истории изменений
     * @request GET:/changes
     * @secure
     */
    changesList: (
      query?: {
        /** Список идентификаторов объектов через запятую */
        object_ids?: string[];
        /** Список идентификаторов пользователей через запятую */
        user_ids?: string[];
        /** Список кодов операций: 0 - добавление, 1 - изменение, 2 - удаление */
        operations?: (0 | 1 | 2)[];
        /** Имена полей объектов (используется только вместе с фильтром object_ids) */
        changed_fields?: string[];
        /**
         * Начало периода изменений
         * @format date-time
         */
        beg_timestamp?: string;
        /**
         * Конец запрашиваемого периода
         * @format date-time
         */
        end_timestamp?: string;
        /**
         * Код системы откуда пришли изменения
         * @example "rbac"
         */
        system_id?: string;
        /**
         * Тип объекта изменений
         * @example "User"
         */
        object_type?: "User" | "Role" | "Group" | "AccessZone" | "AccessZoneCategory" | "Setting";
        /** @default 1 */
        page?: number;
        /** @default 10 */
        page_size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<HistoryList, Error>({
        path: `/changes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
