import { FormInstance } from "antd";

export function clearDisabledValue(
  name: string,
  form: FormInstance,
  id?: string
) {
  if ((form.getFieldsValue()[name] ?? []).includes(id)) {
    form.setFieldValue(
      name,
      (form.getFieldsValue()[name] ?? []).filter((e: any) => e != id)
    );
  }
}
