import React, {FunctionComponent, useEffect, useState} from "react";
import Layout from "../../shared/Layout";
import {useParams} from "react-router-dom";
import {getEmptyRole, mockRoles} from "../../shared/mock";
import RoleDataForm from "./components/RoleDataForm";
import {Tabs} from "antd";
import {useAppTab} from "../../shared/useAppTab";
import RoleUsersList from "./components/RoleUsersList";
import RoleGroupsList from "./components/RoleGroupsList";
import ViewHeader from "../../shared/ViewHeader";
import {useRoleView} from "../../actions/useRoleView";
import { accessKeys } from "../../shared/parseJwt";

const RoleView: FunctionComponent<{}> = (props) => {
    let {id} = useParams();
    const {tab, setTab} = useAppTab()
    const {role, roleLoading, roleError} = useRoleView(id ?? '')

    return <>
        <Layout wrapContent={true} className={'role_view'} title={role?.title ?? 'Просмотр роли'}
                back={{title: 'Роли', link: '/roles', titleBack: 'Роли'}}
                loading={roleLoading}
                error={roleError as any}
        >
            <ViewHeader
                group={role} linkEdit={`/roles/${id}/edit`}
                desc={role?.description ?? ''}
                accessKey={accessKeys.roles}
                labelActive="Активна"
            />

            {role?.in_active_directory && <span className="ldap_desc">Группа из LDAP-каталога: {role?.ad_group}</span>}

            <Tabs
                key={tab == '' ? '' : 'tab'}
                defaultActiveKey={tab}
                items={[
                    {key: 'main', label: 'Доступы',},
                    {key: 'users', label: 'Пользователи',},
                    {key: 'groups', label: 'Группы',},
                ]}
                onChange={setTab}
            />

            {tab == 'main' && <>
                <RoleDataForm id={id ?? ''}/>
            </>}

            {tab == 'users' && <>
                <RoleUsersList id={id ?? ''} />
            </>}

            {tab == 'groups' && <>
                <RoleGroupsList id={id ?? ''} />
            </>}

        </Layout>
    </>
}

export default RoleView;
