import { useLocation } from "react-router-dom";

export function historyMapKey(
  type:
    | "User"
    | "Role"
    | "Group"
    | "AccessZone"
    | "AccessZoneCategory"
    | "Setting",
  key: string
): string {
  switch (type) {
    case "User":
      switch (key) {
        case "full_name":
          return "ФИО";
        case "avatar_url":
          return "Аватарка";
      }
      break;
    case "Role":
      switch (key) {
        case "name":
          return "Код";
        case "title":
          return "Наименование";
        case "children":
          return "Дочерние группы";
        case "permissions":
          return "Доступы";
      }
      break;
    case "Group":
      switch (key) {
        case "name":
          return "Код";
        case "title":
          return "Наименование";
        case "children":
          return "Дочерние группы";
      }
      break;
    case "AccessZone":
      switch (key) {
        case "name":
          return "Код";
        case "title":
          return "Наименование";
      }
      break;
    case "AccessZoneCategory":
      switch (key) {
        case "name":
          return "Код";
        case "title":
          return "Наименование";
      }
      break;
    case "Setting":
      if (key == "action") return "Аварийный режим";

      break;
  }

  switch (key) {
    case "name":
      return "Наименование";
    case "email":
      return "Email";
    case "login":
      return "Логин";
    case "status":
      return "Статус";
    case "created_at":
      return "Создан";
    case "updated_at":
      return "Изменен";
    case "telegram_id":
      return "Telegram ID";
    case "is_superuser":
      return "Суперюзер";
    case "title":
      return "Заголовок";
    case "roles":
      return "Роли";
    case "order":
      return "Порядок";
    case "description":
      return "Описание";
    case "permissions":
      return "Права";
    case "levels":
      return "Уровни доступа";
    case "category_id":
      return "ID категории";
    case "password":
      return "Пароль";
    case "value":
      return "Значение";
    case "groups":
      return "Группы";
    case "password_rules":
      return "Проверка";
    case "password_error_check":
      return "Описание требований к паролю";
    case "access_token_lifetime":
      return "Access token";
    case "refresh_token_lifetime":
      return "Refresh token";
    case "users":
      return "Пользователи";
    case "zones":
      return "Зоны доступа";
    case "in_active_directory":
      return "Есть в AD";
    case "ad_host":
      return "Путь до LDAP-сервера";
    case "ad_root":
      return "Домен LDAP-каталога";
    case "ldap_login":
      return "LDAP Пользователь";
    case "ad_users_dn":
      return "Путь до пользователей";
    case "ad_groups_dn":
      return "Путь до групп";
    case "ldap_password":
      return "LDAP Пароль";
  }

  return key;
}

export function localeEntity(
  entity:
    | "User"
    | "Role"
    | "Group"
    | "AccessZone"
    | "AccessZoneCategory"
    | "Setting"
) {
  switch (entity) {
    case "User":
      return "Пользователь";
    case "Role":
      return "Роль";
    case "Group":
      return "Группа";
    case "AccessZone":
      return "Уровни доступа";
    case "AccessZoneCategory":
      return "Доступы";
    case "Setting":
      return "Настройки";
  }
}

export function useNameEntity(): {
  entity:
    | "User"
    | "Role"
    | "Group"
    | "AccessZone"
    | "AccessZoneCategory"
    | "Setting"
    | "";
  id?: string;
  categoryId?: string;
  type: "list" | "view" | "edit";
} {
  const location = useLocation();

  const paths = location.pathname.replace("/", "").split("/");

  if (paths.length == 1) {
    switch (paths[0]) {
      case "users":
        return { entity: "User", type: "list" };
      case "roles":
        return { entity: "Role", type: "list" };
      case "groups":
        return { entity: "Group", type: "list" };
      case "categories":
        return { entity: "AccessZoneCategory", type: "list" };
      case "settings":
        return { entity: "Setting", type: "list" };
    }
  }

  if (paths.length == 2) {
    switch (paths[0]) {
      case "users":
        return { entity: "User", type: "view", id: paths[1] };
      case "roles":
        return { entity: "Role", type: "view", id: paths[1] };
      case "groups":
        return { entity: "Group", type: "view", id: paths[1] };
      case "categories":
        return { entity: "AccessZoneCategory", type: "view", id: paths[1] };
      case "settings":
        return { entity: "Setting", type: "view", id: paths[1] };
    }
  }

  if (paths.length == 3 && paths[2] == "edit") {
    switch (paths[0]) {
      case "users":
        return { entity: "User", type: "edit", id: paths[1] };
      case "roles":
        return { entity: "Role", type: "edit", id: paths[1] };
      case "groups":
        return { entity: "Group", type: "edit", id: paths[1] };
      case "categories":
        return { entity: "AccessZoneCategory", type: "edit", id: paths[1] };
      case "settings":
        return { entity: "Setting", type: "edit", id: paths[1] };
    }
  }

  if (
    paths.length == 4 &&
    paths[0] == "categories" &&
    paths[2] == "access_zone"
  ) {
    return {
      entity: "AccessZone",
      type: "view",
      id: paths[3],
      categoryId: paths[1],
    };
  }
  if (
    paths.length == 5 &&
    paths[0] == "categories" &&
    paths[2] == "access_zone" &&
    paths[4] == "edit"
  ) {
    return {
      entity: "AccessZone",
      type: "edit",
      id: paths[3],
      categoryId: paths[1],
    };
  }

  return {
    entity: "",
    type: "view",
  };
}

export function getLink(
  entity:
    | "User"
    | "Role"
    | "Group"
    | "AccessZone"
    | "AccessZoneCategory"
    | "Setting",
  id: string,
  categoryId?: string
) {
  if (!id) {
    switch (entity) {
      case "User":
        return `/users`;
      case "Role":
        return `/roles`;
      case "Group":
        return `/groups`;
      case "AccessZone":
        return `/categories/${categoryId ?? "-"}/access_zones`;
      case "AccessZoneCategory":
        return `/categories`;
      case "Setting":
        return `/settings`;
    }
  }
  switch (entity) {
    case "User":
      return `/users/${id}`;
    case "Role":
      return `/roles/${id}`;
    case "Group":
      return `/groups/${id}`;
    case "AccessZone":
      return `/categories/${categoryId ?? "-"}/access_zones/${id}`;
    case "AccessZoneCategory":
      return `/categories/${id}`;
    case "Setting":
      return `/settings`;
  }

  return "";
}
