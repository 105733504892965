import React, { FunctionComponent } from "react";
import { Spin } from "antd";

const AppLayoutContent: FunctionComponent<{
  children?: any;
  bgOpacity?: boolean;
  left?: any;
  right?: any;
  loading?: boolean;
  error?: { error?: { code?: string; message?: string } };
}> = (props) => {
  return (
    <div className={`app-content ${props.bgOpacity ? "bg_opacity" : ""}`}>
      {props.error && (
        <>
          <h3>Проблема</h3>
          {props.error?.error?.message ?? "Ошибка запроса"}
        </>
      )}
      {props.loading && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "50vh",
            }}
          >
            <Spin size="large"></Spin>
          </div>
        </>
      )}
      {!props.loading && !props.error && (
        <>
          {props.left !== undefined && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gridGap: "60px",
                }}
              >
                <div>{props.left}</div>
                <div style={{ marginLeft: "auto" }}>{props.right}</div>
              </div>
            </>
          )}
          {props.children}
        </>
      )}
    </div>
  );
};

export default AppLayoutContent;
