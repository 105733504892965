import Cookies from "js-cookie";

export default function parseJwt(token: string) {
  var base64Url = token.split(".")[1] ?? "";
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload || "[]");
}

export function getAccess(token: string) {
  const tokenPayload = parseJwt(token);

  const access: { [x: string]: string } = {};

  (tokenPayload?.permissions ?? []).forEach((permission: string) => {
    access[permission.split(":")[0]] = permission.split(":")[2];
  });

  return access;
}

export function checkAccess(key: string, value: string[]) {
  let access: { [x: string]: string } = {};
  if (!(window as any).access) {
    (window as any).access = getAccess(Cookies.get("token") ?? "");
  }
  access = (window as any).access;


  return value.includes(access[key]);
}

export const accessKeys = {
  users: "UR_US",
  groups: "UR_GR",
  roles: "UR_RL",
  access: "UR_AZ",
  settings: "UR_ST",
};
