import { FunctionComponent } from "react";
import { HistoryItem } from "../../../HistoryApi";
import { historyMapKey } from "../historyMapKey";
import { historyMapValue } from "../historyMapValue";

const HistoryItemDescLine: FunctionComponent<{
  keyValue: string;
  record: HistoryItem;
  change: any[];
}> = ({ keyValue, record, change }) => {
  return (
    <>
      <div className={"history_record"}>
        <span className={"history_key"}>
          {historyMapKey(record.changes?.object?.type as any, keyValue)}:{" "}
        </span>
        {record.operation != 0 && (
          <>
            <div
              className={
                record.operation == 2  || record.operation == 3 ? "object_action deleted" : "history_old"
              }
            >
              {historyMapValue(
                record.changes?.object?.type as any,
                keyValue,
                change[0]
              )}
            </div>
          </>
        )}
        {record.operation != 2 && (
          <>
            {record.operation == 1 && <>&nbsp;→&nbsp;</>}
            <div className={"history_new"}>
              {historyMapValue(
                record.changes?.object?.type as any,
                keyValue,
                change[1],
                true
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HistoryItemDescLine;
