import AppTableHeader from "./AppTableHeader";
import { Button, Input, Modal, Pagination, Table } from "antd";
import showDeleteConfirm from "./showDeleteConfirm";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { Key, ReactElement, useEffect, useState } from "react";
import { TableRowSelection } from "antd/es/table/interface";
import { ColumnsType } from "antd/lib/table";
import AppIcon from "./Icons/AppIcon";
import { checkAccess } from "./parseJwt";
import { SearchOutlined } from "@ant-design/icons";

function AppTable<T>(props: {
  title?: string;
  className?: string;
  columns: ColumnsType<T>;
  items: any[];
  filter?: {
    onDataSource: (items: T[], search: string) => T[];
    placeholder?: string;
    componentRight?: ReactElement;
  };
  accessCreate?: {
    key: string;
    values: string[];
  };
  onCreate?: () => void;
  onRowClick?: (item: T) => void;
  canManyActions?: boolean;
  onManyDelete?: (ids: string[]) => void;
  children?: any;
  pagination?: {
    total: number;
    current: number;
    onSelect: (page: number, perPage: number) => void;
    perPage: number;
    showSizeChanger?: boolean;
  };
  filtersModal?: {
    content: any;
    onOk: (filters: any) => void;
    onCancel: () => void;
    onClear: () => void;
    count: number;
  };
  loading?: boolean;
  expandRowByClick?: boolean;
  expandedRowKeys?: string[];
}) {
  const [allowManyActions, setAllowManyActions] = useState(false);
  const [dataSource, setDataSource] = useState(props.items);
  const [selected, setSelected] = useState<Key[]>([
    "78b26377-df42-4743-acdf-42586600ac83",
  ]);
  const [search, setSearch] = useState("");
  const [isOpenFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    setDataSource(props.items);
  }, [props.items]);

  useEffect(() => {
    setSelected([]);
  }, [allowManyActions]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const rowSelection: TableRowSelection<T> = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelected(selectedRowKeys as string[]);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  return (
    <>
      <AppTableHeader
        key={"AppTableHeader"}
        left={[
          props.title ? (
            <h3 key={`apptable_title_${props.title}`}>{props.title}</h3>
          ) : null,
        ].filter((e) => !!e)}
        right={[
          props.filter?.componentRight ? (
            <span
              key="props.filter?.componentRight"
              style={{ display: "flex", alignItems: "center" }}
            >
              {props.filter?.componentRight}
            </span>
          ) : null,
          props.filtersModal ? (
            <AppIcon
              active={false}
              key={"allowManyA_btn5"}
              count={props.filtersModal?.count}
              path={"/layout/filter"}
              onClick={() => setOpenFilter(!isOpenFilter)}
            />
          ) : null,
          ...(props.canManyActions
            ? [
                allowManyActions ? (
                  <AppIcon
                  key={"allowManyA41"}
                  disabled={!selected.length}
                  active={false}
                  path={"/layout/delete"}
                  onClick={() =>
                    showDeleteConfirm(() => {
                      setSelected([]);
                      if (props.onManyDelete)
                        props.onManyDelete!(selected as string[]);
                    })
                  }
                />
            ) : undefined,
                <AppIcon
                  key={"allowManyA_btn4"}
                  active={allowManyActions}
                  path={"/layout/many_actions"}
                  onClick={() => setAllowManyActions(!allowManyActions)}
                />,
              ].filter((e) => !!e)
            : []),
          props.filter?.onDataSource ? (
            <Input
              key={"AppTableSearchInput311"}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              suffix={<SearchOutlined />}
              placeholder={
                props.filter?.placeholder ??
                "Поиск по подстроке в имени или логине"
              }
              style={{ width: "30vw", minWidth: "300px" }}
            />
          ) : null,
          props.onCreate &&
            props.accessCreate &&
            checkAccess(props.accessCreate.key, props.accessCreate.values) && (
              <Button
                type={"primary"}
                key={"allowManyA_btn16"}
                onClick={props.onCreate}
              >
                Добавить
              </Button>
            ),
        ].filter((e) => !!e)}
      />
      {props.children ?? (
        <>
          <Table
            className={props.className}
            loading={props.loading}
            columns={props.columns as any}
            rowSelection={
              allowManyActions
                ? ({ ...rowSelection, checkStrictly: false } as any)
                : undefined
            }
            dataSource={
              props.filter?.onDataSource
                ? props.filter?.onDataSource(dataSource, search)
                : dataSource
            }
            pagination={false}
            locale={{ filterReset: "Сброс" }}
            onRow={(record, rowIndex) => ({
              onClick: (event) =>
                props.onRowClick ? props.onRowClick(record) : null,
            })}
            expandable={{
              expandRowByClick: props.expandRowByClick,
              expandedRowKeys: props.expandedRowKeys,
            }}
          />
          {props.pagination && (
            <>
              <br />
              <Pagination
                key={`paginate_${props.pagination?.current}_${props.items.length}`}
                defaultPageSize={15}
                pageSizeOptions={[15, 20, 30, 50, 100]}
                defaultCurrent={props.pagination?.current}
                total={props.pagination?.total}
                pageSize={props.pagination?.perPage}
                onChange={(page, perPage) =>
                  props.pagination?.onSelect(page, perPage)
                }
                showSizeChanger={props.pagination.showSizeChanger ?? false}
                hideOnSinglePage={false}
                locale={{
                  items_per_page: " на странице",
                }}
              />
            </>
          )}
        </>
      )}
      <Modal
        title="Фильтр"
        open={isOpenFilter}
        onCancel={() => {
          setOpenFilter(false);
          props.filtersModal!.onCancel();
        }}
        footer={
          <div style={{ display: "flex" }}>
            <Button
              type={"link"}
              style={{ marginLeft: "auto" }}
              onClick={() => {
                setOpenFilter(false);
                props.filtersModal!.onClear();
              }}
            >
              Сбросить
            </Button>

            <Button
              type="primary"
              onClick={() => {
                setOpenFilter(false);
                props.filtersModal!.onOk({});
              }}
            >
              Применить
            </Button>
          </div>
        }
      >
        {props.filtersModal?.content}
      </Modal>
    </>
  );
}

export default AppTable;
