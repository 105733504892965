import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "../api_client";
import { CreateZoneRequest } from "../Api";
import getEmptyAccessZone from "../emptyModels/getEmptyAccessZone";
import { useOpenNotifications } from "../shared/useOpenNotifications";

export default function ({
  id,
  categoryId,
  pathFrom,
}: {
  id?: string;
  categoryId?: string;
  pathFrom?: string;
}) {
  const { data, error, isLoading } = useQuery({
    queryKey: [`zones`],
    queryFn: () =>
      api.zones.zonesList().then(
        (e) =>
          e.data?.map((e) =>
            e.zones?.length
              ? {
                  ...e,
                  children: e.zones?.map((e) => ({ ...e, key: e.id })),
                  key: e.id,
                }
              : { ...e, key: e.id }
          )
      ),
  });
  const {
    data: item,
    error: itemError,
    isLoading: itemLoading,
  } = id
    ? useQuery({
        queryKey: [`zones_${id}`],
        queryFn: () =>
          api.zones
            .zonesDetail(id)
            .then((e) => ({
              ...e.data,
              levels: e.data?.levels?.map((e2) => ({
                ...e2,
                key: Math.random(),
              })),
            })),
      })
    : {
        data: undefined,
        error: undefined,
        isLoading: false,
      };
  const { apiNotification } = useOpenNotifications();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  function resetCache(id?: string) {
    queryClient.invalidateQueries({ queryKey: ["zones"] });
    queryClient.invalidateQueries({ queryKey: ["zone_categories"] });
    if (id) {
      queryClient.invalidateQueries({ queryKey: [`zones_${id}`] });
    }
    queryClient.invalidateQueries({
      queryKey: [`zone_categories_${categoryId}`],
    });
  }

  const mutationUpdateZone = useMutation({
    mutationFn: (params: {
      id: string;
      payload: CreateZoneRequest;
      isNew: boolean;
      category_id: string;
    }) => {
      if (params.isNew) {
        // delete params.payload.status;
        return api.zones.zonesCreate(params.payload);
      }

      return api.zones.zonesUpdate(params.id, params.payload);
    },
    onSuccess: (result, params) => {
      if (!pathFrom) {
        if (params.isNew) {
          navigate(
            `/categories/${params.category_id}/access_zone/${result.data.id}`
          );
        } else {
          navigate(`/categories/${params.category_id}/access_zone/${id}`);
        }
      } else {
        navigate(pathFrom);
      }
      setTimeout(() => {
        apiNotification.info({
          message: `Сохранение`,
          description: <>Данные сохранены</>,
        });
      }, 1000);
      resetCache(params.id);
    },
    onError: (err: any, params) => {
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось обновить</>,
      });
      resetCache(params.id);
    },
  });

  const mutationDeleteZone = useMutation({
    mutationFn: (ids: string[]) => {
      return api.zones.zonesDelete({ ids: ids });
    },
    onSuccess: (result, ids) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      apiNotification.info({
        message: `Удаление`,
        description: <>Зоны удалены</>,
      });
      ids.forEach((id) => resetCache(id));
    },
    onError: (err: any, ids) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось удалить</>,
      });
      ids.forEach((id) => resetCache(id));
    },
  });

  const mutationSortZone = useMutation({
    mutationFn: (ids: string[]) => {
      return api.zones.sortCreate({ ids: ids });
    },
    onSuccess: (result, ids) => {
      apiNotification.info({
        message: `Сортировка`,
        description: <>Порядок обновлен</>,
      });
      resetCache(id);
    },
    onError: (err: any, ids) => {
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось обновить</>,
      });
      resetCache(id);
    },
  });

  return {
    zones: data ?? [],
    zonesLoading:
      isLoading ||
      mutationUpdateZone.isLoading ||
      mutationDeleteZone.isLoading ||
      mutationDeleteZone.isLoading,
    zonesError: error,

    zone: item ?? { category_id: categoryId, ...getEmptyAccessZone() },
    zoneLoading:
      itemLoading ||
      mutationUpdateZone.isLoading ||
      mutationDeleteZone.isLoading ||
      mutationDeleteZone.isLoading,
    zoneError: itemError,

    updateZone: mutationUpdateZone,
    deleteZone: mutationDeleteZone,
    sortZones: mutationSortZone,
  };
}
