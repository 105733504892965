import { FunctionComponent } from "react";
import { Form, Input, Radio, Select } from "antd";
import { GroupExtended } from "../../../Api";
import useHelpers from "../../../actions/useHelpers";
import useGroupsAction from "../../../actions/useGroupsAction";
import FormHeader from "../../../shared/FormHeader";
import { FormInstance } from "antd/lib";
import { clearDisabledValue } from "../../../shared/clearFormTag";

const GroupForm: FunctionComponent<{
  group: GroupExtended;
  onDelete: () => void;
  onBack: () => void;
  isNew: boolean;
}> = (props) => {
  const { rolesHelper, usersHelper, loading, groupsHelper } = useHelpers();

  const [form] = Form.useForm();
  const { updateGroup, deleteGroup, groupLoading } = useGroupsAction({
    id: props.group.id,
  });

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  async function handleUpdate() {
    await form.validateFields();
    updateGroup.mutate({
      id: props.group.id ?? "",
      payload: {
        roles: form.getFieldsValue().roleIds ?? [],
        title: form.getFieldsValue().title,
        name: form.getFieldsValue().name,
        status: form.getFieldsValue().status,
        children: form.getFieldsValue().groupIds ?? [],
        users: form.getFieldsValue().userIds ?? [],
      },
      isNew: props.isNew,
      listParams: {},
    });
  }

  return (
    <div>
      <FormHeader
        title={props.isNew ? "Создание группы" : "Изменение группы"}
        onSubmit={handleUpdate}
        onCancel={() => props.onBack()}
      />
      <Form
        layout="vertical"
        form={form}
        name="basic"
        initialValues={{
          ...props.group,
          roleIds: props.group.roles?.map((e) => e.id) || [],
          userIds:
            props.group.users?.map((e) => e.id)?.filter((e) => !!e) || [],
          groupIds: props.group.children?.map((e) => e.id) || [],
        }}
        onFinish={handleUpdate}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        disabled={groupLoading || loading}
      >
        <div
          className={"form_row_inline"}
          style={{ gridTemplateColumns: "1fr 200px auto" }}
        >
          <Form.Item<GroupExtended>
            label="Наименование"
            name="title"
            rules={[
              { required: true, message: "Поле обязательно для заполнения" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item<GroupExtended>
            label="Код"
            name="name"
            rules={[
              { required: true, message: "Поле обязательно для заполнения" },
            ]}
          >
            <Input />
          </Form.Item>

          {!props.isNew && (
            <Form.Item<GroupExtended> label="Статус" name="status">
              <Radio.Group>
                <Radio.Button value="active">Активная</Radio.Button>
                <Radio.Button value="archive">В архиве</Radio.Button>
              </Radio.Group>
            </Form.Item>
          )}
        </div>
        <Form.Item<{ roleIds: string[] }> label="Роли" name="roleIds">
          <Select
            mode="multiple"
            filterOption={function (inputValue, option) {
              if (
                `${option?.label} ${option?.value}`
                  ?.toString()
                  ?.toLowerCase()
                  .includes(inputValue.toLowerCase())
              ) {
                return true;
              }
              return false;
            }}
          >
            {(rolesHelper?.data ?? []).map((item) => (
              <Select.Option key={item.value} value={item.value} disabled={item.disabled} label={item.text}>
                <span onClick={() => clearDisabledValue('roleIds', form, item.value)}>{item.text}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item<{ groupIds: string[] }>
          label="Дочерние группы"
          name="groupIds"
        >
          <Select
            filterOption={function (inputValue, option) {
              if (
                `${option?.label} ${option?.value}`
                  ?.toString()
                  ?.toLowerCase()
                  .includes(inputValue.toLowerCase())
              ) {
                return true;
              }
              return false;
            }}
            mode="multiple"
          >
            {(groupsHelper?.data ?? []).map((item) => (
              <Select.Option key={item.value} value={item.value} disabled={item.disabled} label={item.text}>
                <span onClick={() => clearDisabledValue('groupIds', form, item.value)}>{item.text}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item<{ userIds: string[] }> label="Пользователи" name="userIds">
          <Select
            mode="multiple"
            filterOption={function (inputValue, option) {
              console.log(option);
              
              if (
                `${option?.label} ${option?.value}`
                  ?.toString()
                  ?.toLowerCase()
                  .includes(inputValue.toLowerCase())
              ) {
                return true;
              }
              return false;
            }}
          >
            {(usersHelper?.data ?? []).map((users) => (
              <Select.Option key={users.value} value={users.value} disabled={users.disabled} label={users.text}>
                <span onClick={() => clearDisabledValue('userIds', form, users.value)}>{users.text}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <div style={{ display: "flex" }}>
            {/*<Button type="primary" htmlType="submit" onClick={() => {*/}
            {/*    handleUpdate()*/}
            {/*}}>*/}
            {/*    Сохранить*/}
            {/*</Button>*/}

            {/*{!props.isNew && <Button*/}
            {/*    type="default" style={{marginLeft: 'auto'}}*/}
            {/*    onClick={() => showDeleteConfirm(() => deleteGroup.mutate([props.group.id ?? '']))}*/}
            {/*>*/}
            {/*    Удалить*/}
            {/*</Button>}*/}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GroupForm;
