import React, { FunctionComponent, useState } from "react";
import AppTable from "../../../shared/AppTable";
import { ColumnsType } from "antd/lib/table";
import IconView from "../../../shared/IconView";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { User } from "../../../Api";
import { api } from "../../../api_client";
import { GroupsActionsListParams } from "../../../actions/useGroupsAction";

const RoleUsersList: FunctionComponent<{ id: string }> = (props) => {
  const navigate = useNavigate();
  const [params, setParams] = useState<GroupsActionsListParams>({ page: 1, per_page: 15 });

  const { data, error, isLoading } = useQuery({
    queryKey: [`roles_${props.id}_users_${JSON.stringify(params)}`],
    queryFn: () =>
      props.id
        ? api.roles.usersDetail(props.id, params).then((e) => e.data)
        : Promise.resolve({
            data: [],
          }),
  });

  const columns: ColumnsType<User> = [
    {
      title: "ФИО",
      dataIndex: "full_name",
      key: "full_name",
      className: "column_title",
      render: (text, record) => (
        <NavLink to={`/users/${record.id}`}>{text}</NavLink>
      ),
    },
    {
      title: "Логин",
      dataIndex: "login",
      key: "login",
      width: "200px",
    },
  ];

  function filterDataSource(items: User[], search: string) {
    if (search != params.search) {
      setParams({...params, search: search, page: 1})
    }

    return items
  }

  return (
    <div style={{ marginTop: "-55px" }}>
      <AppTable
        pagination={{
          perPage: params?.per_page ?? 15,
          current: data?.meta?.current_page ?? 1,
          total: data?.meta?.total ?? 0,
          showSizeChanger: true,
          onSelect: (page, perPage) =>
            setParams({ ...params, page: page, per_page: perPage }),
        }}
        loading={isLoading}
        filter={{
          onDataSource: filterDataSource,
          placeholder: "Поиск по ФИО/логину",
        }}
        onRowClick={(e) => navigate(`/users/${e.id}`)}
        columns={columns}
        items={data?.data ?? []}
      />
    </div>
  );
};

export default RoleUsersList;
