import React, { FunctionComponent } from "react";
import Layout from "../../shared/Layout";
import { useNavigate, useParams } from "react-router-dom";
import UserForm from "./components/UserForm";
import { useQuery } from "react-query";
import { api } from "../../api_client";

const UserView: FunctionComponent<{ isAdd?: boolean }> = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { data, error, isLoading } = useQuery({
    queryKey: [`user_${id}`],
    queryFn: () => api.users.usersDetail(id ?? "").then((e) => e.data),
  });

  return (
    <Layout
      className={"user_edit"}
      title={
        props.isAdd ? "Новый пользователь" : data?.full_name ?? "Пользователь"
      }
      back={{
        title: "Пользователи",
        link: "/users",
        titleBack: "Пользователи",
      }}
      loading={isLoading}
      error={error as any}
    >
      {data && (
        <UserForm
          isNew={props.isAdd ?? false}
          user={data}
          onDelete={() => {
            navigate("/users");
          }}
        />
      )}
    </Layout>
  );
};

export default UserView;
