import { ExclamationCircleFilled } from "@ant-design/icons";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button, Form, Input, Modal, Select, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ColumnsType } from "antd/lib/table";
import { FunctionComponent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccessZone } from "../../../Api";
import {
  useCategoryActionList,
} from "../../../actions/useCategoryActions";
import useZoneActions from "../../../actions/useZoneActions";
import {
  AccessZoneLevel,
  getEmptyAccessZoneLevel,
} from "../../../emptyModels/AccessZoneLevel";
import FormHeader from "../../../shared/FormHeader";
import SortRow from "../../../shared/SortRow";
import { accessKeys } from "../../../shared/parseJwt";
import { BtnDelete } from "../../../shared/showDeleteConfirm";
import { useOpenNotifications } from "../../../shared/useOpenNotifications";
import { useQueryParams } from "../../../shared/useQueryParams";
import ModalEditPermission from "./ModalEditPermission";

const ZoneForm: FunctionComponent<{ isNew: boolean }> = (props) => {
  let { id, categoryId } = useParams();
  const { from: pathFrom } = useQueryParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [item, setItem] = useState<AccessZoneLevel>(getEmptyAccessZoneLevel());
  const { zoneCategories } = useCategoryActionList();
  const { zone, zoneLoading, updateZone, deleteZone } = useZoneActions({
    id,
    categoryId,
    pathFrom: pathFrom,
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const { apiNotification } = useOpenNotifications();

  const [levels, setLevels] = useState(
    zone.levels?.map((e) => ({ ...e, id: e.name ?? "", key: e.name ?? "" })) ??
      []
  );

  const handleOk = async () => {
    try {
      await form.validateFields();

      if (!levels.length) {
        apiNotification.error({
          message: "Валидация",
          description: "Необходимо указать уровни доступа",
        });
        return;
      }

      updateZone.mutate({
        id: id ?? "",
        payload: {
          ...form.getFieldsValue(),
          levels: levels?.filter(e => e.title?.length && e.name?.length)?.map((e, index) => ({
            name: e.name,
            title: e.title,
            priority: index + 1,
          })),
        },
        isNew: props.isNew,
        category_id: categoryId ?? "",
      });
    } catch (e) {
      // EMPTY
    }
  };

  const handleDelete = async () => {
    Modal.confirm({
      title: "Удалить данные?",
      icon: <ExclamationCircleFilled />,
      okText: "Удалить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        deleteZone.mutate([id ?? ""]);

        navigate(`/categories/${categoryId}`);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCancel = () => {
    setOpen(false);
    if (props.isNew) navigate(`/categories/${categoryId}`);
    else navigate(`/categories/${categoryId}/access_zone/${id}`);
  };

  const columns: ColumnsType<AccessZoneLevel> = [
    {
      key: "sort",
      width: "50px",
    },
    {
      title: "Наименование",
      dataIndex: "title",
      key: "title",
      render: (value, record, index) => {
        if (open && !isNew && record.key == item.key) {
          return (
            <Input
              autoFocus
              value={item.title}
              onChange={(e) => setItem({ ...item, title: e.target.value })}
            />
          );
        }

        return value;
      },
    },
    {
      title: "Код",
      dataIndex: "name",
      key: "name",
      width: "300px",
      render: (value, record, index) => {
        if (open && !isNew && record.key == item.key) {
          return (
            <Input
              value={item.name}
              onChange={(e) => setItem({ ...item, name: e.target.value })}
            />
          );
        }

        return value;
      },
    },
    {
      width: "120px",
      title: "",
      key: "actions",
      dataIndex: "actions",
      render: (value, record, index) => (
        <>
          <BtnDelete
            keyAccess={accessKeys.access}
            values={["E"]}
            onOk={() => {
              setLevels(levels.filter((level) => level.name !== record.name));
            }}
            linkView={""}
            editClick={
              !open || record.key != item.key
                ? () => {
                    setIsNew(false);
                    setItem(record);
                    setOpen(true);
                  }
                : undefined
            }
            okeyClick={
              open && record.key == item.key
                ? () => {
                    if (!item.title.length || !item.name.length) {
                      apiNotification.error({message: 'Валидация', description: 'Не заполнены поля'})
                      return;
                    }

                    const filteredLevels = levels.filter(e => e.id != item.id) ?? []
                    if (filteredLevels.find(e => e.name == item.name.trim()) || filteredLevels.find(e => e.title == item.title.trim())) {
                      apiNotification.error({message: 'Валидация', description: 'Наименование и код должны быть уникальнаыми'})
                      return;
                    }

                    const index = levels.findIndex((e) => e.key == item.key);
                    setLevels([
                      ...levels.slice(0, index),
                      { ...item, name: item.name.trim(), title: item.title.trim(), id: item.name.trim(), key: item.key ?? "" },
                      ...levels.slice(index + 1),
                    ]);

                    setItem(getEmptyAccessZoneLevel());
                    setOpen(false);
                  }
                : undefined
            }
          />
        </>
      ),
    },
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setLevels((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <div className={""}>
      <FormHeader
        title={
          props.isNew ? "Добавление зоны доступа" : "Изменение зоны доступа"
        }
        onSubmit={handleOk}
        onCancel={handleCancel}
      />

      <Form
        style={{ marginBottom: "20px", marginTop: "20px" }}
        layout={"vertical"}
        initialValues={{ ...zone, category_id: categoryId }}
        onFinish={handleOk}
        onFinishFailed={() => {}}
        autoComplete="off"
        disabled={loading}
        form={form}
      >
        <div
          className={"form_row_inline"}
          style={{ gridTemplateColumns: "1fr 200px 190px" }}
        >
          <Form.Item<AccessZone>
            label="Наименование"
            name="title"
            rules={[
              { required: true, message: "Поле обязательно для заполнения" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<AccessZone>
            label="Код"
            name="name"
            rules={[
              { required: true, message: "Поле обязательно для заполнения" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<AccessZone>
            label="Категория"
            name="category_id"
            rules={[
              { required: true, message: "Поле обязательно для заполнения" },
            ]}
          >
            <Select
              options={zoneCategories.map((e) => ({
                value: e.key,
                label: e.title,
              }))}
            />
          </Form.Item>
        </div>
        <Form.Item<AccessZone> label="Описание" name="description">
          <TextArea />
        </Form.Item>
      </Form>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className={"ZoneListTitle"}>Уровни доступа</div>
        <Button
          onClick={() => {
            // setItem(getEmptyAccessZoneLevel());
            // setIsNew(true);
            setOpen(true);
            const level = getEmptyAccessZoneLevel();
            level.id = level.key;
            setItem(level);
            setLevels([
              ...levels,
              { ...level, key: level.key ?? "", id: level.id ?? "" },
            ]);
          }}
          disabled={loading}
        >
          Добавить
        </Button>
      </div>
      <br />

      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={levels?.map((i) => i.id ?? "") ?? []}
          strategy={verticalListSortingStrategy}
        >
          <Table
            loading={loading}
            components={{ body: { row: SortRow } }}
            rowKey="id"
            columns={columns}
            dataSource={(levels ?? []) as any}
            pagination={false}
            onRow={(record, rowIndex) => ({
              onClick: (event) => {
                if (!open) {
                  setItem(record);
                } else {
                  if (item.key != record.key) {
                    setItem(record);
                  }
                }
                setIsNew(false);
                setOpen(true);
              },
            })}
          />
        </SortableContext>
      </DndContext>

      <ModalEditPermission
        item={isNew ? item : getEmptyAccessZoneLevel()}
        onUpdate={(item2) => {
          console.log({ item2 });
          if (isNew) {
            setLevels([
              ...levels,
              { ...item2, id: item2.name, key: item.key ?? "" },
            ]);
          } else {
            const index = levels.findIndex((e) => e.name == item.name);
            setLevels([
              ...levels.slice(0, index),
              { ...item2, id: item2.name, key: item.key ?? "" },
              ...levels.slice(index + 1),
            ]);
          }

          setItem(getEmptyAccessZoneLevel());
          setIsNew(false);
          setOpen(false);
        }}
        isNew={isNew}
        onCancel={() => {
          setIsNew(false);
          setOpen(false);
          setItem(getEmptyAccessZoneLevel());
        }}
      />

      <br />

      {/*{!props.isNew && <div style={{width: '100%'}}>*/}
      {/*    <Button*/}
      {/*        type="default"*/}
      {/*        loading={loading}*/}
      {/*        onClick={handleDelete}*/}
      {/*        style={{marginLeft: 'auto', display: 'block'}}*/}
      {/*        disabled={loading}*/}
      {/*    >*/}
      {/*        Удалить зону*/}
      {/*    </Button>&nbsp;*/}
      {/*</div>}*/}
    </div>
  );
};

export default ZoneForm;
