import React, {FunctionComponent} from "react";
import {Button, Descriptions} from "antd";
import useZoneActions from "../../../actions/useZoneActions";
import {useNavigate, useParams} from "react-router-dom";
import useCategoryActions from "../../../actions/useCategoryActions";
import AppTable from "../../../shared/AppTable";
import {ColumnsType} from "antd/lib/table";
import {AccessZoneLevel} from "../../../emptyModels/AccessZoneLevel";
import {BtnDelete} from "../../../shared/showDeleteConfirm";

const ZoneDataForm: FunctionComponent<{}> = (props) => {
    let {id, categoryId} = useParams();
    const {zone, zoneLoading} = useZoneActions({id})
    const columns: ColumnsType<AccessZoneLevel> = [
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Код',
            dataIndex: 'name',
            key: 'name',
            width: '80px'
        },
        // {
        //     width: '80px',
        //     title: '',
        //     key: 'actions',
        //     dataIndex: 'actions',
        //     render: (value, record, index) => (
        //         <>
        //             <BtnDelete
        //                 onOk={() => {
        //                     // deleteZone.mutate([record.id ?? ''])
        //                 }}
        //                 linkView={''}
        //             />
        //         </>
        //     ),
        // },
    ];

    return <>
        <AppTable columns={columns} items={zone.levels ?? []} />

        <br />
        <br />

        {/*<Button type="primary"*/}
        {/*        loading={zoneLoading}*/}
        {/*        onClick={() => navigate(`/categories/${categoryId}/access_zone/${id}/edit`)}*/}
        {/*>Изменить</Button>*/}
    </>
}

export default ZoneDataForm;
