import {useLocation} from "react-router-dom";

export function useQueryParams() {
    const { search } = useLocation();

    const params: {[x: string]: string} = {};

    search.replace('?', '').split('&').forEach(param => {
        params[param.split('=')[0]] = param.split('=')[1]
    })

    return params;
}