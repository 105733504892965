import {Link} from "react-router-dom";
import React, {FunctionComponent, useState} from "react";
import AppTag from "../../shared/AppTag";

const MainScreenItem: FunctionComponent<{
    icon: string,
    title: string,
    desc: string,
    link: string,
    active: boolean
}> = (props) => {
    // const [isHover, setHover] = useState(false)

    if (props.active)
        return <Link className={'main_screen_item'} to={props.link}
                    //  onMouseLeave={e => setHover(false)}
                    //  onMouseOver={e => setHover(true)}
        >
            <div className={'wrap-img'}>
                <img src={`/menu/icon_${props.icon}_${true ? '3' : '2'}.svg`} width={56} alt=""/>
            </div>
            <div className={'title'}>{props.title}</div>
            <div className={'desc'} dangerouslySetInnerHTML={{__html: props.desc}}></div>
        </Link>

    return <div className={'main_screen_item disabled'}>
        <div className={'header'}>
            <div className={'wrap-img'}>
                <img src={`/menu/icon_${props.icon}_4.svg`} width={56} alt=""/>
            </div>

            <AppTag title={'Недоступно'} disabled={true}/>
        </div>
        <div className={'title'}>{props.title}</div>
        <div className={'desc'} dangerouslySetInnerHTML={{__html: props.desc}}></div>
    </div>
}

export default MainScreenItem;