import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { getLink, localeEntity } from "../historyMapKey";
import { HistoryItem } from "../../../HistoryApi";

const HistoryItemHeader: FunctionComponent<{
  isGlobal: boolean;
  operation?: number;
  user?: { username?: string; avatar_url?: string };
  record: HistoryItem;
}> = ({ user, operation, record, isGlobal }) => {
  return (
    <div className="HistoryItemHeader">
      {user?.avatar_url && (
        <span
          className={"user_avatar"}
          style={{
            backgroundImage: `url(${user?.avatar_url})`,
          }}
        ></span>
      )}
      <span className={"user_action"}>{user?.username}</span> &nbsp;
      {operation == 0 && (
        <Tooltip title="Создание">
          <PlusCircleOutlined
            size={20}
            style={{ color: "var(--character-secondary-45)" }}
          />
        </Tooltip>
      )}
      {operation == 1 && (
        <Tooltip title="Изменение">
          <EditOutlined
            size={20}
            style={{ color: "var(--character-secondary-45)" }}
          />
        </Tooltip>
      )}
      {operation == 3 && (
        <Tooltip title="Действие">
          <EditOutlined
            size={20}
            style={{ color: "var(--character-secondary-45)" }}
          />
        </Tooltip>
      )}
      {operation == 2 && (
        <Tooltip title="Удаление">
          <DeleteOutlined
            size={20}
            style={{ color: "var(--character-secondary-45)" }}
          />
        </Tooltip>
      )}{" "}
      &nbsp;
      {operation != 2 && (
        <span className="object_action title">
          {isGlobal && <>{localeEntity(record.object_type as any)} &nbsp;/&nbsp;</>}
          <NavLink
            className={"object_action"}
            to={getLink(record?.object_type as any, record.object_id ?? "")}
            title={record.changes?.object?.repr}
          >
            {record.changes?.object?.repr}
            {record.operation == 0 && ""}
          </NavLink>
        </span>
      )}
      {record.operation == 2 && (
        <span className="object_action title">
          {isGlobal && <>{localeEntity(record.object_type as any)} / &nbsp;</>}
          <span className={"object_action deleted"}>
            {record.changes?.object?.repr}
          </span>
        </span>
      )}
    </div>
  );
};

export default HistoryItemHeader;
