import React, {FunctionComponent} from "react";
import TagActive from "./TagActive";
import {Button, Tag} from "antd";
import {useNavigate} from "react-router-dom";
import { checkAccess } from "./parseJwt";

const ViewHeader: FunctionComponent<{
    group?: { title?: string, name?: string, status?: string },
    linkEdit: string,
    desc?: string,
    labelActive?: string,
    children?: any,
    childrenTag?: any,
    accessKey: string,
}> = ({group, linkEdit, desc, labelActive, children, accessKey, childrenTag}) => {
    const navigate = useNavigate();

    return <>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <h3 style={{margin: '5px 0', marginRight: '10px'}}>{group?.title} {group?.name && `(${group?.name})`}</h3>
            <>
                {childrenTag && <Tag color={'var(--pnk-color-6)'}>{childrenTag}</Tag>}
                {group?.status && <TagActive labelActive={labelActive} status={group?.status}/>}
            </>

            {children ? children : <>{linkEdit &&  checkAccess(accessKey, ['E', 'F']) && <Button
                style={{marginLeft: 'auto'}}
                type={'primary'} onClick={() => navigate(linkEdit)}>Изменить</Button>}</>}
        </div>
        {desc && <div style={{marginBottom: '20px'}}>{desc}</div>}
    </>;
}

export default ViewHeader;