import {FunctionComponent} from "react";

const AppTableHeader: FunctionComponent<{ left: any; right: any }> = (
    props
) => {
    return (
        <div
            className={`app-table-header`}
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gridGap: "16px",
                marginBottom: "20px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gridGap: "16px",
                }}
            >
                {props.left}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gridGap: "16px",
                }}
            >
                {props.right}
            </div>
        </div>
    );
};

export default AppTableHeader;
