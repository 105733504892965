import React from "react";


export function useOpenNotifications() {

    const Context = React.createContext({name: 'Default'});
    const openNotification = (title: string, desc: any, type: 'info' | 'error' = 'info') => {
        console.log('openNotification', title, desc, type);

        function open() {
            if (type === 'error') {
                (window as any).apiNotifications?.error({
                    message: title,
                    description: <Context.Consumer>{({}) => desc}</Context.Consumer>,
                });
                return;
            }

            (window as any).apiNotifications?.info({
                message: title,
                description: <Context.Consumer>{({}) => desc}</Context.Consumer>,
            });
        }

        if (!(window as any).apiNotifications) {
            setTimeout(() => open(), 100)
        } else {
            open()
        }

    };

    return {
        openNotification,
        apiNotification: {
            info: (props: {
                message: string,
                description: any
            }) => setTimeout(() => openNotification(props.message, props.description, 'info'), 100),
            error: (props: {
                message: string,
                description: any
            }) => setTimeout( () => openNotification(props.message, props.description, 'error'), 100),
        },
    }
}