import {FunctionComponent} from "react";
import RoleForm from "./components/RoleForm";
import {useParams} from "react-router-dom";
import Layout from "../../shared/Layout";
import {useQuery} from "react-query";
import {api} from "../../api_client";
import getEmptyRole, {getEmptyRolePromise} from "../../emptyModels/emptyRole";
import FormHeader from "../../shared/FormHeader";

export function useZones() {
    const {data, error, isLoading} = useQuery({
        queryKey: [`zones_full`],
        queryFn: () => api.zones.zonesList().then(e => e.data)
    })
    return {
        zones: data ?? [],
        zonesError: error,
        zonesLoading: isLoading
    }
}

const RoleEdit: FunctionComponent<{ isNew?: boolean }> = (props) => {
    let {id} = useParams();

    const {data, error, isLoading} = useQuery({
        queryKey: [props.isNew ? 'role_is_new' : `role_${id}`],
        queryFn: () => props.isNew ? getEmptyRolePromise() : api.roles.rolesDetail(id ?? '').then(e => e.data)
    })

    return <>

        <Layout
            wrapContent={true} className={'role_view_list'}
            title={props.isNew ? 'Новая роль' : `${data?.name}`}
            back={{title: 'Роли', link: '/roles', titleBack: 'Роли'}}
            loading={isLoading}
            error={error as any}
        >
            <RoleForm
                item={data ?? getEmptyRole()}
                permissions={data?.permissions ?? []}
                isNew={props.isNew ?? false}
            />

        </Layout>
    </>
}

export default RoleEdit;

