import { FunctionComponent, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import IconView from "../../../shared/IconView";
import AppTable from "../../../shared/AppTable";
import { useQuery } from "react-query";
import { Group } from "../../../Api";
import { api } from "../../../api_client";
import { GroupsActionsListParams } from "../../../actions/useGroupsAction";

const RoleGroupsList: FunctionComponent<{ id: string }> = (props) => {
  const navigate = useNavigate();
  const [params, setParams] = useState<GroupsActionsListParams>({
    page: 1,
    per_page: 15,
  });

  const { data, error, isLoading } = useQuery({
    queryKey: [`roles_${props.id}_groups_${JSON.stringify(params)}`],
    queryFn: () =>
      props.id
        ? api.roles.groupsDetail(props.id, params).then((e) => e.data)
        : Promise.resolve({
            data: [],
          }),
  });

  const columns: ColumnsType<Group> = [
    {
      title: "Наименование",
      dataIndex: "title",
      key: "title",
      className: "column_title",
      render: (text, record) => (
        <NavLink to={`/groups/${record.id}`}>{text}</NavLink>
      ),
    },
    {
      title: "Код",
      dataIndex: "name",
      key: "name",
      width: "300px",
    },
  ];

  function filterDataSource(items: Group[], search: string) {
    if (search != params.search) {
      setParams({...params, search: search, page: 1})
    }

    return items
  }

  return (
    <div style={{ marginTop: "-55px" }}>
      <AppTable
        pagination={{
          perPage: params?.per_page ?? 15,
          current: data?.meta?.current_page ?? 1,
          total: data?.meta?.total ?? 0,
          showSizeChanger: true,
          onSelect: (page, perPage) =>
            setParams({ ...params, page: page, per_page: perPage }),
        }}
        filter={{
          onDataSource: filterDataSource,
          placeholder: "Поиск по наименованию/коду",
        }}
        onRowClick={(e) => navigate(`/groups/${e.id}`)}
        columns={columns}
        items={data?.data ?? []}
      />
    </div>
  );
};

export default RoleGroupsList;
