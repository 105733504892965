import React, {FunctionComponent, useState} from "react";
import Layout from "../../shared/Layout";
import {useParams} from "react-router-dom";
import {Tabs} from "antd";
import CategoryDataForm from "./components/CategoryDataForm";
import useCategoryActions from "../../actions/useCategoryActions";
import CategoryZonesListEdit from "./components/CategoryZonesListEdit";
import ViewHeader from "../../shared/ViewHeader";
import CategoryZonesList from "./components/CategoryZonesList";
import { accessKeys } from "../../shared/parseJwt";

const CategoriesView: FunctionComponent<{}> = (props) => {
    let {id} = useParams();
    const {zoneCategory, zoneCategoryLoading, zoneCategoryError} = useCategoryActions(id)

    const [tab, setTab] = useState('1')

    return <Layout
        className={'category_view'}
        title={zoneCategory.title}
        back={{title: 'Доступ', link: '/categories', titleBack: 'Доступ'}}
        loading={zoneCategoryLoading}
        error={zoneCategoryError as any}
    >
        <ViewHeader
            linkEdit={`/categories/${id}/edit`}
            group={zoneCategory}
            desc={zoneCategory.description || ' '}
            accessKey={accessKeys.access}
        />

        <CategoryZonesListEdit allowActions={false}/>

    </Layout>
}

export default CategoriesView;