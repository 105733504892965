import {useQuery} from "react-query";
import {api} from "../api_client";

export function useRoleView(id: string) {
    const {data: role, error: roleError, isLoading: roleLoading} = useQuery({
        queryKey: [`user_${id}`],
        queryFn: () => api.roles.rolesDetail(id).then(e => e.data)
    })

    return {role, roleError, roleLoading}
}