import {GroupExtended} from "../Api";

export function getEmptyGroupExtended(): GroupExtended {
    return {
        id: "",
        title: "",
        name: "",
        status: "",
        created_at: "",
    }
}