import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";

export function useAppTab() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = useState('')

    useEffect(() => {
        if (tab !== '') {
            setSearchParams({...searchParams, tab: tab})
        }
    }, [tab])

    useEffect(() => {
        if (searchParams.get('tab') !== tab) {
            setTab(searchParams.get('tab') ?? 'main')
        }
    }, [searchParams])

    return {tab, setTab}
}