import React from "react";
import {Tabs} from "antd";
import Layout from "../../shared/Layout";
import SettingsComponents from "./components/SettingsComponents";
import EmergencyMode from "./components/EmergencyMode";
import ViewHeader from "../../shared/ViewHeader";

const SettingsView: React.FC = () => {
    return (
        <Layout
            title={"Настройки"}
            back={{ title: "Настройки", link: "/" }}
            //    loading={isLoading}
        >
            <SettingsComponents />
            <EmergencyMode />

        </Layout>
    );
};

export default SettingsView;
