import { Api, ContentType, QueryParamsType } from "./Api";
import { Api as HistoryApi } from "./HistoryApi";
import { checkTimeRefresh, refreshTokenFun } from "./shared/refreshToken";
import Cookies from "js-cookie";

function getApi(token?: string) {
  return new Api({
    baseUrl: process.env.REACT_APP_API_URL + "/api",
    baseApiParams: {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    },
    customFetch: async (...fetchParams: Parameters<typeof fetch>) => {
      if (checkTimeRefresh()) {
        await refreshTokenFun();
      }

      (
        fetchParams[1] as any
      ).headers.Authorization = `Bearer ${Cookies.get("token")}`;

      const res = await fetch(fetchParams[0], fetchParams[1]);

      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      if (
        res.status == 401 &&
        !["/auth", "/restore_password"].includes(
          location.pathname.split("?")[0]
        )
      ) {
        if (location.pathname != "/auth") {
          /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
          location.href = process.env.REACT_APP_AUTH_REDIRECT + '?redirect=' + window.location.href;
        }
        // console.log("reauth");
      }

      return res;
    },
  });
}

function getApiHistory(token?: string) {
  return new HistoryApi({
    baseUrl: process.env.REACT_APP_API_URL + "/history/api",
    baseApiParams: {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
      // headers: {},
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    },
    customFetch: async (...fetchParams: Parameters<typeof fetch>) => {
      if (checkTimeRefresh()) {
        await refreshTokenFun();
      }

      (
        fetchParams[1] as any
      ).headers.Authorization = `Bearer ${Cookies.get("token")}`;

      const res = await fetch(fetchParams[0], fetchParams[1]);

      /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
      if (
        res.status == 401 &&
        !["/auth", "/restore_password"].includes(
          location.pathname.split("?")[0]
        )
      ) {
        console.log('access_token', Cookies.get("token"));
        
        if (location.pathname != "/auth") {
          /*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/
          location.href = process.env.REACT_APP_AUTH_REDIRECT + '?redirect=' + window.location.href;
        }
        // console.log("reauth");
      }

      return res;
    },
  });
}

export let api = getApi(Cookies.get("token") || undefined);

export let historyApi = getApiHistory(
  Cookies.get("token") || undefined
);

export function resetApi(token?: string) {
  api = getApi(token);

  historyApi = getApiHistory(token);
}

export const contentFormatters: Record<ContentType, (input: any) => any> = {
  [ContentType.Json]: (input: any) =>
    input !== null && (typeof input === "object" || typeof input === "string")
      ? JSON.stringify(input)
      : input,
  [ContentType.Text]: (input: any) =>
    input !== null && typeof input !== "string" ? JSON.stringify(input) : input,
  [ContentType.FormData]: (input: any) => {
    if (input instanceof FormData) {
      return input;
    }

    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`
      );
      return formData;
    }, new FormData());
  },
  [ContentType.UrlEncoded]: (input: any) => toQueryString(input),
};

function encodeQueryParam(key: string, value: any) {
  const encodedKey = encodeURIComponent(key);
  return `${encodedKey}=${encodeURIComponent(
    typeof value === "number" ? value : `${value}`
  )}`;
}

function addQueryParam(query: QueryParamsType, key: string) {
  return encodeQueryParam(key, query[key]);
}

function addArrayQueryParam(query: QueryParamsType, key: string) {
  const value = query[key];
  return value.map((v: any) => encodeQueryParam(key, v)).join("&");
}

function toQueryString(rawQuery?: QueryParamsType): string {
  const query = rawQuery || {};
  const keys = Object.keys(query).filter(
    (key) => "undefined" !== typeof query[key]
  );
  return keys
    .map((key) =>
      Array.isArray(query[key])
        ? addArrayQueryParam(query, key)
        : addQueryParam(query, key)
    )
    .join("&");
}
