import { useQuery } from "react-query";
import { api } from "../api_client";

export default function (params?: {
  users: { page: number; perPage: number; search: string };
}) {
  const {
    data: groupsHelper,
    error: groupsHelperError,
    isLoading: groupsHelperLoading,
  } = useQuery({
    queryKey: [`groups_helper`],
    cacheTime: 60000,
    staleTime: 60000,
    queryFn: () => {
      return api.helpers.groupsList({ per_page: 600000 }).then((e) => ({
        data: e.data.data?.map((e) => ({ ...e, key: e.value })),
        meta: e.data.meta,
      }));
    },
  });

  const {
    data: rolesHelper,
    error: rolesHelperError,
    isLoading: rolesHelperLoading,
  } = useQuery({
    queryKey: [`roles_helper`],
    cacheTime: 60000,
    staleTime: 60000,
    queryFn: () => {
      return api.helpers.rolesList().then((e) => ({
        data: e.data?.map((e) => ({ ...e, key: e.value })) ?? [],
      }));
    },
  });

  const {
    data: usersHelper,
    error: usersHelperError,
    isLoading: usersHelperLoading,
  } = useQuery({
    queryKey: [
      `users_helper_${params?.users?.page}_${params?.users?.perPage}_${params?.users?.search}`,
    ],
    cacheTime: 60000,
    staleTime: 60000,
    queryFn: () => {
      return api.helpers
        .usersList({
          search: params?.users.search,
          page: params?.users?.page,
          per_page: params?.users.perPage ?? 600000,
        })
        .then((e) => ({
          data: e.data.data?.map((e) => ({ ...e, key: e.value })),
          meta: e.data.meta,
        }));
    },
  });

  return {
    loading: groupsHelperLoading || rolesHelperLoading || usersHelperLoading,

    groupsHelper,
    groupsHelperLoading,
    groupsHelperError,

    rolesHelper,
    rolesHelperLoading,
    rolesHelperError,

    usersHelper,
    usersHelperLoading,
    usersHelperError,
  };
}
