import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { AddGroupRequest, GroupUpdateRequest } from "../Api";
import { api } from "../api_client";
import { getEmptyGroupExtended } from "../emptyModels/getEmptyGroupExtended";
import { getEmptyMetaData } from "../emptyModels/getEmptyMetaData";
import { useOpenNotifications } from "../shared/useOpenNotifications";

export interface GroupsActionsListParams {
  search?: string;
  status?: "active" | "archive";
  /**
   * Список uuid через запятую
   * @format uuid
   */
  roles?: string[];
  /** @format uuid */
  group?: string[];
  /**
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * @min 1
   * @default 10
   */
  per_page?: number;
}

export function useGroupsActionList({
  listParams,
}: {
  listParams?: GroupsActionsListParams;
}) {
  const { data, error, isLoading } = useQuery({
    queryKey: [`groups_${JSON.stringify(listParams)}`],
    queryFn: () => {
      return api.groups
        .groupsList({
          groups: listParams?.group?.join(","),
          roles: listParams?.roles?.join(","),
          page: listParams?.page,
          per_page: listParams?.per_page,
          search: listParams?.search,
          status: listParams?.status,
        })
        .then((e) => ({
          data: e.data.data?.map((e) => ({ ...e, key: e.id })),
          meta: e.data.meta,
        }));
    },
  });

  return {
    groups: data ?? { data: [], meta: getEmptyMetaData() },
    groupsLoading: isLoading,
    groupsError: error,
  };
}

export default function ({ id }: { id?: string }) {
  const {
    data: item,
    error: itemError,
    isLoading: itemLoading,
  } = id
    ? useQuery({
        queryKey: [`groups_${id}`],
        queryFn: () => api.groups.groupsDetail(id).then((e) => e.data),
      })
    : {
        data: undefined,
        error: undefined,
        isLoading: false,
      };
  const {
    data: groupZones,
    error: groupZonesError,
    isLoading: groupZonesLoading,
  } = id
    ? useQuery({
        queryKey: [`group_${id}_zones`],
        queryFn: () => api.groups.usersDetail(id).then((e) => e.data),
      })
    : {
        data: undefined,
        error: undefined,
        isLoading: false,
      };
  const {
    data: groupGroups,
    error: groupGroupsError,
    isLoading: groupGroupsLoading,
  } = id
    ? useQuery({
        queryKey: [`group_${id}_groups`],
        queryFn: () => {
          return api.groups.childrenDetail(id).then((e) => e.data);
        },
      })
    : {
        data: undefined,
        error: undefined,
        isLoading: false,
      };
  const { apiNotification } = useOpenNotifications();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  function resetCache(listParams: GroupsActionsListParams, id?: string) {
    queryClient.invalidateQueries({ queryKey: ["groups"] });
    queryClient.invalidateQueries({
      queryKey: [`groups_${JSON.stringify(listParams)}`],
    });
    if (id) {
      queryClient.invalidateQueries({ queryKey: [`group_${id}`] });
      queryClient.invalidateQueries({ queryKey: [`group_${id}_zones`] });
      queryClient.invalidateQueries({ queryKey: [`group_${id}_groups`] });
    }
  }

  const mutationUpdateGroup = useMutation({
    mutationFn: async (params: {
      id: string;
      payload: AddGroupRequest & GroupUpdateRequest;
      isNew: boolean;
      roles?: string[];
      listParams: GroupsActionsListParams;
    }) => {
      console.log({ params });
      let res;
      let id = params.id;
      if (params.isNew) {
        // delete params.payload.status;
        res = await api.groups.groupAdd(params.payload);
        id = res.data.id ?? id;
      } else {
        res = await api.groups.groupUpd(params.id, params.payload);
      }

      // if (
      //   (res?.status === 200 || res?.status === 201) &&
      //   params?.users != undefined
      // ) {
      //   let res2 = await api.groups.usersCreate(id ?? "", {
      //     users: params.users.filter((e) => !!e),
      //   });
      // }

      return res;
    },
    onSuccess: (result, params) => {
      resetCache(params.listParams, params.id);
      if (params.isNew) {
        navigate(`/groups/${result.data.id}`);
      } else {
        navigate(`/groups/${params.id}`);
      }
      apiNotification.info({
        message: `Сохранение`,
        description: <>Данные сохранены</>,
      });
    },
    onError: (err: any, params) => {
      resetCache(params.listParams, params.id);
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось обновить</>,
      });
    },
  });

  const mutationDeleteGroup = useMutation({
    mutationFn: ({
      ids,
    }: {
      ids: string[];
      listParams: GroupsActionsListParams;
    }) => {
      return api.groups.groupsDelete({ ids: ids });
    },
    onSuccess: (result, params) => {
      params.ids.forEach((id) => resetCache(params.listParams, id));
      navigate(`/groups`);
      apiNotification.info({
        message: `Удаление`,
        description:
          params.ids.length > 1 ? <>Группы удалены</> : <>Группа удалена</>,
      });
    },
    onError: (err: any, params) => {
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось удалить</>,
      });
      params.ids.forEach((id) => resetCache(params.listParams, id));
    },
  });

  return {
    group: item ?? getEmptyGroupExtended(),
    groupLoading:
      itemLoading ||
      mutationUpdateGroup.isLoading ||
      mutationDeleteGroup.isLoading,
    groupError: itemError,

    updateGroup: mutationUpdateGroup,
    deleteGroup: mutationDeleteGroup,

    groupZones: groupZones,
    groupZonesLoading: groupZonesLoading,
    groupZonesError: groupZonesError,

    groupGroups: groupGroups,
    groupGroupsLoading: groupGroupsLoading,
    groupGroupsError: groupGroupsError,
  };
}
