import { FunctionComponent } from "react";
import AppHeaderNavigate from "./AppHeaderNavigate";
import AppLayoutContent from "./AppLayoutContent";
import AppMenu from "./AppMenu";
import NotificationLayout from "./NotificationLayout";

const Layout: FunctionComponent<{
  className?: string;
  children?: any;
  title?: string;
  back?: {
    title: string;
    link: string;
    titleBack?: string;
    link2?: string;
    titleBack2?: string;
  };
  bgOpacity?: boolean;
  left?: any;
  right?: any;
  wrapContent?: boolean;
  loading?: boolean;
  navHeader?: any;
  error?: { error?: { code?: string; message?: string } };
  header?: {
    tag?: string;
  }
}> = (props) => {
  return (
    <NotificationLayout>
      <div
        className={`app-layout ${props.className} ${
          props.wrapContent ? "wrap-content" : ""
        }`}
      >
        <AppLayoutContent {...props} />
        <AppMenu />
        <AppHeaderNavigate {...props} />
      </div>
    </NotificationLayout>
  );
};

export default Layout;
