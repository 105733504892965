import React, { FunctionComponent, useEffect, useState } from "react";
import Layout from "../../shared/Layout";
import { Form, Radio, Select, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { NavLink, useNavigate } from "react-router-dom";
import { BtnDelete } from "../../shared/showDeleteConfirm";
import AppTable from "../../shared/AppTable";
import { useQuery } from "react-query";
import { Group, User } from "../../Api";
import { api } from "../../api_client";
import useGroupsAction, {
  GroupsActionsListParams,
  useGroupsActionList,
} from "../../actions/useGroupsAction";
import { accessKeys, checkAccess } from "../../shared/parseJwt";
import useHelpers from "../../actions/useHelpers";

const GroupsList: FunctionComponent<any> = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {rolesHelper, groupsHelper} = useHelpers();
  const [params, setParams] = useState<GroupsActionsListParams>({ page: 1, per_page: 15 });
  const { groups, groupsLoading, groupsError } = useGroupsActionList({
    listParams: params,
  });
  const { deleteGroup } = useGroupsAction({});

  const columns: ColumnsType<Group> = [
    {
      title: "Наименование",
      dataIndex: "title",
      className: "column_title",
      key: "title",
      render: (text, record) => (
        <NavLink to={`/groups/${record.id}`}>{text}</NavLink>
      ),
    },
    {
      title: "Код",
      dataIndex: "name",
      key: "name",
      width: "300px",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: "100px",
      render: (value, record, index) => {
        if (value == "active") {
          return <Tag color="orange">Активна</Tag>;
        }

        return <Tag>В архиве</Tag>;
      },
    },

    {
      width: "120px",
      title: "",
      key: "action",
      render: (value, record, index) => (
        <BtnDelete
          keyAccess={accessKeys.groups}
          values={["E"]}
          onOk={() => {
            deleteGroup.mutate({ids: [record.id ?? ""], listParams: params});
          }}
          linkView={`/groups/${record.id}/edit`}
        />
      ),
    },
  ];

  function applyFilters() {
    console.log(form.getFieldsValue());
    const roles = form.getFieldValue("roles") ?? [];
    const groups = form.getFieldValue("groups") ?? [];
    const isBlocked = form.getFieldValue("isBlocked") ?? "all";

    let status = undefined;
    switch (isBlocked) {
      case "all":
        break;
      case "isBlocked":
        status = "archive";
        break;
      case "isAllowed":
        status = "active";
        break;
      default:
        break;
    }

    setParams({
      group: groups,
      roles: roles,
      status: status as any,
      page: 1,
      per_page: 15
    });
  }

  function filterDataSource(items: User[], search: string) {
    if (search != params.search) {
      setParams({
        ...params,
        search: search,
        page: 1,
      });
    }

    return items;
  }

  return (
    <Layout
      className={"groups_list"}
      title={"Группы"}
      back={{ title: "Главная", link: "/" }}
    >
      <AppTable
        title={"Группы"}
        pagination={{
          perPage: params?.per_page ?? 15,
          current: groups?.meta?.current_page ?? 1,
          total: groups?.meta?.total ?? 0,
          showSizeChanger: true,
          onSelect: (page, perPage) =>
            setParams({ ...params, page: page, per_page: perPage }),
        }}
        loading={groupsLoading}
        filtersModal={{
          content: (
            <>
              <Form
                form={form}
                layout="vertical"
                initialValues={{ roles: [], groups: [], isBlocked: "all" }}
              >
                <Form.Item name="roles" label="Роли">
                  <Select
                    mode="multiple"
                    options={rolesHelper?.data?.map((e) => ({
                      value: e.value,
                      label: e.text,
                    }))}
                    placeholder="Выбрать"
                    filterOption={function (inputValue, option) {
                      if (
                        option?.label
                          ?.toString()
                          ?.toLowerCase()
                          .includes(inputValue.toLowerCase())
                      ) {
                        return true;
                      }
                      return false;
                    }}
                  />
                </Form.Item>
                <Form.Item name="groups" label="Дочерние группы">
                  <Select
                    mode="multiple"
                    options={groupsHelper?.data?.map((e) => ({
                      value: e.value,
                      label: e.text,
                    }))}
                    placeholder="Выбрать"
                    filterOption={function (inputValue, option) {
                      if (
                        option?.label
                          ?.toString()
                          ?.toLowerCase()
                          .includes(inputValue.toLowerCase())
                      ) {
                        return true;
                      }
                      return false;
                    }}
                  />
                </Form.Item>
                <Form.Item name="isBlocked">
                  <Radio.Group>
                    <Radio value="all">Все</Radio>
                    <Radio value="isAllowed">Активные</Radio>
                    <Radio value="isBlocked">В архиве</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </>
          ),
          onOk: applyFilters,
          onCancel: () => {
            // setParams({...params})
          },
          onClear: () => {
            console.log("onClear");
            form.resetFields();
            setParams({});
            // setItems(mockUsers)
          },
          count:
            (params.group?.length ? 1 : 0) +
            (params.roles?.length ? 1 : 0) +
            (params.status?.length ? 1 : 0),
        }}
        columns={columns}
        items={groups?.data ?? []}
        filter={{
          onDataSource: filterDataSource,
          placeholder: "Поиск по наименованию/коду",
        }}
        accessCreate={{
          key: accessKeys.groups,
          values: ["E"],
        }}
        onCreate={() => navigate("/groups/add")}
        onRowClick={(record: User) => navigate(`/groups/${record.id}`)}
        canManyActions={checkAccess(accessKeys.groups, ["E", "F"])}
        onManyDelete={(ids: string[]) => deleteGroup.mutate({ids, listParams: params})}
      />
    </Layout>
  );
};

export default GroupsList;
