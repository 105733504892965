import React, { FunctionComponent, useEffect, useState } from "react";
import Layout from "../../shared/Layout";
import { TableRowSelection } from "antd/es/table/interface";
import { Button, Input, Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { ExclamationCircleFilled, MenuOutlined, SearchOutlined } from "@ant-design/icons";
import { CSS } from "@dnd-kit/utilities";
import AppTableHeader from "../../shared/AppTableHeader";
import { NavLink, useNavigate } from "react-router-dom";
import { CategoryWithZones } from "../../Api";
import useZoneActions from "../../actions/useZoneActions";
import useCategoryActions from "../../actions/useCategoryActions";
import { getEmptyCategoryWithZones } from "../../emptyModels/getEmptyAccessZone";
import { BtnDelete } from "../../shared/showDeleteConfirm";
import AppIcon from "../../shared/Icons/AppIcon";
import { accessKeys, checkAccess } from "../../shared/parseJwt";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const CategoriesList: FunctionComponent<any> = (props) => {
  const [allowManyActions, setAllowManyActions] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [searchRes, setSearchRes] = useState<CategoryWithZones[]>([]);
  const navigate = useNavigate();
  const { zones, zonesLoading, zonesError, deleteZone } = useZoneActions({});
  const { deleteZoneCategory, sortZoneCategory, zoneCategoryLoading } =
    useCategoryActions();
  const [ids, setIds] = React.useState<string[]>([]);

  useEffect(() => {
    setIds(zones?.map((zone) => zone.id ?? "") ?? []);
  }, [zones]);

  function getUrlView(record: CategoryWithZones) {
    return record.zones !== undefined
      ? `/categories/${record.id}`
      : `/categories/${
          zones?.find((e) => e?.zones?.find((e1) => e1.id === record.id))?.id ??
          "0"
        }/access_zone/${record.id}`;
  }

  const columns: ColumnsType<CategoryWithZones> = [
    {
      key: "sort",
      width: "90px",
      className: "sort_categories",
    },
    {
      title: "Наименование",
      dataIndex: "title",
      className: "column_title",
      key: "title",
      render: (value, record, index) => {
        if (record.zones !== undefined) {
          return (
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <NavLink to={getUrlView(record)}>{value}</NavLink>
            </span>
          );
        }

        return <NavLink to={getUrlView(record)}>{value}</NavLink>;
      },
    },
    {
      title: "Код",
      dataIndex: "name",
      key: "name",
      width: "300px",
    },
    {
      title: "",
      key: "action",
      width: "120px",
      render: (value, record, index) => (
        <BtnDelete
          keyAccess={accessKeys.access}
          values={["E"]}
          onOk={() => {
            if (record.zones != undefined) {
              deleteZoneCategory.mutate([record.id ?? ""]);
            } else {
              deleteZone.mutate([record.id ?? ""]);
            }
          }}
          linkView={getUrlView(record) + "/edit"}
          addLink={
            record.zones != undefined
              ? `/categories/${record.id}/access_zone/add`
              : undefined
          }
        />
      ),
    },
  ];

  useEffect(() => {
    setSelected([]);
  }, [allowManyActions]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setIds((previous) => {
        const activeIndex = previous.findIndex((i) => i === active.id);
        const overIndex = previous.findIndex((i) => i === over?.id);
        const res = arrayMove(previous, activeIndex, overIndex);
        sortZoneCategory.mutate(res);
        return res;
      });
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: "Удалить данные?",
      icon: <ExclamationCircleFilled />,
      okText: "Удалить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        deleteZoneCategory.mutate(selected);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const rowSelection: TableRowSelection<CategoryWithZones> = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelected(selectedRowKeys as string[]);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const pluckItems: CategoryWithZones[] = [];
    zones.forEach((e) => pluckItems.push(...[e, ...(e.zones ?? [])]));
    const record = pluckItems.find((e) => e.id === props["data-row-key"]);
    const isCategory = props.className?.includes("ant-table-row-level-0");

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
        onClick={() => (record ? navigate(getUrlView(record)) : "")}
      >
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort" && isCategory) {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  className={"icon_sort"}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  useEffect(() => {
    setSearchRes(filterDataSource(zones, search));
  }, [zones, search]);

  function filterDataSource(
    items: CategoryWithZones[],
    search: string
  ): CategoryWithZones[] {
    if (!search) return items;

    let res = items
      .map((item) => {
        const zones = item.zones?.filter((e2) =>
          `${e2.name} ${e2.title}`.toLowerCase().includes(search.toLowerCase())
        );

        if (zones?.length) {
          return {
            ...item,
            zones: zones,
            children: zones,
          };
        }

        if (
          `${item?.id} ${item?.title} ${(item as any)?.name}`
            ?.toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return { ...item, zones: [] };
        }

        return undefined;
      })
      .filter((e) => !!e);

      return res as CategoryWithZones[];
  }

  return (
    <Layout
      wrapContent={true}
      className={"categories_list"}
      title={"Доступ"}
      back={{ title: "Главная", link: "/" }}
    >
      <AppTableHeader
        left={[<h3 key={`apptable_title_categories`}>Категории</h3>]}
        right={[
          allowManyActions ? (
            <AppIcon
              key={"allowManyA41"}
              disabled={!selected.length}
              active={false}
              path={"/layout/delete"}
              onClick={() => showDeleteConfirm()}
            />
          ) : undefined,
          checkAccess(accessKeys.access, ["E"]) && (
            <AppIcon
              key={"allowManyA_btn4"}
              active={allowManyActions}
              path={"/layout/many_actions"}
              onClick={() => setAllowManyActions(!allowManyActions)}
            />
          ),

          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
            placeholder={"Поиск по наименованию/коду"}
            style={{ width: "30vw", minWidth: "300px" }}
          />,
          checkAccess(accessKeys.access, ["E"]) && (
            <Button
              type={"primary"}
              key={"allowManyA_btn6"}
              onClick={() => navigate("/categories/add")}
            >
              Добавить
            </Button>
          ),
        ]}
      />
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={zones.map((i) => i.id) as any}
          strategy={verticalListSortingStrategy}
        >
          <Table
            loading={zonesLoading || zoneCategoryLoading}
            components={{ body: { row: Row } }}
            columns={columns}
            rowSelection={
              allowManyActions
                ? { ...rowSelection, checkStrictly: false }
                : undefined
            }
            dataSource={ids
              .map(
                (id) =>
                  searchRes?.find((e) => e.id === id) ??
                  getEmptyCategoryWithZones()
              )
              .filter((e) => e.id !== "")}
            pagination={false}
            onRow={(record, rowIndex) => ({
              onClick: (event) => navigate(getUrlView(record)),
            })}
          />
        </SortableContext>
      </DndContext>
    </Layout>
  );
};

export default CategoriesList;
