import React, { FunctionComponent } from "react";
import Layout from "../../shared/Layout";
import { useNavigate, useParams } from "react-router-dom";
import useGroupsAction from "../../actions/useGroupsAction";
import GroupForm from "./components/GroupForm";

const UserView: FunctionComponent<{ isAdd?: boolean }> = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { group, groupLoading, groupError } = useGroupsAction({ id });

  return (
    <Layout
      title={props.isAdd ? "Новая группа" : group?.title ?? "Группа"}
      back={{ title: "Группы", link: "/groups", titleBack: "Группы" }}
      loading={groupLoading}
      error={groupError as any}
    >
      <GroupForm
        isNew={props.isAdd ?? false}
        group={group}
        onDelete={() => {
          navigate("/groups");
        }}
        onBack={() => {
          if (props.isAdd) {
            navigate(`/groups`);
          } else {
            navigate(`/groups/${id}`);
          }
        }}
      />
    </Layout>
  );
};

export default UserView;
