import React, { FunctionComponent, useEffect, useState } from "react";
import Layout from "../../shared/Layout";
import { useParams } from "react-router-dom";
import ZoneDataForm from "./components/ZoneDataForm";
import ViewHeader from "../../shared/ViewHeader";
import useZoneActions from "../../actions/useZoneActions";
import useCategoryActions from "../../actions/useCategoryActions";
import { accessKeys } from "../../shared/parseJwt";
import { CategoryWithZones } from "../../Api";
import { api } from "../../api_client";

const ZoneAccessView: FunctionComponent<{}> = (props) => {
  let { id, categoryId } = useParams();
  const { zone, zoneLoading } = useZoneActions({ id });

  const [category, setCategory] = useState<CategoryWithZones>({});

  useEffect(() => {
    if (zone.category_id && zone.category_id != category.id) {
      api.zoneCategories
        .zoneCategoriesDetail(zone.category_id ?? "")
        .then((e) => setCategory(e.data));
    }
  }, [zone.category_id]);


  return (
    <Layout
      title={zone.title ?? "Зона доступа"}
      back={{
        title: "Категории доступов",
        link: `/categories`,
        titleBack: "Доступ",
        link2: `/categories/${zone?.category_id}`,
        titleBack2: category.title ?? "Категория доступов",
      }}
      loading={zoneLoading || !category.id}
    >
      <>
        <ViewHeader
          group={zone}
          desc={zone.description}
          linkEdit={`/categories/${zone?.category_id}/access_zone/${id}/edit`}
          accessKey={accessKeys.access}
        />

        <h4 style={{position:  'relative', 'top': '15px'}}>Уровни доступа</h4>

        <ZoneDataForm />
      </>
    </Layout>
  );
};

export default ZoneAccessView;
