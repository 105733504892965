import {Role} from "../Api";


export default function getEmptyRole(): Role {
    return {
        id: "",
        name: "",
        description: "",
        permissions: []
    }
}

export function getEmptyRolePromise() {
    return Promise.resolve(getEmptyRole())
}