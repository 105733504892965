import React, {FunctionComponent} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useQuery} from "react-query";
import {SimpleRole} from "../../../Api";
import {ColumnsType} from "antd/lib/table";
import IconView from "../../../shared/IconView";
import AppTable from "../../../shared/AppTable";
import {api} from "../../../api_client";
import {getLevelLocale} from "../../../shared/getLevelLocale";

const UserAccessList: FunctionComponent<{ userId: string }> = (props) => {
    const {data, error, isLoading} = useQuery({
        queryKey: [`user_${props.userId}_zones`], queryFn: () => api.users.zonesDetail(props.userId).then(e => e.data.map(e => ({
                    ...e, children: e.zones?.map(e => ({...e, key: e.id})), key: e.id,
                }
            )
        ))
    })
    const navigate = useNavigate()

    function getUrlView(record: any) {
        if (record.children != undefined) {
            return `/categories/${record.id}`
        }
        return `/categories/${record.category_id}/access_zone/${record.id}`
    }

    const columns: ColumnsType<SimpleRole> = [
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => <NavLink to={getUrlView(record)}>{text}</NavLink>,
        },
        {
            title: 'Уровень',
            dataIndex: 'level',
            key: 'level',
            render: (value, record, index) =>  ((record  as any).level_title) ?? getLevelLocale(value),
        },
    ]

    return <AppTable
        loading={isLoading}
        columns={columns}
        items={data ?? []}
        onRowClick={(record) => navigate(getUrlView(record))}
        expandedRowKeys={data?.filter(e => e.zones?.find(e => e.level)).map(item => item.key ?? '')}
    />
}

export default UserAccessList;