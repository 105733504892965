import React, {FunctionComponent, useState} from "react";
import Layout from "../../shared/Layout";
import {Form, Radio, Select} from "antd";
import {ColumnsType} from "antd/lib/table";
import {NavLink, useNavigate} from "react-router-dom";
import {BtnDelete} from "../../shared/showDeleteConfirm";
import AppTable from "../../shared/AppTable";
import {User} from "../../Api";
import useUserAction, {UserActionsListParams,} from "../../actions/useUserAction";
import {accessKeys, checkAccess} from "../../shared/parseJwt";
import useHelpers from "../../actions/useHelpers";

const UsersList: FunctionComponent<any> = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const {groupsHelper, rolesHelper} = useHelpers();

    const [params, setParams] = useState<UserActionsListParams>({
        page: 1,
        per_page: 15,
    });
    const {users, usersLoading, usersError, deleteUser} = useUserAction({
        listParams: params,
    });

    const columns: ColumnsType<User> = [
        {
            title: "ФИО",
            dataIndex: "full_name",
            className: "column_title",
            key: "full_name",
            render: (text, record) => (
                <NavLink to={`/users/${record.id}`}>
                    {text}{" "}
                    {record.in_active_directory ? (
                        <span className="ldapLabel">LDAP</span>
                    ) : (
                        ""
                    )}
                </NavLink>
            ),
        },
        {
            title: "Логин",
            dataIndex: "login",
            key: "login",
            width: "300px",
        },
        {
            width: "120px",
            title: "",
            key: "action",
            render: (value, record, index) => (
                <BtnDelete
                    keyAccess={accessKeys.users}
                    values={["E"]}
                    onOk={() => {
                        deleteUser.mutate([record.id ?? ""]);
                    }}
                    linkView={`/users/${record.id}/edit`}
                />
            ),
        },
    ];

    function applyFilters() {
        console.log(form.getFieldsValue());
        const roles = form.getFieldValue("roles") ?? [];
        const groups = form.getFieldValue("groups") ?? [];
        const inLdap = form.getFieldValue("inLdap") ?? "";
        const isBlocked = form.getFieldValue("isBlocked") ?? "all";

        let status = undefined;
        switch (isBlocked) {
            case "all":
                break;
            case "isBlocked":
                status = "archive";
                break;
            case "isAllowed":
                status = "active";
                break;
            default:
                break;
        }

        setParams({
            page: 1,
            group: groups,
            roles: roles,
            status: status as any,
            per_page: 15,
            in_ad: inLdap ? 1 : undefined,
        });
    }

    function filterDataSource(items: User[], search: string) {
        if (search !== params.search) {
            setParams({
                ...params,
                search: search,
                page: 1,
            });
        }

        return items;
    }

    return (
        <Layout
            className={"users_list"}
            title={"Пользователи"}
            back={{title: "Главная", link: "/"}}
        >
            <AppTable
                title={"Пользователи"}
                pagination={{
                    perPage: params?.per_page ?? 15,
                    current: users?.meta?.current_page ?? 1,
                    total: users?.meta?.total ?? 0,
                    showSizeChanger: true,
                    onSelect: (page, perPage) =>
                        setParams({...params, page: page, per_page: perPage}),
                }}
                loading={usersLoading}
                filtersModal={{
                    content: (
                        <>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{
                                    roles: [],
                                    groups: [],
                                    isBlocked: "all",
                                    inLdap: "",
                                }}
                            >
                                <Form.Item name="roles" label="Роли">
                                    <Select
                                        mode="multiple"
                                        filterOption={function (inputValue, option) {
                                            if (
                                                option?.label
                                                    ?.toString()
                                                    ?.toLowerCase()
                                                    .includes(inputValue.toLowerCase())
                                            ) {
                                                return true;
                                            }
                                            return false;
                                        }}
                                        options={rolesHelper?.data?.map((e) => ({
                                            value: e.value,
                                            label: e.text,
                                        }))}
                                        placeholder="Выбрать"
                                    />
                                </Form.Item>
                                <Form.Item name="groups" label="Группы">
                                    <Select
                                        mode="multiple"
                                        filterOption={function (inputValue, option) {
                                            if (
                                                option?.label
                                                    ?.toString()
                                                    ?.toLowerCase()
                                                    .includes(inputValue.toLowerCase())
                                            ) {
                                                return true;
                                            }
                                            return false;
                                        }}
                                        options={groupsHelper?.data?.map((e) => ({
                                            value: e.value,
                                            label: e.text,
                                        }))}
                                        placeholder="Выбрать"
                                    />
                                </Form.Item>
                                <Form.Item name="inLdap" label="Пользователи">
                                    <Radio.Group>
                                        <Radio value="">Все</Radio>
                                        <Radio value="ldap">LDAP</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item name="isBlocked">
                                    <Radio.Group>
                                        <Radio value="all">Все</Radio>
                                        <Radio value="isAllowed">Доступные</Radio>
                                        <Radio value="isBlocked">В черном списке</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Form>
                        </>
                    ),
                    onOk: applyFilters,
                    onCancel: () => {
                    },
                    onClear: () => {
                        console.log("onClear");
                        form.resetFields();
                        setParams({});
                        // setItems(mockUsers)
                    },
                    count:
                        (params.group?.length ? 1 : 0) +
                        (params.roles?.length ? 1 : 0) +
                        (params.status?.length ? 1 : 0) +
                        (params.in_ad != undefined ? 1 : 0),
                }}
                columns={columns}
                items={users?.data ?? []}
                filter={{
                    onDataSource: filterDataSource,
                    placeholder: "Поиск по ФИО/логину",
                }}
                accessCreate={{
                    key: accessKeys.users,
                    values: ["E"],
                }}
                onCreate={() => navigate("/users/add")}
                onRowClick={(record: User) => navigate(`/users/${record.id}`)}
                canManyActions={checkAccess(accessKeys.users, ["E", "F"])}
                onManyDelete={(ids: string[]) => deleteUser.mutate(ids)}
            />
        </Layout>
    );
};

export default UsersList;
