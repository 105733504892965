import React, {FunctionComponent, useState} from "react";
import AppTable from "../../../shared/AppTable";
import IconView from "../../../shared/IconView";
import {ColumnsType} from "antd/lib/table";
import {NavLink, useNavigate} from "react-router-dom";
import {useQuery} from "react-query";
import {Group, GroupList, User} from "../../../Api";
import {api} from "../../../api_client";
import {Switch} from "antd";

const UserGroupsList: FunctionComponent<{ userId: string }> = (props) => {
    const [params, setParams] = useState<{ page: number, search?: string, only_direct?: number, per_page: number }>({page: 1, per_page: 15})
    const {data, error, isLoading} = useQuery({ queryKey: [`user_${props.userId}_groups_${JSON.stringify(params)}`], queryFn: () => api.users.groupsDetail(props.userId, params).then(e => e.data) })
    const navigate = useNavigate()

    function getUrlView(record: Group) {
        return `/groups/${record.id}`
    }

    const columns: ColumnsType<Group> = [
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => <NavLink to={`/groups/${record.id}`}>{text}</NavLink>,
        },
        {
            title: 'Код',
            dataIndex: 'name',
            key: 'name',
            width: '140px',
        },
    ]

    function filterDataSource(items: Group[], search: string) {
        if (search != params.search) {
            setParams({
                ...params,
                search: search,
            })
        }

        return items;
    }


    return <AppTable
        columns={columns}
        loading={isLoading}
        items={data?.data ?? []}
        filter={{
            onDataSource: filterDataSource,
            placeholder: 'Поиск по наименованию/коду',
            componentRight: <>
                Только прямые вхождения &nbsp;
                <Switch
                    title={'Только прямые вхождения'}
                    onChange={value => setParams({...params, only_direct: value ? 1 : undefined})} />
            </>
        }}
        pagination={{
            perPage: params?.per_page ?? 15,
            current: data?.meta?.current_page ?? 1,
            total: data?.meta?.total ?? 0,
            onSelect: (page) => setParams({...params, page: page}),
        }}
        onRowClick={(record) => navigate(getUrlView(record))}
    />
}

export default UserGroupsList;