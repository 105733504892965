import React, { FunctionComponent, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer, Input, Popconfirm, Table } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { api } from "../api_client";
import HistoryList from "../screens/history/HistoryList";
import { getAccess } from "./parseJwt";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import useHelpers from "../actions/useHelpers";
import AppTable from "./AppTable";
import Cookies from "js-cookie";

const AppHeaderNavigate: FunctionComponent<{
  title?: string;
  back?: {
    title: string;
    link: string;
    titleBack?: string;
    link2?: string;
    titleBack2?: string;
  };
}> = (props) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["userme"],
    queryFn: () => api.me.getMe().then((e) => e.data),
  });
  const [open, setOpen] = useState(false);

  let access: { [x: string]: string } = {};
  if (!(window as any).access) {
    (window as any).access = getAccess(Cookies.get("token") ?? "");
  }

  access = (window as any).access;

  const confirm = () =>
    new Promise((resolve) => {
      resolve(setUsers(selectedRowKeys as string[]));
    });

  const [params, setParams] = useState({ page: 1, perPage: 100, search: "" });

  const { usersHelper, usersHelperLoading, usersHelperError } = useHelpers({
    users: params,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [users, setUsers] = useState<string[]>([]);

  return (
    <div className={"app-content-header"}>
      <div className={"app-content-title"}>
        {props.title === undefined ? (
          <>
            <span>Добрый день, {data?.full_name}</span>
            <span>Выберите раздел, чтобы начать работу 🚀</span>
          </>
        ) : (
          <span className={"app-content-title2"}>
            {" "}
            {props.back ? (
              <>
                <NavLink to={"/"}>Главная </NavLink>&nbsp;/&nbsp;
                {props.back.titleBack && (
                  <>
                    <NavLink to={props.back.link}>
                      {props.back.titleBack}{" "}
                    </NavLink>
                    &nbsp;/&nbsp;
                  </>
                )}
                {props.back.titleBack2 && (
                  <>
                    <NavLink to={props.back.link2 ?? ""}>
                      {props.back.titleBack2}{" "}
                    </NavLink>
                    &nbsp;/&nbsp;
                  </>
                )}
              </>
            ) : (
              <></>
            )}{" "}
            <>{props.title}</>
          </span>
        )}
      </div>
      <div className={"app-content-avatar"}>
        {["R"].includes(access["UR_HS"]) && (
          <Button type={"link"} onClick={() => setOpen(true)}>
            <img src={"/layout/history.svg"} alt={"history"} />
          </Button>
        )}
        <Avatar size="large" src={data?.avatar_url}>
          {data?.full_name
            ?.split(" ")
            .map((e) => e[0])
            ?.join("")}
        </Avatar>

        <div>{data?.full_name}</div>
      </div>
      <Drawer
        title="Журнал изменений"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={600}
        extra={
          <Popconfirm
            title=""
            okText="ОК"
            cancelText="Сбросить"
            cancelButtonProps={{
              type: "text",
              style: { float: "left" },
            }}
            description={
              <div style={{ width: "450px" }}>
                <Input
                  placeholder="Поиск по ФИО/логину"
                  suffix={<SearchOutlined />}
                  value={params.search}
                  onChange={(e) =>
                    setParams({ ...params, search: e.target.value })
                  }
                />
                <Table
                className="historyUsersFilter"
                  loading={usersHelperLoading}
                  showHeader={false}
                  rowSelection={{
                    selectedRowKeys,
                    onChange: (newSelectedRowKeys: React.Key[]) => {
                      console.log(
                        "selectedRowKeys changed: ",
                        newSelectedRowKeys
                      );
                      setSelectedRowKeys(newSelectedRowKeys);
                    },
                  }}
                  columns={[
                    {
                      title: "",
                      dataIndex: "text",
                    },
                  ]}
                  dataSource={usersHelper?.data}
                  pagination={{
                    current: usersHelper?.meta?.current_page,
                    total: usersHelper?.meta?.total,
                    onChange: (page, perPage) =>
                      setParams({ ...params, page, perPage }),
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                    locale: {
                      items_per_page: " на странице",
                    },
                  }}
                />
              </div>
            }
            onConfirm={confirm}
            onOpenChange={() => console.log("open change")}
            onCancel={() => {
              setParams({ page: 1, perPage: 10, search: "" });
              setSelectedRowKeys([]);
              setUsers([])
            }}
            icon={<></>}
          >
            <Button type={"text"}>
              <FilterOutlined />
            </Button>
          </Popconfirm>
        }
      >
        <HistoryList users={users} />
      </Drawer>
    </div>
  );
};

export default AppHeaderNavigate;
