export interface AccessZoneLevel {
    id?: string;
    key?: string;
    title: string;
    name: string;
    priority: number;
}

export function getEmptyAccessZoneLevel(): AccessZoneLevel {
    return {
        key: `${Math.random()}`,
        title: '',
        name: '',
        priority: 0
    };
}

