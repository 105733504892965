import React, { useEffect } from "react";
import MainScreenItem from "./MainScreenItem";
import Layout from "../../shared/Layout";
import { useNavigate } from "react-router-dom";
import { getAccess } from "../../shared/parseJwt";
import { useOpenNotifications } from "../../shared/useOpenNotifications";
import ViewHeader from "../../shared/ViewHeader";
import Cookies from "js-cookie";

function MainScreen() {
  // const hasToken = true

  const navigate = useNavigate();

  const hasToken = Cookies.get("token") != null;

  useEffect(() => {
    if (!hasToken) {
      window.location.href = process.env.REACT_APP_AUTH_REDIRECT + "?redirect=" + window.location.href;
    }
  });

  let access: { [x: string]: string } = {};
  if (!(window as any).access) {
    (window as any).access = getAccess(Cookies.get("token") ?? "");
  }

  access = (window as any).access;

  return (
    <div className="main_screen">
      {!hasToken && "Loading..."}
      {hasToken && (
        <>
          <Layout bgOpacity={true} title="Главная">
            <ViewHeader
              group={{ title: "Главная" }}
              desc="Выберите раздел, чтобы начать работу:"
              linkEdit=""
              accessKey=""
            />
            <div className={"main_screen_items"}>
              <MainScreenItem
                icon={"users"}
                link={"/users"}
                title={"Пользователи"}
                desc={
                  "Управляйте списком пользователей <br/> и редактируйте их данные"
                }
                active={["F", "E", "R"].includes(access["UR_US"])}
              />
              <MainScreenItem
                icon={"groups"}
                link={"/groups"}
                title={"Группы"}
                desc={
                  "Объединяйте пользователей в группы <br/> по общим признакам"
                }
                active={["F", "E", "R"].includes(access["UR_GR"])}
              />
              <MainScreenItem
                icon={"roles"}
                link={"/roles"}
                title={"Роли"}
                desc={
                  "Создавайте, удаляйте и настраивайте <br/> уровни доступа для пользователей и групп"
                }
                active={["F", "E", "R"].includes(access["UR_RL"])}
              />
              <MainScreenItem
                icon={"access"}
                link={"/categories"}
                title={"Доступы"}
                desc={"Настраивайте зоны доступов и категории зон доступов"}
                active={["F", "E", "R"].includes(access["UR_AZ"])}
              />
              <MainScreenItem
                icon={"settings"}
                link={"/settings"}
                title={"Настройки"}
                desc={
                  "Управляйте списком пользователей <br/> и редактируйте их данные"
                }
                active={["F", "E", "R"].includes(access["UR_ST"])}
              />
            </div>
          </Layout>
        </>
      )}
    </div>
  );
}

export default MainScreen;
