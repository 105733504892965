import React, {FunctionComponent, useState} from "react";
import AppTable from "../../../shared/AppTable";
import IconView from "../../../shared/IconView";
import {ColumnsType} from "antd/lib/table";
import {NavLink, useNavigate} from "react-router-dom";
import {useQuery} from "react-query";
import {Group, GroupListWithRoles, GroupWithRoleList} from "../../../Api";
import {api} from "../../../api_client";
import AppTag from "../../../shared/AppTag";
import { Tag } from "antd";

const GroupGroupsList: FunctionComponent<{ groupId: string }> = (props) => {
    const [params, setParams] = useState<{ page: number, per_page: number }>({page: 1, per_page: 15})
    const {data, error, isLoading} = useQuery({
        queryKey: [`groups_${props.groupId}_groups_${JSON.stringify(params)}`],
        queryFn: () => api.groups.parentsDetail(props.groupId, params).then(e => (e.data as any) as GroupListWithRoles)
    })
    const navigate = useNavigate()

    function getUrlView(record: Group) {
        return `/groups/${record.id}`
        // return record.children !== undefined ? `/categories/${record.key}` : `/categories/${dataSource.find(e => e?.children?.find(e1 => e1.key === record.key))?.key ?? '0'}/access_zone/${record.key}`;
    }

    const columns: ColumnsType<GroupWithRoleList> = [
        {
            title: 'Наименование',
            dataIndex: 'title',
            className: "column_title",
            render: (text, record) => <NavLink to={`/groups/${record.id}`}>{text}</NavLink>,
            key: 'title',
        },
        {
            title: 'Код',
            dataIndex: 'name',
            key: 'name',
            width: '140px',
        },
        {
            title: 'Роли',
            dataIndex: 'roles',
            key: 'roles',
            render: (text, record) => record.roles?.map(e => <Tag>{e}</Tag>),
        },
    ]

    return <AppTable
        columns={columns}
        loading={isLoading}
        items={data?.data ?? []}
        pagination={{
            perPage: params?.per_page ?? 15,
            current: data?.meta?.current_page ?? 1,
            total: data?.meta?.total ?? 0,
            onSelect: (page, perPage) => setParams({...params, page: page, per_page: perPage}),
        }}
        onRowClick={(record) => navigate(getUrlView(record))}
    />
}

export default GroupGroupsList;