import React, { FunctionComponent } from "react";
import Layout from "../../shared/Layout";
import CategoryForm from "./components/CategoryForm";
import useCategoryActions from "../../actions/useCategoryActions";
import { useParams } from "react-router-dom";

const CategoriesEdit: FunctionComponent<{ isAdd?: boolean }> = (props) => {
  let { id } = useParams();
  const { zoneCategory, zoneCategoryLoading, zoneCategoryError } =
    useCategoryActions(id);

  return (
    <Layout
      className={"category_edit"}
      title={props.isAdd ? "Новая категория доступа" : zoneCategory?.title}
      back={{
        title: "Доступ",
        link: "/categories",
        titleBack: "Доступ",
      }}
      loading={zoneCategoryLoading}
      error={zoneCategoryError as any}
    >
      <CategoryForm isAdd={props.isAdd ?? false} />
    </Layout>
  );
};

export default CategoriesEdit;
