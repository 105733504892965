import React, {FunctionComponent, useState} from "react";
import Layout from "../../shared/Layout";
import {ColumnsType} from "antd/lib/table";
import {NavLink, useNavigate} from "react-router-dom";
import {BtnDelete} from "../../shared/showDeleteConfirm";
import AppTable from "../../shared/AppTable";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Role} from "../../Api";
import useNotification from "antd/es/notification/useNotification";
import {api} from "../../api_client";
import {useOpenNotifications} from "../../shared/useOpenNotifications";
import TagActive from "../../shared/TagActive";
import {accessKeys} from "../../shared/parseJwt";
import {Form, Radio, Select} from "antd";
import {UserActionsListParams} from "../../actions/useUserAction";

const RolesList: FunctionComponent<any> = (props) => {
    const [params, setParams] = useState<UserActionsListParams>({
        page: 1,
        per_page: 15,
    });
    const {data, error, isLoading} = useQuery({
        queryKey: [`roles_${JSON.stringify(params)}`],
        queryFn: () => api.roles.rolesList(params),
    });
    const navigate = useNavigate();
    const {apiNotification} = useOpenNotifications();
    const queryClient = useQueryClient();
    const [form] = Form.useForm();

    function getUrlView(record: Role) {
        return `/roles/${record.id}`;
    }

    const handleDelete = useMutation({
        mutationFn: (ids: string[]) =>
            ids.length > 1
                ? api.roles.rolesDelete({ids: ids})
                : api.roles.roleDel(ids[0]),
        onSuccess: (result) => {
            navigate(`/roles`);
            queryClient.invalidateQueries({queryKey: ["roles"]});
            apiNotification.info({
                message: `Удаление`,
                description: <>Роль удалена</>,
            });
        },
        onError: (err: any) => {
            queryClient.invalidateQueries({queryKey: ["roles"]});
            apiNotification.error({
                message: `Ошибка`,
                description: err.error?.message ?? <>Не удалось удалить</>,
            });
        },
    });

    const columns: ColumnsType<Role> = [
        {
            title: "Наименование",
            dataIndex: "title",
            className: "column_title",
            key: "title",
            render: (text, record) => (
                <NavLink to={`/roles/${record.id}`}>{text}</NavLink>
            ),
        },
        {
            title: "Код",
            dataIndex: "name",
            key: "name",
            width: "300px",
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            width: "100px",
            render: (value, record, index) => (
                <TagActive status={value} labelActive="Активна"/>
            ),
        },
        {
            width: "100px",
            title: "",
            key: "action",
            render: (value, record, index) => (
                <>
                    <BtnDelete
                        keyAccess={accessKeys.roles}
                        values={["E"]}
                        onOk={() => {
                            handleDelete.mutate([record.id ?? ""]);
                        }}
                        linkView={getUrlView(record) + '/edit'}
                    />
                </>
            ),
        },
    ];

    function filterDataSource(items: Role[], search: string) {
        if (search !== params.search) {
            setParams({
                ...params,
                search: search,
                page: 1,
            });
        }

        return items;
    }

    function applyFilters() {
        console.log(form.getFieldsValue());
        const roles = form.getFieldValue("roles") ?? [];
        const groups = form.getFieldValue("groups") ?? [];
        const inLdap = form.getFieldValue("inLdap") ?? "";
        const isBlocked = form.getFieldValue("isBlocked") ?? "all";

        let status = undefined;
        switch (isBlocked) {
            case "all":
                break;
            case "isBlocked":
                status = "archive";
                break;
            case "isAllowed":
                status = "active";
                break;
            default:
                break;
        }

        setParams({
            page: 1,
            group: groups,
            roles: roles,
            status: status as any,
            per_page: 15,
            in_ad: inLdap === "ldap" ? 1 : inLdap === "none" ? 0 : undefined,
        });
    }

    return (
        <Layout
            wrapContent={true}
            className={"roles_list"}
            title={"Роли"}
            back={{title: "Главная", link: "/"}}
        >
            <AppTable
                title={"Роли"}
                loading={isLoading}
                filter={{
                    onDataSource: filterDataSource,
                    placeholder: "Поиск по наименованию/коду",
                }}
                accessCreate={{
                    key: accessKeys.roles,
                    values: ["E"],
                }}
                onCreate={() => navigate("/roles/add")}
                columns={columns}
                items={data?.data ?? []}
                onRowClick={(item) => {
                    navigate(getUrlView(item));
                }}
                filtersModal={{
                    content: (
                        <>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{
                                    roles: [],
                                    groups: [],
                                    isBlocked: "all",
                                    inLdap: "",
                                }}
                            >
                                <Form.Item name="inLdap" label="Синхронизация по LDAP">
                                    <Radio.Group>
                                        <Radio value="">Все</Radio>
                                        <Radio value="ldap">LDAP</Radio>
                                        <Radio value="none">Отсутствует</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item name="isBlocked" label="Статус роли">
                                    <Radio.Group>
                                        <Radio value="all">Все</Radio>
                                        <Radio value="isAllowed">Активные</Radio>
                                        <Radio value="isBlocked">В архиве</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Form>
                        </>
                    ),
                    onOk: applyFilters,
                    onCancel: () => {
                    },
                    onClear: () => {
                        console.log("onClear");
                        form.resetFields();
                        setParams({});
                        // setItems(mockUsers)
                    },
                    count:
                        (params.group?.length ? 1 : 0) +
                        (params.roles?.length ? 1 : 0) +
                        (params.status?.length ? 1 : 0) +
                        (params.in_ad != undefined ? 1 : 0),
                }}
            />
        </Layout>
    );
};

export default RolesList;
