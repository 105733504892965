import {AccessZone, CategoryWithZones} from "../Api";


export default function getEmptyAccessZone(): AccessZone {
    return {
        id: "",
        name: "",
        category_id: "",
        levels: [],
        title: "",
        order: 1,
    }
}

export function getEmptyAccessZonePromise() {
    return Promise.resolve(getEmptyAccessZone())
}

export function getEmptyCategoryWithZones(): CategoryWithZones {
    return {
        id: "",
        zones: [],
        title: "",
        order: 1,
        description: "",
    }
}