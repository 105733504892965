import {Form, Input, Modal} from "antd";
import React, {FunctionComponent, useEffect, useState} from "react";
import {AccessZoneLevel, getEmptyAccessZoneLevel} from "../../../emptyModels/AccessZoneLevel";

const ModalEditPermission: FunctionComponent<{ item: AccessZoneLevel, onUpdate: (item: AccessZoneLevel) => void, isNew: boolean, onCancel: () => void }> = (props) => {
    const [item, setItem] = useState(props.item)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    const [source, setSource] = useState({...item})

    useEffect(() => {
        setItem(props.item)
        form.setFieldsValue(props.item)
    }, [props.item])

    const handleOk = async () => {
        try {
            await form.validateFields()
            props.onUpdate({key: item.name,...form.getFieldsValue()})
            setItem(getEmptyAccessZoneLevel())
            form.resetFields()
        } catch (e) {
            // EMPTY
        }
    };


    return <Modal title={props.isNew ? "Добавить уровень доступа" : "Изменить уровень доступа"}
                  open={props.item.name != '' || props.isNew} onOk={handleOk} onCancel={props.onCancel}
                  cancelText='Отмена'
                  confirmLoading={loading}>
        <Form
            className={'wrap-content'}
            style={{marginBottom: '20px', marginTop: '20px'}}
            layout={'vertical'}
            initialValues={item}
            onFinish={handleOk}
            onFinishFailed={() => {
            }}
            autoComplete="off"
            disabled={loading}
            form={form}
        >
            <Form.Item<AccessZoneLevel>
                label="Наименование"
                name="title"
                rules={[{required: true, message: 'Поле обязательно для заполнения'}]}
            >
                <Input/>
            </Form.Item>
            <Form.Item<AccessZoneLevel>
                label="Код"
                name="name"
                rules={[{required: true, message: 'Поле обязательно для заполнения'}]}
            >
                <Input/>
            </Form.Item>
        </Form>
    </Modal>
}

export default ModalEditPermission;
