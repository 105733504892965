import { notification } from "antd";
import { FunctionComponent } from "react";

const NotificationLayout: FunctionComponent<any> = (props) => {
  const [apiNotifications, contextHolder] = notification.useNotification();

  (window as any).apiNotifications = apiNotifications;

  return (
    <>
      {contextHolder}

      {props.children}
    </>
  );
};

export default NotificationLayout;
