import { useMutation, useQuery, useQueryClient } from "react-query";
import React from "react";
import useNotification from "antd/es/notification/useNotification";
import { useNavigate } from "react-router-dom";
import { api } from "../api_client";
import { AddUserRequest } from "../Api";
import { getEmptyCategoryWithZones } from "../emptyModels/getEmptyAccessZone";
import { getEmptyMetaData } from "../emptyModels/getEmptyMetaData";
import { useOpenNotifications } from "../shared/useOpenNotifications";

export interface UserActionsListParams {
  search?: string;
  status?: "active" | "archive";
  /**
   * Список uuid через запятую
   * @format uuid
   */
  roles?: string[];
  /** @format uuid */
  group?: string[];
  /** @default 1 */
  page?: number;
  /** @default 10 */
  per_page?: number;
  in_ad?: number;
}

export default function ({
  id,
  listParams,
}: {
  id?: string;
  listParams?: UserActionsListParams;
}) {
  const { data, error, isLoading } = useQuery({
    queryKey: [`users_${JSON.stringify(listParams)}`],
    queryFn: () => {
      return api.users
        .usersList({
          groups: listParams?.group?.join(","),
          roles: listParams?.roles?.join(","),
          page: listParams?.page,
          per_page: listParams?.per_page,
          search: listParams?.search,
          status: listParams?.status,
          in_ad: listParams?.in_ad ? 1 : undefined,
        })
        .then((e) => ({
          data: e.data.data?.map((e) => ({ ...e, key: e.id })),
          meta: e.data.meta,
        }));
    },
  });
  const {
    data: item,
    error: itemError,
    isLoading: itemLoading,
  } = id
    ? useQuery({
        queryKey: [`users_${id}`],
        queryFn: () => api.users.usersDetail(id).then((e) => e.data),
      })
    : {
        data: undefined,
        error: undefined,
        isLoading: false,
      };
  const {
    data: userZones,
    error: userZonesError,
    isLoading: userZonesLoading,
  } = id
    ? useQuery({
        queryKey: [`users_${id}_zones`],
        queryFn: () => api.users.zonesDetail(id).then((e) => e.data),
      })
    : {
        data: undefined,
        error: undefined,
        isLoading: false,
      };
  const {
    data: userGroups,
    error: userGroupsError,
    isLoading: userGroupsLoading,
  } = id
    ? useQuery({
        queryKey: [`users_${id}_groups`],
        queryFn: () => {
          console.log("userGroups");
          return api.users.groupsDetail(id).then((e) => e.data);
        },
      })
    : {
        data: undefined,
        error: undefined,
        isLoading: false,
      };
  const { apiNotification } = useOpenNotifications();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  function resetCache(id?: string) {
    queryClient.invalidateQueries({ queryKey: ["users"] });
    queryClient.invalidateQueries({
      queryKey: [`users_${JSON.stringify(listParams)}`],
    });
    if (id) {
      queryClient.invalidateQueries({ queryKey: [`users_${id}`] });
      queryClient.invalidateQueries({ queryKey: [`users_${id}_zones`] });
      queryClient.invalidateQueries({ queryKey: [`users_${id}_groups`] });
    }
  }

  const mutationUpdateUser = useMutation({
    mutationFn: async (params: {
      id: string;
      payload: AddUserRequest & { password?: string | undefined };
      isNew: boolean;
      roles?: string[];
      groups?: string[];
    }) => {
      console.log("mutationFn", params);

      let res;
      let id = params.id;
      if (params.isNew) {
        // delete params.payload.status;
        res = await api.users.addUser(params.payload);
        id = res.data.id ?? id;
      } else {
        res = await api.users.userUpd(params.id, params.payload);
      }

    //   if (
    //     (res?.status === 200 || res?.status === 201) &&
    //     params?.roles != undefined
    //   ) {
    //     res = await api.users.userRoles(id ?? "", {
    //       roles: params?.roles ?? [],
    //     });
    //   }

      // if (res?.status === 200 && params?.groups?.length) {
      //     res = await api.users.userGroups(params.id ?? '', {
      //         groups: params?.groups ?? []
      //     })
      // }

      return res;
    },
    onSuccess: (result, params) => {
      apiNotification.info({
        message: `Сохранение`,
        description: <>Данные сохранены</>,
      });
      resetCache(params.id);
      if (params.isNew) {
        navigate(`/users/${result.data.id}`);
      } else {
        navigate(`/users/${params.id}`);
      }
    },
    onError: (err: any, params) => {
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось обновить</>,
      });
      resetCache(params.id);
    },
  });

  const mutationDeleteUser = useMutation({
    mutationFn: (ids: string[]) => {
      return ids.length > 1
        ? api.users.usersDelete({ ids: ids })
        : api.users.userDel(ids[0]);
    },
    onSuccess: (result, ids) => {
      apiNotification.info({
        message: `Удаление`,
        description: <>Пользователи удалены</>,
      });
      ids.forEach((id) => resetCache(id));
      navigate(`/users`);
    },
    onError: (err: any, ids) => {
      apiNotification.error({
        message: `Ошибка`,
        description: err.error?.message ?? <>Не удалось удалить</>,
      });
      ids.forEach((id) => resetCache(id));
    },
  });

  return {
    users: data ?? { data: [], meta: getEmptyMetaData() },
    usersLoading:
      isLoading || mutationUpdateUser.isLoading || mutationDeleteUser.isLoading,
    usersError: error,

    user: item ?? getEmptyCategoryWithZones(),
    userLoading:
      itemLoading ||
      mutationUpdateUser.isLoading ||
      mutationDeleteUser.isLoading,
    userError: itemError,

    updateUser: mutationUpdateUser,
    deleteUser: mutationDeleteUser,

    userZones,
    userZonesLoading,
    userZonesError,

    userGroups: userGroups,
    userGroupsLoading,
    userGroupsError,
  };
}
