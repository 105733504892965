import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Modal, Typography } from "antd";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { api } from "../../../api_client";
import { accessKeys, checkAccess } from "../../../shared/parseJwt";
import { useOpenNotifications } from "../../../shared/useOpenNotifications";

const EmergencyMode = () => {
  const [form] = Form.useForm();
  const [isShow, onChangeShow] = useState(false);
  const { apiNotification } = useOpenNotifications();

  const handleReset = useMutation({
    mutationFn: () => {
      return api.settings.resetPasswordsCreate();
    },
    onSuccess: () => {
      apiNotification.info({message: 'Действие', description: 'Сброс данных завершен.'})
    }
  });

  const showPropsConfirm = () => {
    Modal.confirm({
      title: "Сброс всех токенов и пароли пользователей",
      icon: <ExclamationCircleFilled />,
      cancelText: "Отмена",
      okText: "Сбросить",

      cancelButtonProps: { type: "text" },
      okButtonProps: {
        type: "primary",
        style: { backgroundColor: "#FF4D4F" },
      },
      content: (
        <Typography.Text disabled>
          Отменить действие будет невозможно!
        </Typography.Text>
      ),
      onOk() {
        handleReset.mutate();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <br />
      {checkAccess(accessKeys.settings, ["F"]) && (
        <>
          <Checkbox onChange={() => onChangeShow(!isShow)}>
            Аварийный режим
          </Checkbox>
          <Col>
            <Typography.Title
              level={5}
              className={isShow ? "" : "disabled_text"}
            >
              Сброс всех токенов и паролей пользователей
            </Typography.Title>
            <Typography.Text
              type="danger"
              className={isShow ? "" : "disabled_text"}
            >
              Нажимать только в крайнем случае!
            </Typography.Text>
            <br />
            <br />
            <Form
              layout={"vertical"}
              form={form}
              initialValues={{ layout: "horizontal" }}
              style={{ maxWidth: "100%" }}
            >
              <Form.Item>
                <Button
                  style={{ borderRadius: "4px" }}
                  type="primary"
                  danger
                  onClick={showPropsConfirm}
                  disabled={!isShow}
                >
                  Сбросить все
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </>
      )}
    </>
  );
};

export default EmergencyMode;
