import React, {FunctionComponent} from "react";
import {Tag} from "antd";

const TagActive: FunctionComponent<{ status?: string, labelActive?: string}> = (props) => {
    if (props.status == 'active') {
        return <Tag color={'orange'}>{props.labelActive ?? 'Активная'}</Tag>
    }
    return <Tag>В архиве</Tag>
}

export default TagActive;