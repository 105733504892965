import React, {FunctionComponent, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useQuery} from "react-query";
import {SimpleRole, UserAccessZone, UserCategoryWithZones, UserCategoryWithZonesList} from "../../../Api";
import {ColumnsType} from "antd/lib/table";
import IconView from "../../../shared/IconView";
import AppTable from "../../../shared/AppTable";
import {api} from "../../../api_client";
import {getLevelLocale} from "../../../shared/getLevelLocale";

const GroupAccessList: FunctionComponent<{ groupId: string }> = (props) => {
    const [params, setParams] = useState<{ page: number }>({page: 1})
    const {data, error, isLoading} = useQuery({
        queryKey: [`groups_${props.groupId}_zones_${JSON.stringify(params)}`],
        queryFn: () => api.groups.zonesDetail(props.groupId).then(e => e.data.map(e => ({...e, key: e.id, children: e.zones})))
    })
    const navigate = useNavigate()

    function getUrlView(record: UserCategoryWithZones) {
        const category = data?.find(e => e.id === record.id)
        if (category) {
            return `/categories/${record.id}`
        }

        const category2 = data?.find(e => e.zones?.find(e2 => e2.id == record.id))
        const item = category2?.zones?.find(e => e.id === record.id)

        return `/categories/${category2?.id}/access_zone/${item?.id}`
    }

    const columns: ColumnsType<UserAccessZone> = [
        {
            title: 'Наименование',
            dataIndex: 'title',
            className: "column_title",
            render: (text, record) => <NavLink to={`/groups/${record.id}`}>{text}</NavLink>,
            key: 'title',
        },
        {
            title: 'Уровень',
            key: 'level',
            render: (text, record) => {
                return getLevelLocale(record.level)
            }
        },

    ]

    return <AppTable
        loading={isLoading}
        columns={columns}
        items={data ?? []}
        onRowClick={(record) => navigate(getUrlView(record))}
        expandedRowKeys={data?.map(e => e.id ?? '') ?? []}
    />
}

export default GroupAccessList;