import React, { FunctionComponent, useEffect, useState } from "react";
import Layout from "../../shared/Layout";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, Tabs, Tag } from "antd";
import UserDataForm from "./components/UserDataForm";
import UserAccessList from "./components/UserAccessList";
import UserGroupsList from "./components/UserGroupsList";
import { useQuery } from "react-query";
import { api } from "../../api_client";
import ViewHeader from "../../shared/ViewHeader";
import { accessKeys } from "../../shared/parseJwt";

const UserView: FunctionComponent<{ isAdd?: boolean }> = (props) => {
  let { id } = useParams();
  const [tab, setTab] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (tab !== "") {
      setSearchParams({ ...searchParams, tab: tab });
    }
  }, [tab]);

  useEffect(() => {
    if (searchParams.get("tab") !== tab) {
      setTab(searchParams.get("tab") ?? "accesses");
    }
  }, [searchParams]);

  const { data, error, isLoading } = useQuery({
    queryKey: [`user_${id}`],
    queryFn: () => api.users.usersDetail(id ?? "").then((e) => e.data),
  });

  return (
    <Layout
      className={"user_view"}
      title={
        props.isAdd
          ? "Новый пользователь"
          : `${
              data?.full_name
                ? `${data?.full_name} (${data?.login})`
                : "Пользователь"
            }`
      }
      back={{
        title: "Пользователи",
        link: "/users",
        titleBack: "Пользователи",
      }}
      loading={isLoading}
      error={error as any}
    >
      <ViewHeader
        group={{ ...data, title: data?.full_name }}
        linkEdit={`/users/${id}/edit`}
        labelActive={"Активен"}
        accessKey={accessKeys.users}
        childrenTag={data?.in_active_directory ? "LDAP" : ""}
      />

      <div className={"DataInfo"}>
        <div className={"DataInfoRow1"}>
          <div
            className={"img"}
            style={{
              backgroundImage: `url(${
                data?.avatar_url ?? "/layout/avatar.png"
              })`,
            }}
          ></div>
          <div>
            <div>Логин: {data?.login}</div>
            {data?.email && (
              <div>
                E-mail: <a href={`mailto:${data?.email}`}>{data?.email}</a>
              </div>
            )}
            {data?.telegram_id && <div>Telegram ID: {data?.telegram_id}</div>}
          </div>
        </div>
        <div>
          Роли:{" "}
          {data?.roles?.map((role) => (
            <Tag key={`role_${role.id}`}>{role.title}</Tag>
          ))}
        </div>
        {data?.in_active_directory && (
          <div>
            Базовые роли:{" "}
            {data?.base_roles?.map((role) => (
              <Tag key={`role_${role.id}`}>{role.title}</Tag>
            ))}
          </div>
        )}
      </div>

      <br />

      {!props.isAdd && (
        <Tabs
          key={tab == "" ? "" : "tab"}
          defaultActiveKey={tab}
          items={[
            { key: "accesses", label: "Доступы" },
            { key: "groups", label: "Группы" },
          ]}
          onChange={setTab}
        />
      )}

      {tab === "accesses" && <UserAccessList userId={id ?? ""} />}

      {tab === "groups" && (
        <div className={"UserGroupList"}>
          <UserGroupsList userId={id ?? ""} />
        </div>
      )}
    </Layout>
  );
};

export default UserView;
