import React, {FunctionComponent} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {getAccess} from "./parseJwt";
import {stopEvent} from "./stopEvent";
import {useQueryClient} from "react-query";
import Cookies from "js-cookie";

const AppMenu: FunctionComponent<any> = (props) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient()

    let access: { [x: string]: string } = {}
    if (!(window as any).access) {
        (window as any).access = getAccess(Cookies.get('token') ?? '');
    } else {
        access = (window as any).access
    }

    function handleLogout(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        Cookies.remove('token', {domain: process.env.REACT_APP_BASE_DOMAIN})
        Cookies.remove('refresh_token', {domain: process.env.REACT_APP_BASE_DOMAIN})
        queryClient.invalidateQueries({queryKey: ['userme']})

        window.location.href = process.env.REACT_APP_AUTH_REDIRECT + "?redirect=" + window.location.href;

        stopEvent(e)
    }

    return <div className={'app-menu'} key={'key_app_menu'}>
        <Link className={'logo'} to={'/'}>
            <img src={'/images/logo.svg'} alt={'Logo'}/>
        </Link>
        {['F', 'E', 'R'].includes(access['UR_US']) &&
            <NavLink key={'link_users'}  to={'/users'} className={({isActive}) => (isActive ? 'active menu-item' : 'menu-item')}>
                <img src="/menu/icon_users.svg" className={'menu-item-icon'} alt="Пользователи"/>
                <span>Пользователи</span>
            </NavLink>}
        {['F', 'E', 'R'].includes(access['UR_GR']) &&
            <NavLink key={'link_groups'}  to={'/groups'} className={({isActive}) => (isActive ? 'active menu-item' : 'menu-item')}>
                <img src="/menu/icon_groups.svg" className={'menu-item-icon'} alt="Группы"/>
                <span>Группы</span>
            </NavLink>}
        {['F', 'E', 'R'].includes(access['UR_RL']) &&
            <NavLink key={'link_roles'}  to={'/roles'} className={({isActive}) => (isActive ? 'active menu-item' : 'menu-item')}>
                <img src="/menu/icon_roles.svg" className={'menu-item-icon'} alt="Роли"/>
                <span>Роли</span>
            </NavLink>}
        {['F', 'E', 'R'].includes(access['UR_AZ']) && <NavLink  key={'link_categories'}  to={'/categories'}
                                                                className={({isActive}) => (isActive ? 'active menu-item' : 'menu-item')}>
            <img src="/menu/icon_access.svg" className={'menu-item-icon'} alt="Доступы"/>
            <span>Доступы</span>
        </NavLink>}
        {['F', 'E', 'R'].includes(access['UR_ST']) &&
            <NavLink key={'link_settings'} to={'/settings'} className={({isActive}) => (isActive ? 'active menu-item' : 'menu-item')}>
                <img src="/menu/icon_settings.svg" className={'menu-item-icon'} alt="Настройки"/>
                <span>Настройки</span>
            </NavLink>}
        <Link className={'menu-item menu-item-logout'} to={process.env.REACT_APP_AUTH_REDIRECT + '?redirect=' + window.location.href} onClick={handleLogout}>
            <img src="/menu/icon_logout.svg" className={'menu-item-icon'} alt="Выход"/>
            <span>Выход</span>
        </Link>
    </div>
}

export default AppMenu