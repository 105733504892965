export function getLevelLocale(level?: string) {
    if (!level) return ''
    switch (level.toLowerCase().split(':')[1]) {
        case 'none':
            return 'Недоступен'
        case 'view':
            return 'Просмотр'
        case 'edit':
            return 'Изменение'
        case 'read':
            return 'Изменение'
        case 'full':
            return 'Полный'
        case 'n':
            return 'Недоступен'
        case 'r':
            return 'Просмотр'
        case 'e':
            return 'Изменение'
        case 'f':
            return 'Полный'
        default:
            return  level.split(':')[1];
    }
}