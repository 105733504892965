import {Form, Input, Modal} from "antd";
import {FunctionComponent, useEffect, useState} from "react";
import {useQuery} from "react-query";
import {api} from "../../../api_client";

const UserChangePassword: FunctionComponent<{
    isOpen: boolean,
    onUpdate: (pass1: string) => void,
    onCancel: () => void
}> = (props) => {
    const [item, setItem] = useState({pass1: '', pass2: ''})
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    const {data: settings, error: itemError, isLoading: itemLoading} =
        useQuery({
            queryKey: [`settings`],
            queryFn: () => api.settings.settingsList().then(e => e.data)
        })

    const passDesc = settings?.find(e => e.name == "PASSWORD_ERROR_CHECK")
    const passRegular = settings?.find(e => e.name == "PASSWORD_RULES")

    useEffect(() => {
        setItem({pass1: '', pass2: ''})
        form.setFieldsValue(item)
    }, [props.isOpen])

    const handleOk = async () => {
        try {
            await form.validateFields()
            props.onUpdate(form.getFieldValue('pass1'))
            setItem({pass1: '', pass2: ''})
            form.resetFields()
        } catch (e) {
            // EMPTY
        }
    };


    return <Modal title={'Изменение пароля'}
                  open={props.isOpen} onOk={handleOk} onCancel={props.onCancel}
                  confirmLoading={loading}
                  okText={'Сохранить'}
                  cancelText={'Отмена'}
    >


        {passDesc?.value}

        <Form
            className={'wrap-content'}
            style={{marginBottom: '20px', marginTop: '20px'}}
            layout={'vertical'}
            initialValues={item}
            onFinish={handleOk}
            onFinishFailed={() => {
            }}
            autoComplete="off"
            disabled={loading}
            form={form}
        >
            <Form.Item<{ pass1: string }>
                name="pass1"
                rules={[
                    {required: true, message: 'Поле обязательно для заполнения'},
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!(new RegExp(passRegular?.value ?? '', 'g')).test(value)) {
                                return Promise.reject(new Error('Пароль недостаточно сложный.'));
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}
            >
                <Input.Password
                    placeholder={'Придумайте пароль'}
                />
            </Form.Item>
            <Form.Item<{ pass2: string }>
                name="pass2"
                rules={[
                    {required: true, message: 'Поле обязательно для заполнения'},
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('pass1') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Пароли не совпадают!'));
                        },
                    }),
                ]}
            >
                <Input.Password placeholder={'Повторите, чтобы не ошибиться'}/>
            </Form.Item>
        </Form>
    </Modal>
}

export default UserChangePassword;
