import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ConfigProvider, Empty } from "antd";
import "./style.scss";
import MainScreen from "./screens/main_screen/MainScreen";
import AuthScreen from "./screens/auth/AuthScreen";
import RestoreScreen from "./screens/auth/RestoreScreen";
import CategoriesList from "./screens/categories/CategoriesList";
import CategoriesView from "./screens/categories/CategoryView";
import ZoneAccessView from "./screens/zones/ZoneAccessView";
import NotFondScreen from "./screens/main_screen/NotFondScreen";
import UsersList from "./screens/users/UsersList";
import UserView from "./screens/users/UserView";
import RolesList from "./screens/roles/RolesList";
import RoleView from "./screens/roles/RoleView";
import RoleEdit from "./screens/roles/RoleEdit";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import UserEdit from "./screens/users/UserEdit";
import CategoriesEdit from "./screens/categories/CategoryEdit";
import ZoneAccessEdit from "./screens/zones/ZoneAccessEdit";
import SettingsView from "./screens/settings/SettingsView";
import refreshToken, { refreshTokenFun } from "./shared/refreshToken";
import GroupsList from "./screens/groups/GroupsList";
import GroupEdit from "./screens/groups/GroupEdit";
import GroupView from "./screens/groups/GroupView";
import HistoryList from "./screens/history/HistoryList";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainScreen />,
  },
  {
    path: "/categories",
    element: <CategoriesList />,
  },
  {
    path: "/categories/-/access_zones/:id",
    element: <ZoneAccessView />,
  },
  {
    path: "/categories/:categoryId/access_zone/add",
    element: <ZoneAccessEdit isAdd={true} />,
  },
  {
    path: "/categories/:categoryId/access_zone/:id",
    element: <ZoneAccessView />,
  },
  {
    path: "/categories/:categoryId/access_zone/:id/edit",
    element: <ZoneAccessEdit />,
  },
  {
    path: "/categories/add",
    element: <CategoriesEdit isAdd={true} />,
  },
  {
    path: "/categories/:id",
    element: <CategoriesView />,
  },
  {
    path: "/categories/:id/edit",
    element: <CategoriesEdit />,
  },
  {
    path: "/users",
    element: <UsersList />,
  },
  {
    path: "/users/:id/edit",
    element: <UserEdit />,
  },
  {
    path: "/users/:id",
    element: <UserView />,
  },
  {
    path: "/users/add",
    element: <UserEdit isAdd={true} />,
  },
  {
    path: "/groups",
    element: <GroupsList />,
  },
  {
    path: "/groups/:id/edit",
    element: <GroupEdit />,
  },
  {
    path: "/groups/:id",
    element: <GroupView />,
  },
  {
    path: "/groups/add",
    element: <GroupEdit isAdd={true} />,
  },
  {
    path: "/roles",
    element: <RolesList />,
  },
  {
    path: "/roles/:id",
    element: <RoleView />,
  },
  {
    path: "/roles/:id/edit",
    element: <RoleEdit />,
  },
  {
    path: "/roles/add",
    element: <RoleEdit isNew={true} />,
  },
  {
    path: "/auth",
    element: <AuthScreen />,
  },
  {
    path: "/restore_password",
    element: <RestoreScreen />,
  },
  {
    path: "/settings",
    element: <SettingsView />,
  },
  {
    path: "/history",
    element: <HistoryList users={[]} />,
  },
  {
    path: "*",
    element: <NotFondScreen />,
  },
]);

const queryClient = new QueryClient();

refreshToken();

document.addEventListener("visibilitychange", function (event) {
  if (!document.hidden) {
    refreshTokenFun();
  }
});

createRoot(document.getElementById("root")!).render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      {/*<ReactQueryDevtoolsPanel style={{}} className={''}  />*/}
      <ReactQueryDevtools initialIsOpen={false} />

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description="Нет данных"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#FA8C16",
            borderRadius: 8,
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </React.StrictMode>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
